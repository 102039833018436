// i18n-default-category:"subscriptions"

import { memo, useContext, useState, useCallback } from 'react'
import styled from 'styled-components'
// import { NavLink } from "react-router-dom"
import { i18n } from 'inline-i18n'
import { i18nReact } from 'inline-i18n/build/i18nReact'
import Button from '@material-ui/core/Button'
import BottomNavigation from '@material-ui/core/BottomNavigation'
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import AddBoxIcon from '@material-ui/icons/AddBox'
import CheckIcon from '@material-ui/icons/Check'
import StopIcon from '@material-ui/icons/Stop'
import NotesIcon from '@material-ui/icons/Notes'
import ShareIcon from '@material-ui/icons/Share'
import TabIcon from '@material-ui/icons/Tab'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import PostAddIcon from '@material-ui/icons/PostAdd'
import AssignmentIcon from '@material-ui/icons/Assignment'
// import FlareIcon from '@material-ui/icons/Flare'
// import VpnKeyIcon from '@material-ui/icons/VpnKey'
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks'
import queryString from 'query-string'
import { useApolloClient } from '@apollo/client'

import { LoggedInUserContext } from '../../../context/LoggedInUser'
import userQuery from '../../../graphql/queries/user'
import useInstanceValue from '../../../hooks/useInstanceValue'
import useSetTimeout from '../../../hooks/useSetTimeout'
import useGoUpdateProject from '../../../hooks/useGoUpdateProject'
import useDataQuery from '../../../hooks/useDataQuery'
import useEffectAsync from '../../../hooks/useEffectAsync'

import Header from "../../common/Header"
import NavLinkOrAWithDisable from '../../common/NavLinkOrAWithDisable'

import projectsQuery from '../../../graphql/queries/projects'

const getBasicUsageData = () => ([
  {
    label: i18n("Projects"),
    src: "/features_projects.gif",
    explanation: i18n("Create a new project from the main menu"),
    note: i18n("Find existing projects under “All Projects”"),
    icon: <LibraryBooksIcon />,
  },
  {
    label: i18n("Modules"),
    src: "/features_modules.gif",
    explanation: i18n("Create study modules via the plus button"),
    note: i18n("Switch between them via the thumbnails"),
    icon: <AddBoxIcon />,
  },
  {
    label: i18n("Notes"),
    src: "/features_notes.gif",
    explanation: i18n("Record rich text notes"),
    note: i18n("Insert and highlight passages inline"),
    icon: <NotesIcon />,
  },
  {
    label: i18n("Resize"),
    src: "/features_resize.gif",
    explanation: i18n("Use resize dots to change panel sizes"),
    note: i18n("Dots are also used to minimize and expand certain panels"),
    icon: <FiberManualRecordIcon />,
  },
  {
    label: i18n("Options"),
    src: "/features_options.gif",
    explanation: i18nReact("Find settings and options wherever you see a {{down_arrow}} icon", {
      down_arrow: <StyledExpandMoreIcon />
    }),
    note: i18n("Share, duplicate, print, star, rename, and more"),
    icon: <ExpandMoreIcon />,
  },
])

const getNewWayData = () => ([
  {
    label: i18n("Thumbnails"),
    src: "/features_thumbnails.gif",
    explanation: i18n("Thumbnails replace tabs to eliminate clutter"),  // TODO: change "replace tabs" language
    note: i18n("Choose between single and side-by-side views (large devices only)"),
    icon: <StopIcon />,
  },
  {
    label: i18n("Note Tabs"),
    src: "/features_note_tabs.gif",
    explanation: i18n("Notes are grouped together in renamable tabs"),
    note: i18n("View in a drawer or panel (large devices only)"),
    icon: <TabIcon />,
  },
  {
    label: i18n("Sharing"),
    src: "/features_share.gif",
    explanation: i18n("Sharing has been simplified"),
    note: i18n("Shared work automatically updates as you make changes"),
    icon: <ShareIcon />,
  },
  {
    label: i18n("Pages"),
    src: "/features_pages.gif",
    explanation: i18n("Insert study modules within notes"),
    note: i18n("Bring your complete study together"),
    icon: <PostAddIcon />,
  },
  {
    label: i18n("YouVersion"),
    src: "/features_youversion.gif",
    explanation: i18n("Paste in an alternative version"),
    note: i18n("Grab the version text from YouVersion or wherever"),
    icon: <AssignmentIcon />,
  },
])

// const getAdditionsData = () => ([
//   {
//     label: i18n("General"),
//     src: "/features_compare.gif",
//     explanation: i18n("Lots of site-wide improvements"),
//     note: i18nReact("See {{about_the_biblearc_study_bible}} for details", {
//       about_the_biblearc_study_bible: (
//         <NavLinkOrAWithDisable to="/about-the-biblearc-study-bible" exact>
//           {i18n("About The Biblearc Study Bible")}
//         </NavLinkOrAWithDisable>
//       )
//     }),
//     icon: <FlareIcon />,
//   },
//   {
//     label: i18n("Sign-in"),
//     src: "/features_signin.gif",
//     explanation: i18n("Sign in to multiple devices at once"),
//     note: i18n("Your work will be automatically synced"),
//     icon: <VpnKeyIcon />,
//   },
//   {
//     label: i18n("Browser"),
//     // src: "/features_browser.gif",
//     explanation: i18n("Full-feature projects browser"),
//     // note: i18n("..."),
//     note: "—COMING SOON—",
//     icon: <LibraryBooksIcon />,
//   },
//   {
//     label: i18n("Offline"),
//     // src: "/features_offline.gif",
//     explanation: i18n("Access your work with or without a connection"),
//     // note: i18n("You may also make Bible versions available offline"),
//     note: "—COMING SOON—",
//     icon: <CheckIcon />,
//   },
// ])

const PageTitle = styled.div`
  line-height: 48px;
  font-size: 18px;

  @media (max-width: 850px) {
    display: none;
  }
`

const MobilePageTitle = styled.div`
  line-height: 48px;
  font-size: 18px;
  display: none;

  @media (max-width: 850px) {
    display: block;
  }
`

const HeaderLogoImg = styled.img`
  height: 36px;
  vertical-align: top;
  margin: 5px -4px 0 -6px;
`

const Container = styled.div`
  flex: 1;
  overflow: auto;
  padding: 10px 20px calc(100vh - 250px);
  background-color: white;
`

const Content = styled.div`
  max-width: 800px;
  margin: 0 auto;
  font-size: 16px;
`

const SuccessContainer = styled.div`
  margin: 40px 0 -30px;
  display: flex;
  justify-content: center;
`

const Success = styled.div`
  font-size: 18px;
  border: 1px solid ${({ theme }) => theme.palette.divider};
  background-color: ${({ theme }) => theme.palette.grey[50]};
  align-self: center;
  padding: 10px 17px;
  border-radius: 5px;

  .MuiSvgIcon-root {
    height: 25px;
    width: auto;
    vertical-align: middle;
    color: ${({ theme }) => theme.palette.primary.main};
    margin: -4px 5px 0 -2px;
  }
`

const Welcome = styled.div`
  text-align: center;
  margin: 70px 0 30px;
  font-size: 22px;
  font-weight: 500;
`

const ToolsLogoImg = styled.img`
  height: 44px;
  vertical-align: top;
  margin: -7px -12px -7px -6px;
`


const TagLine1 = styled.div`
  text-align: center;
`

const TagLine2 = styled.div`
  text-align: center;
  font-weight: 300;
  margin-bottom: 30px;
`

const Whoops = styled.div`
  text-align: center;
  font-weight: 300;
  margin-top: 45px;
`

const UseFor = styled.div`
  margin-bottom: 10px;
  background-color: ${({ theme }) => theme.palette.primary.faded};
  padding: 4px 15px;
  text-align: center;
`

const ToolsLogoImg2 = styled.img`
  height: 30px;
  vertical-align: top;
  margin: -4px -6px -4px -6px;
`

const Helps = styled.div`
  margin: 60px 0 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const HelpHeader = styled.div`
  text-align: center;
  font-size: 28px;
  font-weight: 200;
  margin-bottom: 20px;
`

const UseForSection = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const AlternativelyUseFor = styled.div`
  font-size: 14px;
  font-weight: 300;
  text-align: center;
`

const HelpSubhead = styled.div`
  text-align: center;
  font-weight: bold;
  font-size: 20px;
  margin: 30px 0 15px;
`

const GifSection = styled.div`
  border: 1px solid ${({ theme }) => theme.palette.divider};
  margin-bottom: 25px;
  width: 602px;
  max-width: calc(100vw - 20px);
`

// const GifSectionHeading = styled.div`
//   font-weight: 300;
//   font-size: 15px;
// `

const StyledBottomNavigation = styled(BottomNavigation)`
  background: ${({ theme }) => theme.palette.grey[100]};
  border-top: 1px solid ${({ theme }) => theme.palette.divider};
`

const GifContainer = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  background: white;
`

const ExplanationContainer = styled.div`
  position: absolute;
  bottom: 15px;
  left: 15px;
  right: 15px;
  display: flex;
  justify-content: center;
`

const Explanation = styled.div`
  border: 1px solid ${({ theme }) => theme.palette.divider};
  border-radius: 5px;
  padding: 10px;
  background-color: ${({ theme }) => theme.palette.grey[100]}cc;
  backdrop-filter: blur(3px);
  font-size: 13px;
  text-align: center;
  color: black;
`

const Note = styled.div`
  font-weight: 300;
  font-size: 12px;

  &:empty {
    display: none;
  }
`

const Gif = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;

  &:not([src]) {
    display: none;
  }
`

const ButtonContainer = styled.div`
  margin: 10px 0;
  text-align: center;
`

const SeePromo = styled.div`
  font-size: 14px;
  margin: 10px 0;
  text-align: center;
`

const StyledExpandMoreIcon = styled(ExpandMoreIcon)`
  height: 18px;
  width: 18px;
  border-radius: 3px;
  border: 1px solid ${({ theme }) => theme.palette.divider};
  background: white;
  margin: -5px 0;
`

const YourToolsSubscription = ({ ...props }) => {

  let {
    'purchase-successful': purchaseSuccessful,
    'subscription-granted': subscriptionGranted,
  } = queryString.parse(window.location.search)
  purchaseSuccessful = purchaseSuccessful !== undefined
  subscriptionGranted = subscriptionGranted !== undefined

  const client = useApolloClient()
  const user = useContext(LoggedInUserContext)
  const { hasToolsPlan } = user || {}
  const getUser = useInstanceValue(user)
  const [ setDoUserQueryTimeout ] = useSetTimeout()

  const { projects: { count: recentProjectsCount=0 }={} } = useDataQuery({
    projectsQuery,
    variables: {
      limit: 3,
    },
  })

  const [ goCreateProject ] = useGoUpdateProject()
  const createProject = useCallback(() => goCreateProject(), [ goCreateProject ])

  const [ basicUsageIdx, setBasicUsageIdx ] = useState(0)
  const [ newWayIdx, setNewWayIdx ] = useState(0)
  // const [ additionsIdx, setAdditionsIdx ] = useState(0)

  const basicUsageOnChange = useCallback((event, basicUsageIdx) => setBasicUsageIdx(basicUsageIdx), [])
  const newWayOnChange = useCallback((event, newWayIdx) => setNewWayIdx(newWayIdx), [])
  // const additionsOnChange = useCallback((event, additionsIdx) => setAdditionsIdx(additionsIdx), [])

  useEffectAsync(
    // Immediately after purchase, keep doing user queries until stripe subscription is found.
    () => {
      if(!purchaseSuccessful) return

      let timesChecked = 0

      const checkAndTryAfterTimeout = () => {
        setDoUserQueryTimeout(
          async () => {
            const user = getUser()
            if(!user) return

            const hasStripeSubscription = !!(user.activeSubscriptions || []).filter(({ type }) => type === 'STRIPE')[0]

            if(!hasStripeSubscription) {
              await client.query({
                context: {
                  isBackupServerQuery: true,  // forcing the await to wait until run through the server via this option
                  forceSaveServerResultToDexie: true,    
                },
                query: userQuery,
                fetchPolicy: `network-only`,
              })
              if(++timesChecked < 10) {
                checkAndTryAfterTimeout()
              }
            }
          },
          1000,
        )
      }

      checkAndTryAfterTimeout()
    },
    [],
  )

  const basicUsageData = getBasicUsageData()
  const newWayData = getNewWayData()
  // const additionsData = getAdditionsData()

  return (
    <>

      <Header {...props}>
        <PageTitle>
          {i18nReact("Your {{logo}} Subscription", {
            logo: <HeaderLogoImg src="/biblearc_tools_1.svg" />
          })}
        </PageTitle>
        <MobilePageTitle>
          {i18n("TOOLS")}
        </MobilePageTitle>
      </Header>

      <Container>
        <Content>

          {purchaseSuccessful &&
            <SuccessContainer>
              <Success>
                <CheckIcon />
                {i18n("Purchase successful")}
              </Success>
            </SuccessContainer>
          }

          {subscriptionGranted &&
            <SuccessContainer>
              <Success>
                <CheckIcon />
                {i18n("Subscription granted")}
              </Success>
            </SuccessContainer>
          }

          <Welcome>
            {i18nReact("Welcome to {{something}}", {
              something: <ToolsLogoImg src="/biblearc_tools_1.svg" />,
            })}
          </Welcome>

          <TagLine1>
            {i18n("“Visual Meditations”")}
          </TagLine1>
          <TagLine2>
            {i18n("...using graphical Bible study methods.")}
          </TagLine2>

          {!hasToolsPlan &&
            <Whoops>
              {i18n("[[ You do not have an active Biblearc TOOLS subscription. ]]")}
            </Whoops>
          }

          {!!hasToolsPlan &&
            <>
              <ButtonContainer>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={createProject}
                  disableElevation
                >
                  {recentProjectsCount > 0
                    ? i18n("Create a new project")
                    : i18n("Create your first project")
                  }
                </Button>
              </ButtonContainer>
            </>
          }

          <Helps>

            <HelpHeader>
              {i18n("Getting Started")}
            </HelpHeader>

            <UseForSection>

              <UseFor>
                {i18nReact("Use {{biblearc_tools}} for in-depth study of a passage or topic.", {
                  biblearc_tools: <ToolsLogoImg2 src="/biblearc_tools_1.svg" />,
                })}
              </UseFor>

              <AlternativelyUseFor>
                {i18nReact("(Use {{my_biblearc_study_bible}} for general Bible reading.)", {
                  my_biblearc_study_bible: (
                    <NavLinkOrAWithDisable to="/your-my-bsb-subscription" exact>
                      {i18n("My Biblearc Study Bible")}
                    </NavLinkOrAWithDisable>
                  ),
                })}
              </AlternativelyUseFor>
              <AlternativelyUseFor>
                {i18nReact("(Use {{biblearc_equip}} to learn deeper study of Scripture.)", {
                  biblearc_equip: (
                    <NavLinkOrAWithDisable to="/your-equip-subscription">
                      {i18n("Biblearc EQUIP")}
                    </NavLinkOrAWithDisable>
                  ),
                })}
              </AlternativelyUseFor>

            </UseForSection>

            <HelpSubhead>
              {i18n("Basic Usage")}
            </HelpSubhead>

            <GifSection>

              <GifContainer>
                <Gif src={basicUsageData[basicUsageIdx].src} />
                <ExplanationContainer>
                  <Explanation>
                    {basicUsageData[basicUsageIdx].explanation}
                    <Note>
                      {basicUsageData[basicUsageIdx].note}
                    </Note>
                  </Explanation>
                </ExplanationContainer>
              </GifContainer>

              <StyledBottomNavigation
                value={basicUsageIdx}
                onChange={basicUsageOnChange}
                showLabels
              >
                {basicUsageData.map(({ label, icon }) => (
                  <BottomNavigationAction
                    key={label}
                    label={label}
                    icon={icon}
                  />
                ))}
              </StyledBottomNavigation>

            </GifSection>

            <HelpSubhead>
              {i18n("Key Features")}
            </HelpSubhead>

            <GifSection>

              <GifContainer>
                <Gif src={newWayData[newWayIdx].src} />
                <ExplanationContainer>
                  <Explanation>
                    {newWayData[newWayIdx].explanation}
                    <Note>
                      {newWayData[newWayIdx].note}
                    </Note>
                  </Explanation>
                </ExplanationContainer>
              </GifContainer>

              <StyledBottomNavigation
                value={newWayIdx}
                onChange={newWayOnChange}
                showLabels
              >
                {newWayData.map(({ label, icon }) => (
                  <BottomNavigationAction
                    key={label}
                    label={label}
                    icon={icon}
                  />
                ))}
              </StyledBottomNavigation>

            </GifSection>

            {/* <GifSectionHeading>
              {i18n("Improvements You Don’t Want to Miss")}
            </GifSectionHeading>

            <GifSection>

              <GifContainer>
                <Gif src={additionsData[additionsIdx].src} />
                <ExplanationContainer>
                  <Explanation>
                    {additionsData[additionsIdx].explanation}
                    <Note>
                      {additionsData[additionsIdx].note}
                    </Note>
                  </Explanation>
                </ExplanationContainer>
              </GifContainer>

              <StyledBottomNavigation
                value={additionsIdx}
                onChange={additionsOnChange}
                showLabels
              >
                {additionsData.map(({ label, icon }) => (
                  <BottomNavigationAction
                    key={label}
                    label={label}
                    icon={icon}
                  />
                ))}
              </StyledBottomNavigation>

            </GifSection> */}

            {/* <HelpSubhead>
              {i18n("Usage Examples")}
            </HelpSubhead>

            [videos]

            note to self: use different layouts for the different videos

            1. Begin to study a passage
              - Context (Notes tab)
              - Highlight (Notes tab)
              - Questions (Notes tab)
              - Paraphrase (Discourse)

            2. Carefully exegete
              - [with appropriate notes tabs]
              - Diagram
              - Arc
              - Insights (Notes tab)

            3. Prepare a teaching
              - [building from exegesis]
              - Teaching (Notes with highlighted passages)

            4. Make a biblical argument
              - [building from exegesis of multiple passges]
              - Thesis Paper (Notes with study modules + highlighted passages)

            5. Explore a topic
              - Search
              - Relevant passages (Notes with highlighted passages)
              - Phrase a key verse */}

          </Helps>

          <SeePromo>
            {i18nReact("See the {{biblearc_tools_promo_page}} for further inspiration.", {
              biblearc_tools_promo_page: (
                <NavLinkOrAWithDisable to="/about-tools" exact>
                  {i18n("Biblearc TOOLS promotional page")}
                </NavLinkOrAWithDisable>
              )
            })}
          </SeePromo>

        </Content>
      </Container>

    </>
  )
}


export default memo(YourToolsSubscription)
// i18n-default-category:"projects"

import { memo, useCallback } from 'react'
import styled from 'styled-components'
import { i18n } from 'inline-i18n'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'

import { getModuleTextSizeMultiplier, getModuleTextFontSize, getModuleLineSpacingMultiplier, getModuleLineSpacing } from '../../../utils/misc'

import CustomSlider from '../../common/CustomSlider'
import CustomCheckbox from '../../common/CustomCheckbox'
import SubtleDivider from '../../common/SubtleDivider'
import FormattingKeyOptions from '../shared/FormattingKeyOptions'

const Container = styled.div`
  flex: 1;
  overflow: auto;
  padding: 0 15px 2px;
`

const TextSizeSlider = styled(CustomSlider)`
  background-color: white;
  padding: 10px;
  margin: 5px -10px 0 -10px;
  border-radius: 10px;
`

const LineSpacingSizeSlider = styled(TextSizeSlider)`
  margin-top: -15px;
  margin-bottom: -10px;
`

const StyledSubtleDivider = styled(SubtleDivider)`
  margin: 10px -15px 0;
`

const StyledTextField = styled(TextField)`
  margin: 15px 0 10px;
`

const PassageInNotesSettings = ({
  versionId,
  languageId,
  settings,
  setDisplayedTextSize,
  setDisplayedLineSpacing,
  commitSettings,
}) => {

  const {
    textSize,
    lineSpacing,
    showCfs,
    showNotes,
    showHebrewVowels,
    showCantillation,
    showGreekAccents,
    greekPunctuation,
    formattingKeyId,
    formattingKeyView,
  } = settings

  const updateTextSize = useCallback(
    (event, value) => {
      setDisplayedTextSize(
        getModuleTextFontSize(value / 100)
      )
    },
    [ setDisplayedTextSize ],
  )

  const updateLineSpacing = useCallback(
    (event, value) => {
      setDisplayedLineSpacing(
        getModuleLineSpacing(value / 100)
      )
    },
    [ setDisplayedLineSpacing ],
  )

  const onChangePassageGreekPunctuation = useCallback(({ target }) => commitSettings({ greekPunctuation: target.value }), [ commitSettings ])

  return (
    <Container>

      <TextSizeSlider
        label={i18n("Text Size")}
        min={200}
        max={400}
        color="secondary"
        defaultValue={parseInt(getModuleTextSizeMultiplier(textSize) * 100, 10)}
        onChange={updateTextSize}
        onChangeCommitted={commitSettings}
        className="module-settings-textsizes"
      />

      <LineSpacingSizeSlider
        label={i18n("Line Spacing")}
        min={200}
        max={350}
        color="secondary"
        defaultValue={parseInt(getModuleLineSpacingMultiplier(lineSpacing) * 100, 10)}
        onChange={updateLineSpacing}
        onChangeCommitted={commitSettings}
        className="module-settings-textsizes"
      />

      {versionId !== `original` &&
        <CustomCheckbox
          checked={showCfs}
          onUpdate={commitSettings}
          onUpdateKey="showCfs"
          onUpdateValue={!showCfs}
          label={i18n("Show cross-reference dots")}
        />
      }

      <CustomCheckbox
        checked={showNotes}
        onUpdate={commitSettings}
        onUpdateKey="showNotes"
        onUpdateValue={!showNotes}
        label={i18n("Show translation note dots")}
      />

      {versionId === `original` && languageId === `heb` &&
        <>
          <CustomCheckbox
            checked={showHebrewVowels}
            onUpdate={commitSettings}
            onUpdateKey="showHebrewVowels"
            onUpdateValue={!showHebrewVowels}
            label={i18n("Show vowels")}
          />

          <CustomCheckbox
            checked={showCantillation}
            onUpdate={commitSettings}
            onUpdateKey="showCantillation"
            onUpdateValue={!showCantillation}
            label={i18n("Show cantillation")}
          />
        </>
      }

      {versionId === `original` && languageId === `grc` &&
        <>
          <CustomCheckbox
            checked={showGreekAccents}
            onUpdate={commitSettings}
            onUpdateKey="showGreekAccents"
            onUpdateValue={!showGreekAccents}
            label={i18n("Show accents")}
          />

          <StyledTextField
            variant="outlined"
            size="small"
            select
            label={i18n("Punctuation")}
            value={greekPunctuation}
            onChange={onChangePassageGreekPunctuation}
            fullWidth
          >
            <MenuItem
              value="ENGLISH"
            >
              {i18n("English")}
            </MenuItem>
            <MenuItem
              value="GREEK"
            >
              {i18n("Greek")}
            </MenuItem>
            <MenuItem
              value="NONE"
            >
              {i18n("None")}
            </MenuItem>
          </StyledTextField>
        </>
      }

      <StyledSubtleDivider />

      <FormattingKeyOptions
        formattingKeyId={formattingKeyId}
        formattingKeyView={formattingKeyView}
        commitSettings={commitSettings}
      />

      {/* <List>

        <CustomCheckbox
          checked={!!legacySetting.value.settings.showmp}
          onChange={toggleShowMPIndicator}
          label={i18n("Show main point indicator")}
        />

      </List> */}

    </Container>
  )

}

export default memo(PassageInNotesSettings)
import { gql } from '@apollo/client'
import i18nStrings_fields from '../fragments/i18nStrings_fields'

const i18nStrings = gql`
  query i18nStrings($id: ID!) {
    i18nStrings(id: $id) {
      ${i18nStrings_fields}
    }
  }
`

export default i18nStrings
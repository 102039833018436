import { memo } from 'react'
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt'
import styled from 'styled-components'

import { capitalize } from '../../../utils/misc'
import useInstanceValuesCallback from '../../../hooks/useInstanceValuesCallback'

const Container = styled.div`
  display: flex;
  padding: 5px 0;
`

const Side = styled.div`
  min-width: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border: 1px solid ${({ theme, $isSelected }) => $isSelected ? theme.palette.tertiary.main : theme.palette.divider};
  background: ${({ theme, $isSelected }) => $isSelected ? `${theme.palette.tertiary.main}19` : `white`};
  border-radius: 4px;
  padding: 5px 7px;
  transition: border-color .15s ease-in-out;

  ${({ onClick, theme }) => !onClick ? `` : `
    &:hover {
      cursor: pointer;
      border-color: ${theme.palette.tertiary.main};
    }
  `}
`

const TextContainer = styled.div`
  font-size: 17px;
  white-space: pre-wrap;
`

const English = styled.span`
  background: ${({ theme }) => theme.palette.primary.light}88;
`

const Desc = styled.div`
  font-size: 12px;
  font-weight: 500;
`

const Category = styled.div`
  font-size: 12px;
  font-weight: 300;
`

const StyledArrowRightAltIcon = styled(ArrowRightAltIcon)`
  align-self: center;
  margin: 0 6px;
`

const Translation = styled.span`
  background: ${({ theme }) => theme.palette.tertiary.light}55;
`

const NotTranslated = styled.div`
  flex: 1;
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: 200;
  align-items: center;
  line-height: 1.2;
`

const Flagged = styled.div`
  font-size: 12px;
  font-weight: 500;
`

const TranslateListItem = ({
  hash,
  engText,
  desc,
  category,
  i18nEnglishStringInfo,
  info,
  // createdAt,
  // updatedAt,
  // languageId,
  setHash,
  isSelected,
}) => {

  const { descRequests } = i18nEnglishStringInfo
  const { value } = info

  const goSetHash = useInstanceValuesCallback(() => setHash(hash))

  return (
    <Container>

      <Side>

        <TextContainer>
          <English>
            {engText}
          </English>
        </TextContainer>

        {!!desc &&
          <Desc>
            Explanation: {desc}
          </Desc>
        }

        <Category>
          Category: {capitalize(category)}
        </Category>

      </Side>

      <StyledArrowRightAltIcon />

      <Side
        onClick={isSelected ? null : goSetHash}
        $isSelected={isSelected}
      >

        {value !== undefined &&
          <TextContainer>
            <Translation>
              {value}
            </Translation>
          </TextContainer>
        }

        {value === undefined &&
          <NotTranslated>
            Not yet translated
            {(descRequests || []).length > 0 &&
              <Flagged>
                Description requested
              </Flagged>
            }
          </NotTranslated>
        }

      </Side>

    </Container>
  )
}


export default memo(TranslateListItem)
import { memo, useContext, useMemo, useState } from 'react'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import { getLanguageInfo } from '@bibletags/bibletags-ui-helper/node_build/language'

import useStickyRefState from '../../../hooks/useStickyRefState'
import useDataQuery from '../../../hooks/useDataQuery'
import useInstanceValuesCallback from '../../../hooks/useInstanceValuesCallback'
import useAppSize from '../../../hooks/useAppSize'
import useSimpleToggle from '../../../hooks/useSimpleToggle'
import useLayoutEffectAsync from '../../../hooks/useLayoutEffectAsync'
import { LoggedInUserContext } from '../../../context/LoggedInUser'

import TranslateHeader from "./TranslateHeader"
import AppContent from "../../common/AppContent"
import TranslateInstructions from './TranslateInstructions'
import TranslateEditor from './TranslateEditor'
import TranslateList from './TranslateList'
import NavLinkOrAWithDisable from '../../common/NavLinkOrAWithDisable'
import InfoDialog from '../../common/InfoDialog'

import i18nStringsQuery from '../../../graphql/queries/i18nStrings'

const Panels = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  margin: 0 auto;
  padding: 0 15px;
  max-width: 1200px;
  gap: 15px;
`

const RightPanel = styled.div`
  width: 400px;
  max-width: calc(100vw - 30px);
`

const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const ChooseSomething = styled.div`
  font-size: 17px;
  font-weight: 300;
  padding: 80px 0 10px;
`

const TaggingToo = styled.div`
  border: 1px dashed ${({ theme }) => theme.palette.divider};
  margin: 70px 20px 0;
  padding: 10px 13px;
`

const SubmitForReview = styled.div`
  margin: 30px 20px 0;
  font-size: 13px;
`

const Translate = ({ ...props }) => {

  const [ instructionsRead, setInstructionsRead ] = useStickyRefState({ id: `Translate:instructionsRead`, defaultValue: false })
  const [ languageId, setLanguageId ] = useStickyRefState({ id: `Translate:languageId` })
  const [ hash, setHash ] = useStickyRefState({ id: `Translate:hash` })
  const user = useContext(LoggedInUserContext)
  const { englishName } = languageId ? getLanguageInfo(languageId) : {}
  const { width } = useAppSize()
  const condensed = width < 1000

  const { i18nStrings={} } = useDataQuery({
    i18nStringsQuery,
    variables: {
      id: languageId,
    },
    skip: !user || !languageId,
  })
  const { i18nEnglishStrings, i18nTranslationStrings, googleTranslateStringsByHash, status } = i18nStrings

  const i18nEnglishString = (i18nEnglishStrings || []).find(i18nEnglishString => i18nEnglishString.hash === hash)

  const i18nTranslationStringsByHash = useMemo(
    () => {
      const i18nTranslationStringsByHash = {}
      ;(i18nTranslationStrings || []).forEach(i18nTranslationString => {
        i18nTranslationStringsByHash[i18nTranslationString.hash] = i18nTranslationString
      })
      return i18nTranslationStringsByHash
    },
    [ i18nTranslationStrings ],
  )

  const { value=`` } = (i18nTranslationStringsByHash[hash] || {}).info || {}
  const [ editedValue, setEditedValue ] = useState(value)
  const [ showUnsavedEditsDialog, toggleShowUnsavedEditsDialog ] = useSimpleToggle()
  const hasUnsavedEdits = editedValue !== value

  const goSeeInstructions = useInstanceValuesCallback(() => setInstructionsRead(false))

  const tryToSetHash = useInstanceValuesCallback(
    hash => {
      if(hasUnsavedEdits) {
        toggleShowUnsavedEditsDialog({ force: true })
      } else {
        setHash(hash)
      }
    }
  )
 
  useLayoutEffectAsync(() => setEditedValue(value), [ value ])

  const showInstructions = !instructionsRead || !user

  const submitForReview = (
    <SubmitForReview>
      Click
      {` `}
      <NavLinkOrAWithDisable to={`https://equip.biblearc.com/contact?defaultMessage=${encodeURIComponent(`I would like to submit the Biblearc translation of ${englishName} for review.`)}`}>
        here
      </NavLinkOrAWithDisable>
      {` `}
      to submit this translation for review.
    </SubmitForReview>
  )

  const instructionsButton = (
    <Button
      variant="outlined"
      onClick={goSeeInstructions}
    >
      Back to instructions
    </Button>
  )

  return (
    <>

      <TranslateHeader
        {...props}
        languageId={languageId}
        setLanguageId={setLanguageId}
        status={status}
      />

      <AppContent>

        {showInstructions &&
          <TranslateInstructions
            setInstructionsRead={setInstructionsRead}
          />
        }

        {!showInstructions &&
          <Panels>

            {(!condensed || !i18nEnglishString) &&
              <TranslateList
                languageId={languageId}
                hash={hash}
                setHash={tryToSetHash}
                hasUnsavedEdits={hasUnsavedEdits}
                i18nEnglishStrings={i18nEnglishStrings}
                i18nTranslationStringsByHash={i18nTranslationStringsByHash}
                condensed={condensed}
                submitForReview={submitForReview}
                instructionsButton={instructionsButton}
              />
            }

            {(!condensed || !!i18nEnglishString) &&
              <RightPanel>

                {!!i18nEnglishString &&
                  <TranslateEditor
                    key={hash}
                    languageId={languageId}
                    hash={hash}
                    setHash={tryToSetHash}
                    i18nEnglishString={i18nEnglishString}
                    i18nTranslationString={i18nTranslationStringsByHash[hash]}
                    googleTranslateString={googleTranslateStringsByHash[hash]}
                    editedValue={editedValue}
                    setEditedValue={setEditedValue}
                    hasUnsavedEdits={hasUnsavedEdits}
                    i18nStrings={i18nStrings}
                  />
                }

                {!i18nEnglishString &&
                  <Info>
                    <ChooseSomething>
                      Click on a translation text to edit it.
                    </ChooseSomething>
                    {instructionsButton}
                    <TaggingToo>
                      If you know biblical Greek or Hebrew, you might also consider helping us tag the original text to a Bible translation in your language.
                      This will greatly enhance the functionality of Biblearc in your language!
                      {` `}
                      <NavLinkOrAWithDisable to="/tag">
                        Learn more
                      </NavLinkOrAWithDisable>
                    </TaggingToo>
                    {!!englishName && submitForReview}
                  </Info>
                }

              </RightPanel>
            }

          </Panels>
        }

      </AppContent>

      <InfoDialog
        open={showUnsavedEditsDialog}
        title="Whoops!"
        explanation="You have unsaved edits. Please either save them or reset this entry before switching to another translation."
        onOkay={toggleShowUnsavedEditsDialog}
      />

    </>
  )
}


export default memo(Translate)
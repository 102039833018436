// i18n-default-category:"projects"

import { memo, useCallback, useRef, useState, useContext } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { i18n } from 'inline-i18n'
import styled from 'styled-components'
import Tabs from '@material-ui/core/Tabs'
import { useApolloClient } from '@apollo/client'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import CallReceivedIcon from '@material-ui/icons/CallReceived'
import AddIcon from '@material-ui/icons/Add'

import { IS_EMBED } from '../../../utils/constants'
import useAppSize from '../../../hooks/useAppSize'
import useGoUpdateModule from '../../../hooks/useGoUpdateModule'
import useGoUpdateProject from '../../../hooks/useGoUpdateProject'
import useInstanceValue from '../../../hooks/useInstanceValue'
import useEqualObjsMemo from '../../../hooks/useEqualObjsMemo'
import useNotesShortcuts from '../../../hooks/useNotesShortcuts'
import useEffectAsync from '../../../hooks/useEffectAsync'
import { getNewOrdering, isChrome, sortModules } from '../../../utils/misc'
import { LoggedInUserContext } from '../../../context/LoggedInUser'
import { getEmbedMode } from '../../../graphql/links/embedLink'

import NotesMinimizedPanel from './NotesMinimizedPanel'
import NotesEditor from './NotesEditor'
import NotesTab from './NotesTab'
import Loading from '../../common/Loading'

import modulePiecesQuery from '../../../graphql/queries/modulePieces'

const emptyObj = {}

const Container = styled.div`
  flex: 1;
  min-width: 0;
  display: ${({ $willSetEmbedHeightByContent }) => $willSetEmbedHeightByContent ? `block` : `flex`};
  flex-direction: column;
  position: relative;
`

const NotesHeader = styled.div`
  margin-right: ${({ $inDrawer }) => $inDrawer ? `5px` : `0`};
  display: flex;
`

const StyledIconButton = styled(IconButton)`
  padding: 8px;
  margin: 4px 0;
  background-color: white;

  &:hover {
    background-color: #f5f5f5;
  }

  .MuiSvgIcon-root {
    transform: rotate(${({ $deg }) => $deg}deg);
    transition: transform .3s ease-in-out;
    height: 18px;
  }
`

const StyledTabs = styled(Tabs)`
  .MuiTabs-indicator {
    background-color: ${({ theme }) => theme.palette.grey[400]};
    transition: none;

    ${({ $reordering }) => !$reordering ? `` : `
      display: none;
    `}
  }

  .Mui-selected .expand-more-icon {
    visibility: visible;
  }
`

const Spacer = styled.div`
  flex: 1;
  cursor: row-resize;
`

const Notes = ({
  project,
  inDrawer=false,
  minimizeInPanel=false,
  minimizedInDrawer=false,
  resizeEvents={},
  getProjectSizes,
  goPrintOrDownload,
  embedSettings={},
  embedSetHeight,
  embedOverflowHeight,
  onDeleteModuleByProject,
}) => {

  const user = useContext(LoggedInUserContext)
  const { hasToolsPlan } = user || {}

  const { largeSize } = useAppSize()

  const projectId = project.id
  const [ goUpdateProject ] = useGoUpdateProject({ project })
  const [ goCreateModule ] = useGoUpdateModule({ projectId })
  const client = useApolloClient()

  const notesModuleByProjects = useEqualObjsMemo(project.moduleByProjects.filter(({ module }) => module.type === `NOTES`).sort(sortModules))

  const [ dragTab, setDragTab ] = useState(null)

  const tabIndex = Math.max(0, notesModuleByProjects.findIndex(({ module }) => module.id === project.notesModuleId))

  const getNotesHeightPercentage = useInstanceValue(project.notesHeightPercentage)

  const selectedModule = (notesModuleByProjects[tabIndex] || {}).module || emptyObj

  const cancelClickOrMoveRef = useRef()

  const goPrintOrDownloadWithBaseParams = useCallback(
    params => {
      goPrintOrDownload({
        module: selectedModule,
        projectId,
        note: (
          isChrome
            // The issue is that counter() does not work with html-to-image in Chrome, and there are not <ol>s around second level lists
            // Look to get this fixed when I do a new Notes module
            ? i18n("Note: Ordered lists only print/download correctly in Safari and Firefox.")
            : null
        ),
        ...params,
      })
    },
    [ goPrintOrDownload, selectedModule, projectId ],
  )

  useNotesShortcuts({
    cancelClickOrMoveRef,
    goPrintOrDownload: goPrintOrDownloadWithBaseParams,
  })

  const toggleMinimize = useCallback(() => goUpdateProject({ notesHeightPercentage: getNotesHeightPercentage() * -1 }), [ goUpdateProject, getNotesHeightPercentage ])
  const toggleInDrawer = useCallback(() => goUpdateProject({ notesInDrawer: !inDrawer }), [ goUpdateProject, inDrawer ])

  const handleTabChange = useCallback(
    (event, newTabIndex) => {
      goUpdateProject({
        notesModuleId: ((notesModuleByProjects[newTabIndex] || {}).module || {}).id || null,
      })
      if(getNotesHeightPercentage() < 0) {
        toggleMinimize()
      }
    },
    [ getNotesHeightPercentage, toggleMinimize, notesModuleByProjects, goUpdateProject ],
  )

  const addNotesModule = useCallback(
    () => {

      if(getNotesHeightPercentage() < 0) {
        toggleMinimize()
      }

      const id = uuidv4()
      const ordering = getNewOrdering((notesModuleByProjects.at(-1) || {}).ordering)

      // write this query so that it receives an immediate result
      client.writeQuery({
        query: modulePiecesQuery,
        data: {
          modulePieces: [],
        },
        variables: {
          moduleId: id,
        },
      })

      goCreateModule(
        {
          id,
          type: `NOTES`,
          label: i18n("Notes"),
        },
        { ordering },
      )

      goUpdateProject({ notesModuleId: id })

    },
    [ goCreateModule, notesModuleByProjects, client, getNotesHeightPercentage, toggleMinimize, goUpdateProject ],
  )

  useEffectAsync(
    () => {
      if(notesModuleByProjects.length === 0) {
        addNotesModule()
      }
    },
    [ notesModuleByProjects ],
  )

  if(minimizeInPanel) {
    return (
      <NotesMinimizedPanel
        notesModuleByProjects={notesModuleByProjects}
        toggleInDrawer={toggleInDrawer}
        goUpdateProject={goUpdateProject}
        getProjectSizes={getProjectSizes}
        passageBookmarks={project.passageBookmarks}
      />
    )
  }

  const canAddTabs = (
    hasToolsPlan
    || (
      IS_EMBED
      && getEmbedMode() !== `frozen`
    )
  )

  return (
    <Container
      className="notes-module"
      $willSetEmbedHeightByContent={!!embedSetHeight}
    >

      {!!selectedModule.id &&
        <>

          {![ `richtextbox`, `passages` ].includes(embedSettings.embedType) &&
            <NotesHeader $inDrawer={inDrawer}>

              <StyledTabs
                value={tabIndex}
                onChange={handleTabChange}
                variant="scrollable"
                scrollButtons="off"
                $reordering={!!dragTab}
              >
                {notesModuleByProjects.map((moduleByProject, idx) => (
                  <NotesTab
                    key={moduleByProject.id}
                    moduleByProject={moduleByProject}
                    onDeleteModuleByProject={onDeleteModuleByProject}
                    minimizedInDrawer={minimizedInDrawer}
                    goPrintOrDownload={goPrintOrDownloadWithBaseParams}
                    draggable={notesModuleByProjects.length > 1}
                    dragTab={dragTab}
                    setDragTab={setDragTab}
                    orderingBefore={(notesModuleByProjects[idx-1] || {}).ordering}
                    orderingAfter={(notesModuleByProjects[idx+1] || {}).ordering}
                  />
                ))}
              </StyledTabs>

              {canAddTabs &&
                <StyledIconButton
                  onClick={addNotesModule}
                  tabIndex={-1}
                >
                  <AddIcon />
                </StyledIconButton>
              }

              <Spacer {...resizeEvents} />

              {inDrawer &&
                <Tooltip
                  placement="top"
                  title={
                    getNotesHeightPercentage() < 0
                      ? i18n("Notes tabs")
                      : i18n("Minimize")
                  }
                >
                  <StyledIconButton
                    onClick={toggleMinimize}
                    $deg={getNotesHeightPercentage() > 0 ? 315 : 135}
                    tabIndex={-1}
                    className="notes-minimize-button"
                  >
                    <CallReceivedIcon />
                  </StyledIconButton>
                </Tooltip>
              }

              {largeSize &&
                <StyledIconButton
                  onClick={toggleInDrawer}
                  $deg={inDrawer ? 180 : 0}
                  tabIndex={-1}
                >
                  <CallReceivedIcon />
                </StyledIconButton>
              }

            </NotesHeader>
          }

          <NotesEditor
            key={`editor:${selectedModule.id}`}
            projectId={projectId}
            module={selectedModule}
            goPrintOrDownload={goPrintOrDownloadWithBaseParams}
            embedType={embedSettings.embedType}
            embedSetHeight={embedSetHeight}
            embedOverflowHeight={embedOverflowHeight}
          />

        </>
      }

      {!selectedModule.id && <Loading />}

    </Container>
  )
}

export default memo(Notes)
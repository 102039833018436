// i18n-default-category:"study-bible"

import { memo, useCallback } from 'react'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import CheckIcon from '@material-ui/icons/Check'
import BookmarkIcon from '@material-ui/icons/Bookmark'
import FastForwardIcon from '@material-ui/icons/FastForward'
import FastRewindIcon from '@material-ui/icons/FastRewind'
import { i18n } from 'inline-i18n'

import { fontFamily } from '../../utils/theme'
import { findBookmarkInsertIdx, getTimeStringFromSeconds } from '../../utils/misc'
import useIsLoggedIn from '../../hooks/useIsLoggedIn'
import useLogInCallback from '../../hooks/useLogInCallback'

import FadedLoading from './FadedLoading'

const Container = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding-bottom: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgb(0 0 0/.5);
  opacity: ${({ $show }) => $show ? 1 : 0};
  transition: .35s ease-in-out opacity;
  z-index: ${({ $show }) => $show ? 1 : 0};
`

const Top = styled.div`
  align-self: stretch;
  display: flex;
  justify-content: space-between;
  position: relative;
  padding: 8px;
`

const Middle = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const BookmarkTime = styled.div`
  font-weight: 300;
  font-size: 22px;
  color: ${({ theme }) => theme.palette.primary.light};
`

const AdjustInstructions = styled.div`
  margin: -5px 0 -15px;
  font-weight: 300;
  opacity: .8;
`

const AdjustmentButtons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
`

const Bottom = styled.div`
  font-weight: 300;
  opacity: .8;
  padding: 6px 0 7px;
  font-size: 13px;
  visibility: ${({ $hide }) => $hide ? `hidden` : `visible`};
`

const LinkLikeButton = styled.div`
  text-decoration: underline;
  font-weight: 300;
  opacity: .5;
  transition: opacity .15s ease-in-out;
  margin-bottom: 10px;
  visibility: ${({ $hide }) => $hide ? `hidden` : `visible`};

  &:hover {
    opacity: 1;
    cursor: pointer;
  }
`

const TopButton = styled(Button)`
  color: white;
  padding: 2px 8px;
  
  &:hover {
    background: rgb(255 255 255/.2);
  }
`

const AddButton = styled(Button)`
  color: white;
  border-color: rgb(255 255 255/.4);

  &:hover {
    border-color: white;
  }
`

const AdjustmentIconButton = styled(IconButton)`
  color: white;
  padding: 20px;

  .MuiSvgIcon-root {
    font-size: 35px;
  }

  &:hover {
    background: rgb(255 255 255/.1);
  }

`

const AdjustmentIconButtonText = styled.div`
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  white-space: nowrap;
  text-align: center;
  font-size: 11px;
  font-weight: 300;
  font-family: ${fontFamily};
`

const StyledBookmarkIcon = styled(BookmarkIcon)`
  color: ${({ theme }) => theme.palette.primary.main};
`

const MustLogIn = styled.div`
  margin-bottom: 10px;
`

const StyledFadedLoading = styled(FadedLoading)`
  background-color: rgb(0 0 0/.75);
`

const VideoPlayerCustomControlsBookmarking = ({
  show,
  getMyReactionTypes,
  addBookmark,
  removeBookmark,
  getCurrentSecs,
  myReactionTypeIndexInEdit,
  setMyReactionTypeIndexInEdit,
  clearMyReactionTypeIndexInEdit,
  goUpdateBookmark,
  toggleShowBookmarking,
  modifierKeyDown,
  shiftKeyDown,
}) => {

  const inEdit = myReactionTypeIndexInEdit !== null

  const isLoggedIn = useIsLoggedIn()
  const { logIn, navigatingToLogin } = useLogInCallback()

  const goAddBookmark = useCallback(
    () => {
      const bookmarkMs = parseInt(getCurrentSecs() * 1000, 10)
      const index = findBookmarkInsertIdx({ myReactionTypes: getMyReactionTypes(), bookmarkMs })
      addBookmark(bookmarkMs)
      setMyReactionTypeIndexInEdit(index)
    },
    [ addBookmark, getCurrentSecs, setMyReactionTypeIndexInEdit, getMyReactionTypes ],
  )

  const goRemoveBookmark = useCallback(
    () => {
      if(myReactionTypeIndexInEdit == null) return
      removeBookmark(getMyReactionTypes()[myReactionTypeIndexInEdit])
      clearMyReactionTypeIndexInEdit()
    },
    [ removeBookmark, clearMyReactionTypeIndexInEdit, myReactionTypeIndexInEdit, getMyReactionTypes ],
  )
  
  const goAddBookmarkToBeginning = useCallback(() => addBookmark(0), [ addBookmark ])

  const moveBack = useCallback(event => goUpdateBookmark({ event, directionMultiple: -1 }), [ goUpdateBookmark ])
  const moveForward = useCallback(event => goUpdateBookmark({ event }), [ goUpdateBookmark ])

  const timeAdjustmentString = (
    shiftKeyDown
      ? i18n("10s")
      : (
        modifierKeyDown
          ? i18n("0.1s")
          : i18n("1s")
      )
  )

  return (
    <Container
      $show={show}
      onClick={inEdit ? clearMyReactionTypeIndexInEdit : null}
    >

      {!isLoggedIn &&
        <>
          <Top>
            <TopButton
              startIcon={<ArrowBackIcon />}
              onClick={toggleShowBookmarking}
              tabIndex={-1}
            >
              {i18n("Back")}
            </TopButton>
          </Top>
          <Middle>
            <MustLogIn>
              {i18n("You must be logged in to use bookmarks.")}
            </MustLogIn>
            <Button
              onClick={logIn}
              variant="outlined"
              color="primary"
            >
              {i18n("Sign in / create an account")}
            </Button>
            {navigatingToLogin && <StyledFadedLoading size={20} />}
          </Middle>
          <Bottom />
        </>
      }

      {isLoggedIn &&
        <>

          <Top>

            <TopButton
              startIcon={inEdit ? <CheckIcon /> : <ArrowBackIcon />}
              onClick={inEdit ? clearMyReactionTypeIndexInEdit : toggleShowBookmarking}
              tabIndex={-1}
            >
              {inEdit ? i18n("Done") : i18n("Back")}
            </TopButton>

            {inEdit &&
              <TopButton
                endIcon={<DeleteIcon />}
                onClick={goRemoveBookmark}
                tabIndex={-1}
              >
                {i18n("Delete")}
              </TopButton>
            }

          </Top>

          <Middle>
            
            {!inEdit &&
              <>

                <LinkLikeButton
                  onClick={goAddBookmarkToBeginning}
                  $hide={getMyReactionTypes().includes(`BOOKMARK:0`)}
                >
                  {i18n("Bookmark entire message")}
                </LinkLikeButton>

                <AddButton
                  startIcon={<StyledBookmarkIcon />}
                  onClick={goAddBookmark}
                  variant="outlined"
                  tabIndex={-1}
                >
                  {i18n("Add bookmark here")}
                </AddButton>

              </>
            }
            
            {inEdit &&
              <>

                <AdjustInstructions>
                  {i18n("Adjust bookmark placement")}
                </AdjustInstructions>

                <AdjustmentButtons>

                  <AdjustmentIconButton
                    onClick={moveBack}
                    tabIndex={-1}
                  >
                    <FastRewindIcon />
                    <AdjustmentIconButtonText>
                      {timeAdjustmentString}
                    </AdjustmentIconButtonText>
                  </AdjustmentIconButton>

                  <BookmarkTime>
                    {getTimeStringFromSeconds(parseInt(parseInt((getMyReactionTypes()[myReactionTypeIndexInEdit] || ``).split(`:`)[1], 10) / 1000, 10)) || null}
                  </BookmarkTime>

                  <AdjustmentIconButton
                    onClick={moveForward}
                    tabIndex={-1}
                  >
                    <FastForwardIcon />
                    <AdjustmentIconButtonText>
                      {timeAdjustmentString}
                    </AdjustmentIconButtonText>
                  </AdjustmentIconButton>

                </AdjustmentButtons>

              </>
            }

          </Middle>

          {!inEdit &&
            <Bottom $hide={getMyReactionTypes().length === 0}>
              {i18n("Tap a bookmark to adjust or delete it.")}
            </Bottom>
          }

        </>
      }


    </Container>
  )

}

export default memo(VideoPlayerCustomControlsBookmarking)
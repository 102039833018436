import { i18n } from 'inline-i18n'
import styled from 'styled-components'

const Passages = styled.div`
  margin-top: 5px;
  font-weight: 400;
  opacity: .7;
`

const ClickToView = styled.div`
  margin-top: 5px;
  font-weight: 400;
`

export const manuscriptInfo = {
  "𝔓1": {
    date: "AD 200-300",
    passages: "Matthew 1:1-9, 12-13, 14-20, 23",
  },
  "𝔓4": {
    date: "AD 200-300",
    passages: "Luke 1:58-59, 1:62-2:1, 2:6-7, 3:8-4:2, 4:29-32, 34-35, 5:3-8, 5:30-6:16",
  },
  "𝔓5": {
    date: "AD 200-300",
    passages: "John 1:23-31, 33-40 16:14-22, 16:22-30, 20:19-20, 22-25",
  },
  "𝔓6": {
    date: "AD 300-400",
    passages: "John 10:1-2, 11:1-8, 45-52",
  },
  "𝔓8": {
    date: "AD 300-400",
    passages: "Acts 4:31-37, 5:2-9, 6:1-6, 8-15",
  },
  "𝔓9": {
    date: "AD 200-300",
    passages: "1 John 4:11-12, 14-17",
  },
  "𝔓13": {
    date: "AD 200-400",
    passages: "Hebrews 2:14-5:5, 10:8-22, 10:29-11:14, 11:28-12:17",
  },
  "𝔓15": {
    date: "AD 200-300",
    passages: "1 Corinthians 7:18-32, 7:33-8:4",
  },
  "𝔓17": {
    date: "AD 300-400",
    passages: "Hebrews 9:2-14, 9:15-19",
  },
  "𝔓18": {
    date: "AD 200-400",
    passages: "Revelation 1:4-7",
  },
  "𝔓19": {
    date: "AD 300-500",
    passages: "Matthew 10:32-40, 10:41-11:5",
  },
  "𝔓20": {
    date: "AD 200-300",
    passages: "James 2:19-3:2, 3:3-9",
  },
  "𝔓21": {
    date: "AD 300-500",
    passages: "Matthew 12:24-26, 12:32-33",
  },
  "𝔓22": {
    date: "AD 200-300",
    passages: "John 15:25-16:3, 16:21-32",
  },
  "𝔓23": {
    date: "AD 200-225",
    passages: "James 1:10-12, 1:15-18",
  },
  "𝔓24": {
    date: "AD 300-400",
    passages: "Revelation 5:5-8, 6:5-8",
  },
  "𝔓25": {
    date: "AD 375-400",
    passages: "Matthew 18:32-34, 19:1-3, 5-7, 9-10",
  },
  "𝔓27": {
    date: "AD 200-300",
    passages: "Romans 8:12-22, 24-27, 8:33-9:3, 9:5-9",
  },
  "𝔓28": {
    date: "AD 200-300",
    passages: "John 6:8-12, 6:17-22",
  },
  "𝔓29": {
    date: "AD 200-300",
    passages: "Acts 26:7-8, 26:20",
  },
  "𝔓30": {
    date: "AD 200-300",
    passages: "1 Thessalonians 4:12-13, 16-17, 5:3, 8-10, 12-18; 2 Thessalonians 1:1-2",
  },
  "𝔓32": {
    date: "AD 200-225",
    passages: "Titus 1:11-15, 2:3-8",
  },
  "𝔓35": {
    date: "AD 400-600",
    passages: "Matthew 25:12-15, 25:20-23",
  },
  "𝔓37": {
    date: "AD 200-400",
    passages: "Matthew 26:19-37, 26:37-52",
  },
  "𝔓38": {
    date: "AD 300-325",
    passages: "Acts 18:27-19:6, 19:12-16",
  },
  "𝔓39": {
    date: "AD 200-300",
    passages: "John 8:14-18, 8:18-21",
  },
  "𝔓40": {
    date: "AD 200-300",
    passages: "Romans 1:24-27, 31-2:3, 3:21-4:8, 6:4-5, 16, 9:16-17, 27",
  },
  "𝔓45": {
    date: "AD 200-300",
    passages: "Matthew 20:24-32, 21:13-19, 25:41-26:39; Mark 4:36-40, 5:15-26, 5:38-6:3, 16-25, 36-50, 7:3-15, 7:25-8:1, 10-26, 8:34-9:9, 18-31, 11:27-12:1, 5-8, 13-19, 24-28; Luke 6:31-41, 6:45-7:7, 9:26-41, 9:45-10:1, 6-22, 10:26-11:1, 6-25, 28-46, 11:50-12:12, 18-37, 12:42-13:1, 6-24; 13:29-14:10, 17-33; John 4:51, 54, 5:21, 24, 10:7-25, 10:30-11:10, 18-36, 42-57; Acts 4:27-36, 5:10-21, 30-39, 6:7-7:2, 10-21, 32-41, 7:52-8:1, 14-25, 8:34-9:6, 16-27, 9:35-10:2, 10-23, 31-41, 11:2-14, 11:24-12:5, 13-22, 13:6-16, 25-36, 13:46-14:3, 15-23, 15:2-7, 19-27, 15:38-16:4, 15-22, 32-40; 17:9-17",
  },
  "𝔓46": {
    date: "AD 200-225",
    passages: "Romans 5:17–6:14, 8:15–16:23; Hebrews; 1 Corinthians; 2 Corinthians; Galatians; Ephesians; Philippians; Colossians; 1 Thessalonians 1:1–2:3, 5:5, 23–28",
  },
  "𝔓47": {
    date: "AD 200-300",
    passages: "Revelation 9:10-11:3, 11:5-16:15, 16:17-17:2",
  },
  "𝔓48": {
    date: "AD 200-300",
    passages: "Acts 23:11-17, 23:25-29",
  },
  "𝔓49": {
    date: "AD 275-300",
    passages: "Ephesians 4:16-29, 4:32-5:13",
  },
  "𝔓51": {
    date: "AD 400-425",
    passages: "Galatians 1:2-10, 13, 16-20",
  },
  "𝔓52": {
    date: "AD 125-175",
    passages: "John 18:31-33, 18:37-38",
  },
  "𝔓53": {
    date: "AD 200-300",
    passages: "Matthew 26:29-40; Acts 9:33-10:1",
  },
  "𝔓57": {
    date: "AD 300-500",
    passages: "Acts 4:36-5:2, 8-10",
  },
  "𝔓64": {
    date: "AD 200-225",
    passages: "Matthew 3:9, 15, 5:20-22, 25-28, 26:7-8, 10, 14-15, 22-23, 31-33",
  },
  "𝔓66": {
    date: "AD 200-225",
    passages: "John 1:1-6:11, 6:35b-14:26, 29-30, 15:2-26; 16:2-4, 6-7; 16:10-20:20, 20:22-23, 20:25-21:9, 12, 17; John 19:8-11, 13-15, 18-20, 23-28, 31-32;",
  },
  "𝔓69": {
    date: "AD 200-300",
    passages: "Luke 22:41, 45-48, 58-61",
  },
  "𝔓70": {
    date: "AD 200-300",
    passages: "Matthew 2:13-16, 2:22-3:1, 11:26-27, 12:4-5, 24:3-6, 24:12-15",
  },
  "𝔓71": {
    date: "AD 300-400",
    passages: "Matthew 19:10-11, 17-18",
  },
  "𝔓72": {
    date: "AD 200-400",
    passages: "1 Peter; 2 Peter, Jude",
  },
  "𝔓75": {
    date: "AD 200-225",
    passages: "Luke 3:18-4:2, 4:34-5:10, 5:37-18:18, 22:4-24:53; John 1:1-11:45, 48-57, 12:3-13:19, 14:8-15:10",
  },
  "𝔓77": {
    date: "AD 100-300",
    passages: "Matthew 23:30-39",
  },
  "𝔓81": {
    date: "AD 300-400",
    passages: "1 Peter 2:20-3:1, 4-12",
  },
  "𝔓82": {
    date: "AD 300-500",
    passages: "Luke 7:32-34, 37-38",
  },
  "𝔓85": {
    date: "AD 300-500",
    passages: "Revelation 9:19-10:1, 5-9",
  },
  "𝔓86": {
    date: "AD 300-400",
    passages: "Matthew 5:13-16, 22-25",
  },
  "𝔓87": {
    date: "AD 200-300",
    passages: "Philemon 13-15, 24-25",
  },
  "𝔓88": {
    date: "AD 300-400",
    passages: "Mark 2:1-26",
  },
  "𝔓89": {
    date: "AD 300-400",
    passages: "Hebrews 6:7-9, 15-17",
  },
  "𝔓90": {
    date: "AD 100-200",
    passages: "John 18:36-19:1; 19:1-7",
  },
  "𝔓91": {
    date: "AD 200-300",
    passages: "Acts 2:30-37, 2:46-3:2",
  },
  "𝔓92": {
    date: "AD 200-400",
    passages: "Ephesians 1:11-13, 19-21; 2 Thessalonians 1:4-5, 11-12",
  },
  "𝔓95": {
    date: "AD 200-300",
    passages: "John 5:26-29, 36-38",
  },
  "𝔓98": {
    date: "AD 150-250",
    passages: "Revelation 1:13-20",
  },
  "𝔓100": {
    date: "AD 200-400",
    passages: "James 3:13-4:4, 4:9-5:1",
  },
  "𝔓101": {
    date: "AD 200-300",
    passages: "Matthew 3:10-12, 16-4:3",
  },
  "𝔓102": {
    date: "AD 200-400",
    passages: "Matthew 4:11-12, 22-23",
  },
  "𝔓104": {
    date: "AD 100-200",
    passages: "Matthew 21:34-37, 43-45?",
  },
  "𝔓106": {
    date: "AD 200-300",
    passages: "John 1:29-35, 40-46",
  },
  "𝔓107": {
    date: "AD 200-300",
    passages: "John 17:1-2, 11",
  },
  "𝔓108": {
    date: "AD 200-300",
    passages: "John 17:23-24, 18:1-5",
  },
  "𝔓109": {
    date: "AD 200-300",
    passages: "John 21:18-20, 23-25",
  },
  "𝔓110": {
    date: "AD 300-400",
    passages: "Matthew 10:13-15, 25-27",
  },
  "𝔓111": {
    date: "AD 200-300",
    passages: "Luke 17:11-13, 22-23",
  },
  "𝔓113": {
    date: "AD 200-300",
    passages: "Romans 2:12-13, 29",
  },
  "𝔓114": {
    date: "AD 200-300",
    passages: "Hebrews 1:7-12",
  },
  "𝔓115": {
    date: "AD 200-400",
    passages: "Revelation 2:1-3, 13-15, 27-29, 3:10-12, 5:8-9, 6:4-6, 8:3-8, 8:11-9:5, 9:7-16, 9:18-10:4, 8, 11:5, 8-15, 11:18-12:6, 9-10, 12-17, 13:1-3, 6-12, 13-16, 17-14:3, 5-7, 10-11, 14-16, 14:18-15:1, 5-7",
  },
  "𝔓116": {
    date: "AD 500-700",
    passages: "Hebrews 2:9-11, 3:3-6",
  },
  "𝔓117": {
    date: "AD 300-500",
    passages: "2 Corinthians 7:6-11",
  },
  "𝔓118": {
    date: "AD 200-300",
    passages: "Romans 15:26-27, 32-33, 16:1, 4-7, 11-12",
  },
  "𝔓119": {
    date: "AD 200-300",
    passages: "John 1:21-28, 38-44",
  },
  "𝔓120": {
    date: "AD 300-400",
    passages: "John 1:25-28, 33-38, 42-44",
  },
  "𝔓121": {
    date: "AD 200-300",
    passages: "John 19:17-18, 25-26",
  },
  "𝔓122": {
    date: "AD 300-500",
    passages: "John 21:11-14.22-24",
  },
  "𝔓123": {
    date: "AD 300-400",
    passages: "1 Corinthians 14:31-34, -15:3-6",
  },
  "𝔓125": {
    date: "AD 200-400",
    passages: "1 Peter 1:23-2:5; 2:7-12",
  },
  "𝔓126": {
    date: "AD 300-400",
    passages: "Hebrews 13:12-13.19-20",
  },
  "𝔓129": {
    date: "AD 200-300",
    passages: "1 Corinthians 8:10-9:3; 9:27-10:6; 1 Corinthians 7:32-37; 9:10-16",
  },
  "𝔓130": {
    date: "AD 200-400",
    passages: "Hebrews 9:9-12, 19-23",
  },
  "𝔓131": {
    date: "AD 200-300",
    passages: "Romans 9:18-23, 9:33-10:4",
  },
  "𝔓132": {
    date: "AD 200-400",
    passages: "Ephesians 3:21-4:2, 14-16",
  },
  "𝔓133": {
    date: "AD 200-300",
    passages: "1 Timothy 3:13-4:8",
  },
  "𝔓134": {
    date: "AD 200-400",
    passages: "John 1:49-2:1",
  },
  "𝔓135": {
    date: "AD 300-500",
    passages: "Galatians 3:21-22, 28-29, 4:31-5:6, 10-15",
  },
  "𝔓137": {
    date: "AD 150-250",
    passages: "Mark 1:7-9, 16-18",
  },
  "𝔓138": {
    date: "AD 200-300",
    passages: "Luke 13:13–17, 25–30",
  },
  "𝔓139": {
    date: "AD 300-400",
    passages: "Philemon 1:6-8, 18-20",
  },
  "01": {
    alt: "א",
    name: "Sinaiticus",
    date: "4th century",
    passages: "Entire New Testament",
  },
  "02": {
    alt: "A",
    name: "Alexandrinus",
    date: "5th century",
    passages: "Entire New Testament",
  },
  "03": {
    alt: "B",
    name: "Vaticanus",
    date: "4th century",
    passages: "Gospels, Acts, General Epistles, Pauline Epistles (lacking 1 Timothy through Philemon)",
  },
  "04": {
    alt: "C",
    name: "Ephraemi Rescriptus",
    date: "5th century",
    passages: "Entire New Testament",
  },
  "05": {
    alt: "Dea",
    name: "Bezae",
    date: "5th century",
    passages: "Gospels, Acts",
  },
  "029": {
    alt: "T",
    name: "Borgianus",
    date: "5th century",
    passages: "Luke 6:18-26, 18:2-16, 18:32-19-8, 21:33-22:3, 22:1-23:20, 24:25-27, 24:29-31; John",
  },
  "032": {
    alt: "W",
    name: "Washingtonianus",
    date: "5th century",
    passages: "Gospels",
  },
  "057": {
    date: "4th/5th century",
    passages: "Acts 3:5-6, 3:10-12",
  },
  "058": {
    date: "4th century",
    passages: "Matthew 18:18-19, 18:22-23, 18:25-26, 18:28-29",
  },
  "059": {
    date: "4th/5th century",
    passages: "Mark 15:20-21,26-27; Mark 15:29-38",
  },
  "0160": {
    date: "4th/5th century",
    passages: "Matthew 26:25-26, 34-36",
  },
  "0162": {
    date: "3rd/4th century",
    passages: "John 2:11–22",
  },
  "0169": {
    date: "4th century",
    passages: "Revelation 3:19-4:3",
  },
  "0171": {
    date: "3rd/4th century",
    passages: "Matthew 10:17-23, 25-32; Luke, 22:44-56, 61-63",
  },
  "0173": {
    date: "5th century",
    passages: "James 1:25-27",
  },
  "0176": {
    date: "4th/5th century",
    passages: "Galatians 3:16-25",
  },
  "0181": {
    date: "4th/5th century",
    passages: "Luke 9:59-10:14",
  },
  "0185": {
    date: "4th century",
    passages: "1 Corinthians 2:5-6, 9, 13, 3:2-3",
  },
  "0188": {
    date: "4th century",
    passages: "Mark 11:11-17",
  },
  "0189": {
    date: "2nd/3rd",
    passages: "Acts 5:3–21",
  },
  "0206": {
    date: "4th century",
    passages: "1 Peter 5:5-13",
  },
  "0207": {
    date: "4th century",
    passages: "Revelation 9:2–15",
  },
  "0214": {
    date: "4th/5th century",
    passages: "Mark 8:33-37",
  },
  "0219": {
    date: "4th/5th century",
    passages: "Romans 2:21-23, 3:8-9, 23-25, 27-30",
  },
  "0220": {
    date: "3rd/4th century",
    passages: "Romans 4:23–5:3, 5:8–13",
  },
  "0221": {
    date: "4th century",
    passages: "Romans 5:16-17, 19, 5:21-6:3",
  },
  "0228": {
    date: "4th century",
    passages: "Hebrews 12:19-21, 23-25",
  },
  "0230": {
    date: "4th century",
    passages: "Ephesians 6:11-12",
  },
  "0231": {
    date: "4th century",
    passages: "Matthew 26:75-27:1, 3-4",
  },
  "0232": {
    date: "5th/6th century",
    passages: "2 John 1:1-9",
  },
  "0242": {
    date: "4th century",
    passages: "Matthew 8:25-9:2, 13:32-38, 40-46",
  },
  "0252": {
    date: "5th century",
    passages: "Hebrews 6:2-4, 6-7",
  },
  "0258": {
    date: "4th century",
    passages: "John 10:25-26",
  },
  "0270": {
    date: "5th/6th century",
    passages: "1 Corinthians 15:-15, 19-25",
  },
  "0308": {
    date: "4th century",
    passages: "Revelation 11:15-16, 11:17-18",
  },
  "0312": {
    date: "3rd/4th century",
    passages: "Luke 5:23-24, 30-31, 7:9, 17-18",
  },
  "0315": {
    date: "4th/5th century",
    passages: "Mark 2:9, 21, 25, 3:1–2",
  },
  "0323": {
    date: "4th/5th century",
    passages: "John",
  }
}

export const getManuscriptInfoStr = id => (
  !manuscriptInfo[id]
    ? ``
    : (
      <>
        {
          manuscriptInfo[id].name
            ? i18n("{{name}} ({{alt}}) // {{date}}", "", "original-languages", manuscriptInfo[id])
            : manuscriptInfo[id].date
        }
        <Passages>
          {manuscriptInfo[id].passages}
        </Passages>
        <ClickToView>
          {i18n("Click to view manuscript layout and image on CNTR.", "", "original-languages")}
        </ClickToView>
      </>
    )
)
// i18n-default-category:"equip"

import { memo } from 'react'
import styled from 'styled-components'
import { i18n } from 'inline-i18n'
import { useLocation } from "react-router-dom"

import Header from "../../common/Header"
import LinkButton from '../../common/LinkButton'
import AppContent from '../../common/AppContent'

const StyledAppContent = styled(AppContent)`
  padding-bottom: 60px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

// const HeaderLogo = styled.img`
//   height: 38px;
//   vertical-align: top;
//   margin: 5px -6px 0;
//   user-select: none;
// `

const ButtonLogo = styled.img`
  height: 40px;
  margin: -7px 8px 8px;
`

const Title = styled.div`
  font-weight: bold;
  font-size: 20px;

  &:empty {
    display: none;
  }
`

const Body = styled.div`
  font-size: 18px;
  font-weight: 300;

  &:empty {
    display: none;
  }
`

const LearnMoreOn = styled.div`
  margin: 10px 0 0;
  font-size: 17px;
`

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
`

const StyledLinkButton = styled(LinkButton)`
`

const EquipLinkButton = styled(LinkButton)`
  margin-top: 30px;
  border-radius: 100px;

  .MuiButton-label {
    flex-direction: column;

  }
  
`

const Equip = ({ ...props }) => {

  const location = useLocation()

  const equipUrl = `https://equip.biblearc.com/${location.pathname.split(`/`).slice(2).join(`/`)}`

  const searchParams = new URLSearchParams(location.search)
  const title = searchParams.get(`title`)
  const body = searchParams.get(`body`)

  return (
    <>
      <Header
        {...props}
      >
        {/* <HeaderLogo src="/biblearc_equip_1.svg" /> */}
      </Header>

      <StyledAppContent>

        <Title>
          {title}
        </Title>

        <Body>
          {body}
        </Body>

        <Buttons>

          <EquipLinkButton
            href={equipUrl}
            variant="outlined"
          >
            <LearnMoreOn>
              {i18n("Learn more on")}
            </LearnMoreOn>
            <ButtonLogo src="/biblearc_equip_1.svg" />
          </EquipLinkButton>

          <StyledLinkButton
            to="/"
            variant="contained"
            disableElevation
            size="small"
          >
            {i18n("Go to The Biblearc Study Bible")}
          </StyledLinkButton>

        </Buttons>

      </StyledAppContent>

    </>
  )
}


export default memo(Equip)
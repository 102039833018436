import React, { memo } from 'react'
import styled from 'styled-components'
import * as Sentry from '@sentry/react'
import { i18n } from 'inline-i18n'
import Button from '@material-ui/core/Button'

import { IGNORE_ERRORS_REGEXES, IS_EMBED } from '../../utils/constants'
import { removeLocalStorage, getLocalStorage, setLocalStorage, equalObjs } from '../../utils/misc'
import { db } from '../../utils/database'
import { setThemeColor } from '../../hooks/useThemeColor'

import Loading from './Loading'
import FadedLoading from './FadedLoading'

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  color: ${({ $effectiveDarkModeOn }) => $effectiveDarkModeOn ? `#eee` : `black`};
  background-color: ${({ $effectiveDarkModeOn }) => $effectiveDarkModeOn ? `#262626` : `white`};
  padding: 20px;
`

const Heading = styled.div`
  font-size: 22px;
  font-weight: 100;
`

const Offline = styled.div`
  font-size: 26px;
  text-transform: uppercase;
`

const OfflineExplanation = styled.div`
  font-size: 17px;
  font-weight: 100;
  margin: 0 0 40px;
`

const Waiting = styled.div`
  font-size: 15px;
  font-weight: 300;
  margin: 10px 0 0;
`

const LoadingContainer = styled.div`
  position: relative;
  overflow: hidden;
  width: 70px;
  height: 70px;
  border-radius: 14%;
  background: url(${({ iconUrl }) => `"${iconUrl}"`});
  background-size: cover;
`

const StyledFadedLoading = styled(FadedLoading)`
  background-color: rgb(255 255 255/.5);
  .MuiCircularProgress-colorPrimary {
    color: black;
  }
`

const ErrorMessage = styled.div`
  border: 1px solid ${({ $effectiveDarkModeOn }) => $effectiveDarkModeOn ? `#555` : `#ddd`};
  background: ${({ $effectiveDarkModeOn }) => $effectiveDarkModeOn ? `#444` : `#eee`};
  border-radius: 3px;
  padding: 5px 8px;
  margin-top: 10px;
`

const Message = styled.div`
  margin: 20px 0;
  font-weight: 500;
`

const ButtonContainer = styled.div`
  display: flex;
`

const StyledButton = styled(Button)`
  background: ${({ $color }) => $color === "gray" ? `rgb(150 150 150)` : `#bdac59`} !important;
  color: white !important;
  margin: 0 5px !important;
`

const StylelessA = styled.a`
  text-decoration: none;
`

const report = (error, errorInfo) => {
  if(process.env.REACT_APP_STAGE === `development`) {
    console.error(`On production, will report this sort of error to sentry.`, error, errorInfo)
  } else {
    console.error(`Reporting this error to sentry.`, error, errorInfo)
    Sentry.captureException(
      error,
      {
        extra: {
          errorInfo,
        },
      }
    )
  }
}

const shouldIgnore = error => {
  const message = error.reason || error.message || ""
  return (
    IGNORE_ERRORS_REGEXES.some(errorRegex => errorRegex.test(message))
    || (
      // if it is an apple pen error
      typeof error === `object`
      && equalObjs(error, { isTrusted: false })
    )
  )
}

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      error: null,
      tryingNoIndexedDB: false,
      loggingOut: false,
    }
  }

  tryNoIndexedDB = error => {
    if(
      typeof error === `object`
      && error.name === `DatabaseClosedError`
      && !/[?&]noindexeddb(?:&|$)/.test(window.location.search)
    ) {
      const connectorChar = /\?/.test(window.location.href) ? `&` : `?`
      window.location = window.location.href.replace(/(#.*)?$/g, `${connectorChar}noindexeddb`)
      this.setState({ tryingNoIndexedDB: true })
      return true
    }
  }

  logOutOnNoLoginError = error => {
    if(IS_EMBED) return  // if embed, make sure this doesn't actually log out
    if(this.state.loggingOut) return
    const message = error.reason || error.message || ""
    const lastLogOutOnError = getLocalStorage('lastLogOutOnError', 0)
    if(
      [ /not logged in/ ].some(errorRegex => errorRegex.test(message))
      && Date.now() - lastLogOutOnError > 1000 * 60  // this disallows a vicious loop
    ) {
      setLocalStorage('lastLogOutOnError', Date.now())
      this.setState({ loggingOut: true })
      ;(async () => {
        let numQueuedMutations = 0
        try {
          numQueuedMutations = await db.queuedMutations.count()
        } catch(err) {}
        if(numQueuedMutations) {
          this.setState({ loggingOut: false })
          this.setState({ error })
        } else {
          window.sessionSyncAuth.logOut()
        }
      })()
    }
  }

  goThrowError = error => {
    if(
      !this.tryNoIndexedDB(error)
      && !shouldIgnore(error)
    ) {
      report(error)
      this.setState({ error })
    }
    this.logOutOnNoLoginError(error)
  }

  handleOnline = () => {
    if(window.navigator.onLine && this.state.error) {
      window.location.reload()
    }
  }

  componentDidMount() {
    window.addEventListener('unhandledrejection', this.goThrowError)
    window.addEventListener("error", this.goThrowError)
    window.addEventListener('online', this.handleOnline)
  }

  componentWillUnmount() {
    window.removeEventListener('unhandledrejection', this.goThrowError)
    window.removeEventListener("error", this.goThrowError)
    window.removeEventListener('online', this.handleOnline)
  }

  componentDidUpdate() {
    const { error } = this.state
    const online = window.navigator.onLine

    if(!online || error) {

      const { effectiveDarkModeOn, iconUrl } = getLocalStorage(`errorBondaryInfo`, {})

      if(
        effectiveDarkModeOn !== this.state.effectiveDarkModeOn
        || iconUrl !== this.state.iconUrl
      ) {

        setThemeColor({ effectiveDarkModeOn })

        this.setState({
          effectiveDarkModeOn,
          iconUrl,
        })

      }

    }

  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return {
      error,
    }
  }

  componentDidCatch(error, errorInfo) {
    if(
      !this.tryNoIndexedDB(error)
      && !shouldIgnore(error)
    ) {
      report(error, errorInfo)
      removeLocalStorage('inLoginProcess')
    }
    this.logOutOnNoLoginError(error)
  }

  render() {
    const { children, goUpdate, setResetApp } = this.props
    const { error, tryingNoIndexedDB, loggingOut, effectiveDarkModeOn, iconUrl } = this.state
    const online = window.navigator.onLine

    if(tryingNoIndexedDB || loggingOut) return <Loading />

    if(!online) {
      return (
        <Container $effectiveDarkModeOn={effectiveDarkModeOn}>
          <Offline>{i18n("Offline")}</Offline>
          <OfflineExplanation>{i18n("This app requires an active internet connection.")}</OfflineExplanation>
          <LoadingContainer iconUrl={iconUrl}>
            <StyledFadedLoading size={40} />
          </LoadingContainer>
          <Waiting>{i18n("Waiting for connection...")}</Waiting>
        </Container>
      )
    }

    // IMPORTANT NOTE: See useError to catch errors and present a dialog.

    if(error) {

      // You can render any custom fallback UI
      return (
        <Container $effectiveDarkModeOn={effectiveDarkModeOn}>
          <Heading>{i18n("Whoops! There was an error.")}</Heading>

          {!!error.message &&
            <ErrorMessage $effectiveDarkModeOn={effectiveDarkModeOn}>{error.message}</ErrorMessage>
          }

          {process.env.REACT_APP_STAGE === `staging` &&
            <ErrorMessage $effectiveDarkModeOn={effectiveDarkModeOn}>{JSON.stringify(error)}</ErrorMessage>
          }

          {/* check connection and tell them that is the problem (if it was a graphql server query) */}

          {!!goUpdate &&
            <>
              <Message>{i18n("Given that there is a pending update, let’s start by trying that.")}</Message>
              <StyledButton
                onClick={goUpdate}
                variant="contained"
              >
                {i18n("Apply update")}
              </StyledButton>
            </>
          }

          {!goUpdate &&
            <>
              <Message>{i18n("Let us know if this issue persists.")}</Message>
              <ButtonContainer>
                <StyledButton
                  onClick={setResetApp}
                  variant="contained"
                >
                  {i18n("Reset app")}
                </StyledButton>
                <StylelessA
                  href={`https://equip.biblearc.com/contact?defaultMessage=${`I received the following error on biblearc.com: "${encodeURIComponent(error.message)}". I was trying to...`}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <StyledButton
                    variant="contained"
                    $color="gray"
                  >
                    {i18n("Contact us")}
                  </StyledButton>
                </StylelessA>
              </ButtonContainer>
            </>
          }

          {/* option to reset app: see notes below */}

          <Message></Message>
        </Container>
      )

    }

    return children
  }
}

export default memo(ErrorBoundary)
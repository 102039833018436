// i18n-default-category:"study-bible"

import { memo, useContext } from 'react'
import styled from 'styled-components'
import { i18n } from 'inline-i18n'
import { i18nReact } from 'inline-i18n/build/i18nReact'

import { LoggedInUserContext } from '../../context/LoggedInUser'

import LinkButton from './LinkButton'
import NavLinkOrAWithDisable from './NavLinkOrAWithDisable'
import SketchDemoSpot from './SketchDemoSpot'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  padding: ${({ $showSketchPreview }) => $showSketchPreview ? `0 30px 10px` : `70px 30px 30px`};
  font-size: 17px;
  text-align: center;
`

const SubscribeSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`

const ButtonImage = styled.img`
  height: 30px;
  margin: -10px -5px -10px -3px;
`

const AboutImage = styled.img`
  height: 28px;
  margin: -11px -5px -10px -3px;
`

const Description = styled.div`
  font-weight: 300;
  font-size: 14px;
  max-width: 520px;
`

const AboutSection = styled.div`
  padding: 20px;
  background-color: rgb(0 0 0/.05);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`

const About = styled.div`
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 300;
`

const SubscribeToMessage = styled.div`
`

const TagLine = styled.span`
  font-weight: 500;
`

const LearnMore = styled.div`
  font-size: 14px;
  font-weight: 300;
`

const StyledSketchDemoSpot = styled(SketchDemoSpot)`
  margin-bottom: 20px;
`

const MyPlanPromoSpot = ({
  subscribeToMessage,
  hideAboutHeading,
  showSketchPreview,
  sketchPreviewWidth,
  ...otherProps
}) => {

  const { hasMyPlan } = useContext(LoggedInUserContext) || {}

  if(hasMyPlan) return null

  return (
    <Container
      $showSketchPreview={showSketchPreview}
      {...otherProps}
    >

      <SubscribeSection>

        {!!showSketchPreview && <StyledSketchDemoSpot width={sketchPreviewWidth} />}

        {!!subscribeToMessage &&
          <SubscribeToMessage className="MyPlanPromoSpot-SubscribeToMessage">
            {subscribeToMessage}
          </SubscribeToMessage>
        }

        <LinkButton
          to="/subscribe"
          variant="outlined"
          className="MyPlanPromoSpot-LinkButton"
        >
          {i18nReact("Get {{thing_to_get}}", {
            thing_to_get: (
              <ButtonImage src="/my_biblearc_study_bible_1.svg" />
            ),
          })}
        </LinkButton>

      </SubscribeSection>

      <AboutSection
        className="MyPlanPromoSpot-AboutSection"
      >

        {!hideAboutHeading &&
          <About>
            {i18nReact("About {{logo}}", {
              logo: <AboutImage src="/my_biblearc_study_bible_1.svg" />,
            })}
          </About>
        }

        <Description>
          {i18n("Integrate your church’s sermons and videos from popular ministries, powerfully markup your Bible, and personalize maps.")}
          {` `}
          <TagLine>
            {i18n("Make The Biblearc Study Bible your own.")}
          </TagLine>
          {` `}
          {i18n("Just $2.99 per month.")}
        </Description>

        <LearnMore>
          <NavLinkOrAWithDisable
            to="/about-my-bsb"
          >
            {i18n("Learn more")}
          </NavLinkOrAWithDisable>
        </LearnMore>

      </AboutSection>

    </Container>
  )
}

export default memo(MyPlanPromoSpot)
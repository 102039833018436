import { gql } from '@apollo/client'

import i18nStrings_fields from '../fragments/i18nStrings_fields'

export default gql
`
  mutation updateI18nString($hash: String!, $languageId: String!, $prevUpdatedAt: Milliseconds!, $input: I18nStringInput!) {
    updateI18nString(hash: $hash, languageId: $languageId, prevUpdatedAt: $prevUpdatedAt, input: $input) {
      ${i18nStrings_fields}
    }
  }
`
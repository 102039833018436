// i18n-default-category:"church-bible"

import { memo, useCallback, useState } from 'react'
import styled from 'styled-components'
// import { NavLink } from "react-router-dom"
import { i18n } from 'inline-i18n'
import TextField from '@material-ui/core/TextField'
import { useMutation } from '@apollo/client'

import useRefState from '../../../hooks/useRefState'
import useMutationContext from '../../../hooks/useMutationContext'
import useEffectAsync from '../../../hooks/useEffectAsync'
import { fixName, fixShortName, fixLocation, getRandomColorHex  } from '../create-a-church-bible/CreateAChurchBible'

import ConfirmDialog from '../../common/ConfirmDialog'
import AppIconCreator from '../create-a-church-bible/AppIconCreator'

import updateChannelMutation from '../../../graphql/mutations/updateChannel'

const StyledTextField = styled(TextField)`
  width: 500px;
`

const Footer = styled.div`
  font-style: italic;
  margin: 15px 0 0;
`

const EditChannelDetailDialog = ({
  channelId,
  detail,
  currentValue,
  open,
  onCancel,
  multiline,
  shortName,
}) => {

  const [ updateChannel ] = useMutation(updateChannelMutation)
  const context = useMutationContext()

  const [ imageEditInfo, setImageEditInfo, getImageEditInfo ] = useRefState({ bgColor: getRandomColorHex() })
  const [ loading, setLoading ] = useState(false)
  const [ value, setValue, getValue ] = useRefState(``)

  const onChange = useCallback(
    ({ target }) => {
      setValue(
        (detail === `name` && fixName(target.value))
        || (detail === `shortName` && fixShortName(target.value))
        || (detail === `location` && fixLocation(target.value))
        || target.value
      )
    },
    [ detail, setValue ],
  )

  const onConfirm = useCallback(
    async () => {

      setLoading(true)

      await updateChannel({
        variables: {
          id: channelId,
          input: {
            [detail]: (
              detail === `imageEditInfo`
                ? getImageEditInfo()
                : getValue()
            ),
          },
        },
        context,
      })

      onCancel()

    },
    [ setLoading, updateChannel, channelId, getValue, onCancel, detail, context, getImageEditInfo ],
  )

  useEffectAsync(
    () => {
      if(open) {
        setLoading(false)
        setValue(currentValue)
      }
    },
    [ open ],
  )

  const readyToConfirm = (
    detail === `imageEditInfo`
    || (
      (value || ``).trim()
      && value.trim() !== currentValue
    )
  )

  return (
    <ConfirmDialog
      open={open}
      onConfirm={onConfirm}
      onCancel={onCancel}
      loading={loading}
      disabled={!readyToConfirm}
      explanation={
        <>
          {
            detail === `imageEditInfo`
              ? (
                <AppIconCreator
                  shortName={shortName}
                  imageEditInfo={imageEditInfo}
                  setImageEditInfo={setImageEditInfo}
                />
              )
              : (
                <StyledTextField
                  value={value}
                  onChange={onChange}
                  label={{
                    name: i18n("Name"),
                    shortName: i18n("Short Name"),
                    location: i18n("Location"),
                    description: i18n("About"),
                  }[detail]}
                  variant="outlined"
                  size="small"
                  multiline={multiline}
                  minRows={5}
                  maxRows={15}
                />
              )
          }
          {[ `shortName`, `imageEditInfo` ].includes(detail) &&
            <Footer>
              {i18n("Note: App icon and name changes will only affect new installs.")}
            </Footer>
          }
        </>
      }
    />
  )
}

export default memo(EditChannelDetailDialog)
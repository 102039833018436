// i18n-default-category:"study-bible"

import { memo, useCallback, useState, useMemo } from 'react'
import { i18n } from 'inline-i18n'
import styled from 'styled-components'
import { Virtuoso } from 'react-virtuoso'

import useModalAnchor from '../../hooks/useModalAnchor'
import useLayoutEffectAsync from '../../hooks/useLayoutEffectAsync'
import useAppSize from '../../hooks/useAppSize'

import StudyBibleItemThumbnail from './StudyBibleItemThumbnail'
import OptionsPopper from '../common/OptionsPopper'
import StudyBibleItemsPassageRef from './StudyBibleItemsPassageRef'

const gap = 15
const entryWidth = 208
const typicalRowHeight = 186

const StyledOptionsPopper = styled(OptionsPopper)`
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: ${gap}px;
  padding: ${gap/2}px ${gap}px;
`

const Link = styled.div`
  display: inline-block;
  font-size: 13px;
  line-height: 25px;
  transform: translateY(-3px);
  transition: opacity .15s ease-in-out;
  color: ${({ theme }) => theme.palette.secondary.main};
  text-decoration: underline;

  ${({ $open }) => !$open ? `` : `
    color: black;
    text-decoration: none;
  `};
  
  @media (hover: hover) {
    &:hover {
      cursor: pointer;
      opacity: .4;
    }
  }
`

const Entry = styled.div`
  width: ${entryWidth}px;
  margin: -5px 0 -10px;
`

const FooterSpacer = styled.div`
  height: ${gap/2}px;
`

const StyledVirtuoso = styled(Virtuoso)`
  height: ${({ totalCount }) => totalCount * typicalRowHeight + gap/2}px !important;
  max-height: min(calc(100vh - 16px), 700px);
  width: ${({ $numCols }) => $numCols * entryWidth + gap * ($numCols + 1)}px;
  color: black;
`

const getSortValue = ({ fromLoc, toLoc }) => `${fromLoc} ${toLoc || `00000000`}`

const PassageStudyBibleViewOrMoreLink = ({
  isMoreLink,
  isRTL,
  hiddenStudyBibleItems,
  setStudyBibleItemInfo,
  setChannelItemInfo,
  versionId,
  children,
  ...otherProps
}) => {

  const { anchorEl, openModal, closeModal } = useModalAnchor()
  const [ numCols, setNumCols ] = useState(0)
  const { width } = useAppSize()

  const sortedHiddenStudyBibleItemRows = useMemo(
    () => {
      const sortedHiddenStudyBibleItems = (
        hiddenStudyBibleItems
          .slice(0)
          .sort((a,b) => getSortValue(a) < getSortValue(b) ? -1 : 1)
      )
      const rows = []
      sortedHiddenStudyBibleItems.forEach((studyBibleItem, idx) => {
        const rowIndex = Math.floor(idx / numCols)
        rows[rowIndex] = rows[rowIndex] || []
        rows[rowIndex].push(studyBibleItem)
      })
      return rows
    },
    [ hiddenStudyBibleItems, numCols ]
  )

  useLayoutEffectAsync(
    () => {
      if(anchorEl) {
        setNumCols(
          Math.max(
            Math.min(
              (
                isRTL
                  ? Math.floor(((width - anchorEl.getBoundingClientRect().right) - gap - 25) / (entryWidth + gap))
                  : Math.floor((anchorEl.getBoundingClientRect().left - gap - 25) / (entryWidth + gap))
              ),
              Math.ceil(hiddenStudyBibleItems.length / 3),
              3,
            ),
            1
          )
        )
      }
    },
    [ anchorEl, isRTL, width ],
  )

  const setItemInfoAndCloseModal = useCallback(
    info => {
      if((info.studyBibleItem || {}).type === `CHANNEL`) {
        setChannelItemInfo(info)
      } else {
        setStudyBibleItemInfo(info)
      }
      closeModal()
    },
    [ setStudyBibleItemInfo, setChannelItemInfo, closeModal ],
  )

  return (
    <>

      <Link
        onClick={openModal}
        $open={!!anchorEl}
        {...otherProps}
      >
        {
          children
          || (
            isMoreLink
              ? i18n("View {{num}} more", { num: hiddenStudyBibleItems.length })
              : i18n("View {{num}} item(s)", { num: hiddenStudyBibleItems.length })
          )
        }
      </Link>

      <StyledOptionsPopper
        open={!!anchorEl && numCols > 0}
        anchorEl={anchorEl}
        onClose={closeModal}
        bgColor="white"
        placement={isRTL ? `right` : `left`}
        onTopOfAll
        isModal
        $cols={numCols}
        allowDarkMode
      >

        <StyledVirtuoso
          totalCount={sortedHiddenStudyBibleItemRows.length}
          $numCols={numCols}
          itemContent={index => (
            <Row key={index}>
              {sortedHiddenStudyBibleItemRows[index].map(studyBibleItem => (
                <Entry key={studyBibleItem.id}>

                  <StudyBibleItemsPassageRef
                    fromLoc={studyBibleItem.fromLoc}
                    toLoc={studyBibleItem.toLoc}
                    convertToVersionId={versionId}
                  />

                  <StudyBibleItemThumbnail
                    studyBibleItem={studyBibleItem}
                    versionId={versionId}
                    setItemInfo={setItemInfoAndCloseModal}
                    fromViewOrMoreLink
                    isRTL={isRTL}
                  />

                </Entry>
              ))}
            </Row>
          )}
          components={{
            Footer: () => <FooterSpacer />,
          }}
          tabIndex={-1}
        />

      </StyledOptionsPopper>

    </>
  )
}

export default memo(PassageStudyBibleViewOrMoreLink)
// i18n-default-category:"admin"

import { memo, useState, useCallback } from 'react'
import { i18n } from 'inline-i18n'
import styled from 'styled-components'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'

import useInstanceValue from '../../hooks/useInstanceValue'
import useEffectAsync from '../../hooks/useEffectAsync'
import useInstanceValuesCallback from '../../hooks/useInstanceValuesCallback'

import ConfirmDialog from '../common/ConfirmDialog'
import LazyLoadPageViewer from '../common/LazyLoadPageViewer'
import StudyBiblePageEditorPage from './StudyBiblePageEditorPage'
import StudyBibleDots from './StudyBibleDots'
import SketchCanvas from '../common/SketchCanvas'

const StyledConfirmDialog = styled(ConfirmDialog)`

  pointer-events: none;
  user-select: none;
  display: flex;
  justify-content: flex-end;

  .MuiDialogTitle-root {
    padding-bottom: 0;
  }

  .MuiDialogContentText-root {
    flex: 1;
    min-height: 0;
    display: flex;
    flex-direction: column;
  }

  .MuiDialog-paper {
    align-self: flex-end;
    max-width: none;
    margin: 10px;
    pointer-events: all;
  }

  .MuiDialogContent-root {
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }

  .MuiDialogActions-root {
    position: absolute;
    top: 5px;
    right: 5px;
  }

`

const Container = styled.div`
  position: relative;
`

const StyledLazyLoadPageViewer = styled(LazyLoadPageViewer)`
  position: relative;
  height: 180px;
  z-index: 5;
  margin-bottom: -15px;

  .LazyLoadPageViewer-PageContainer {
    background: none;
  }
`

const ImageContainer = styled.div`
  position: relative;
  z-index: 2;
  margin-bottom: 40px;

  .SketchCanvas-Container {
    overflow: visible;
  }

  .SketchCanvas-Palette {
    transform: translateY(43px);
  }
`

const Image = styled.img`
  vertical-align: middle;
  outline: 1px solid rgb(0 0 0/.1);
`

const StyledDeleteIconButton = styled(IconButton)`
  color: ${({ $color, theme }) => (theme.palette[$color] || {}).main || (theme.palette.markupColors[$color] || {}).line || $color || `black`};
  padding: 10px;
  position: absolute;
  right: 0;
  top: 172px;
  z-index: 4;

  .MuiSvgIcon-root {
    width: 18px;
    height: 18px;
  }
`


const StudyBiblePageEditorDialog = ({
  open,
  onClose,
  type,
  details,
  onChangeDetails,
  imageUrl,
  ...otherProps
}) => {

  details = [ ...(details || []) ]
  details.length === 0 && details.push({ ordering: 1 })
  const getDetails = useInstanceValue(details)
  const [ detailsInEdit, setDetailsInEdit ] = useState(details)
  const [ pageKey, setPageKey ] = useState(0)
  const getDetailsInEdit = useInstanceValue(detailsInEdit)
  const getPageKey = useInstanceValue(pageKey)
  const [ naturalImageSize, setNaturalImageSize ] = useState({})

  const onLoadImage = useCallback(
    ({ target }) => {
      const { naturalWidth, naturalHeight } = target
      setNaturalImageSize({
        width: naturalWidth / 2,
        height: naturalHeight / 2,
      })
    },
    [],
  )

  const updateDetail = useCallback(
    updates => {
      const pageKey = getPageKey()
      const newDetailsInEdit = [ ...getDetailsInEdit() ]
      newDetailsInEdit[pageKey] = {
        ...newDetailsInEdit[pageKey],
        ...updates,
      }
      setDetailsInEdit(newDetailsInEdit)
    },
    [ getDetailsInEdit, getPageKey ],
  )

  const updateSketchInfo = useInstanceValuesCallback(
    sketchInfo => {
      updateDetail({
        info: {
          ...(getDetailsInEdit()[getPageKey()].info || {}),
          ...sketchInfo,
        },
      })
    }
  )

  const removeDetail = useCallback(
    () => {
      const pageKey = getPageKey()
      const newDetailsInEdit = [ ...getDetailsInEdit() ]
      newDetailsInEdit.splice(pageKey, 1)
      newDetailsInEdit.length === 0 && newDetailsInEdit.push({ ordering: 1 })
      if(getPageKey() > newDetailsInEdit.length - 1) {
        setPageKey(newDetailsInEdit.length - 1)
      }
      setDetailsInEdit(newDetailsInEdit)
      },
    [ getDetailsInEdit, getPageKey ],
  )

  const onConfirm = useCallback(
    () => {
      onChangeDetails(getDetailsInEdit().slice(0,-1))
      onClose()
    },
    [ onClose, onChangeDetails, getDetailsInEdit ],
  )

  const onCancel = useCallback(
    () => {
      setPageKey(0)
      setDetailsInEdit(getDetails())
      onClose()
    },
    [ onClose, getDetails ],
  )

  const { svgStrokes, utensilState } = detailsInEdit[pageKey].info || {}
  const readyForNewPage = (
    (detailsInEdit.at(-1).text || ``).trim()
    || (detailsInEdit.at(-1).info || {}).svgStrokes
  )

  useEffectAsync(
    () => {
      if(open) {
        setDetailsInEdit(details)
      }
    },
    [ open ],
  )

  useEffectAsync(
    () => {
      if(readyForNewPage) {
        setDetailsInEdit([
          ...getDetailsInEdit(),
          {
            ordering: detailsInEdit.at(-1).ordering + 1
          },
        ])
      }
    },
    [ readyForNewPage ],
  )

  return (
    <StyledConfirmDialog
      {...otherProps}
      open={open}
      onConfirm={onConfirm}
      onCancel={onCancel}
      confirmButtonLabel={i18n("Save")}
      cancelButtonLabel={i18n("Cancel")}
      title={i18n("Add / Edit Pages")}
      disableEnforceFocus
      explanation={
        <Container>
          <StyledLazyLoadPageViewer
            pageKey={pageKey}
            page={
              <StudyBiblePageEditorPage
                pageKey={pageKey}
                detail={detailsInEdit[pageKey]}
                updateDetail={updateDetail}
                type={type}
              />
            }
            setPageKey={setPageKey}
            previousPageKey={pageKey - 1}
            nextPageKey={pageKey + 1}
          />
          <StudyBibleDots
            numDots={detailsInEdit.length}
            pageKey={pageKey}
            setPageKey={setPageKey}
          />
          <ImageContainer>
            <SketchCanvas
              svgStrokes={svgStrokes}
              utensilState={utensilState}
              updateSketchInfo={updateSketchInfo}
              inEditingMode
            />
            <Image
              src={imageUrl}
              onLoad={onLoadImage}
              style={naturalImageSize}
            />
          </ImageContainer>
          <StyledDeleteIconButton
            onClick={removeDetail}
            $color="tertiary"
          >
            <DeleteIcon />
          </StyledDeleteIconButton>
        </Container>
      }
      hideBackdrop
    />
  )
}

export default memo(StudyBiblePageEditorDialog)
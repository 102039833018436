import { memo, useCallback, useState, useMemo } from 'react'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { i18n } from 'inline-i18n'
import { useMutation } from '@apollo/client'

import useDataQuery from '../../../hooks/useDataQuery'
import useRefState from '../../../hooks/useRefState'
import useMutationContext from '../../../hooks/useMutationContext'

import GiveAwayCodeRow from './GiveAwayCodeRow'

import actionCodesQuery from '../../../graphql/queries/actionCodes'
import createActionCodesMutation from '../../../graphql/mutations/createActionCodes'

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Container = styled.div`
  align-self: center;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const NewAlertContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.palette.divider};
  border-radius: 5px;
  padding: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
`

const StyledTextField = styled(TextField)`
  width: 140px;
`

const StyledTextField2 = styled(TextField)`
  width: 220px;
`

const StyledKeyboardDatePicker = styled(KeyboardDatePicker)`
  width: 180px;

  .MuiInputBase-root {
    padding: 0;
  }

  .MuiInputBase-input {
    padding-top: 10.5px;
    padding-bottom: 10.5px;
  }
`

const Heading = styled.div`
  margin: 35px 0 15px;
  font-size: 18px;
  font-weight: 500;
`

const initialNewInfo = {
  plan: `MY`,
  numDays: `365`,
  expirationDate: new Date(Date.now() + 1000*60*60*24*14),
  num: `1`,
}

const GiveAwayCodesTab = () => {

  const [ createActionCodes ] = useMutation(createActionCodesMutation)
  const [ updating, setUpdating ] = useState(false)
  const context = useMutationContext()
  const [ newInfo, setNewInfo, getNewInfo ] = useRefState(initialNewInfo)

  const { actionCodes, loading, refetch } = useDataQuery({
    actionCodesQuery,
    fetchPolicy: 'network-only',
  })

  const commonStyledTextFieldProps = useMemo(
    () => ({
      variant: "outlined",
      size: "small",
      InputLabelProps: {
        shrink: true,
      },
      disabled: loading || updating,
    }),
    [ loading, updating ],
  )

  const actionCodeSets = useMemo(
    () => {
      if(!actionCodes) return {}
      const actionCodeSets = {}
      actionCodes.forEach(actionCode => {
        const { action, details, expiresAt, user } = actionCode
        const setId = `${action}-${details.plan}-${details.numDays}-${details.firstTimeSubscribersOnly}-${expiresAt}-${(user || {}).id}`
        actionCodeSets[setId] = actionCodeSets[setId] || []
        actionCodeSets[setId].push(actionCode)
      })
      return actionCodeSets
    },
    [ actionCodes ],
  )

  const onChange = useCallback(
    eventOrDate => {
      const { target } = eventOrDate
      const newInfo = { ...getNewInfo() }

      if(!target) {
        newInfo.expirationDate = eventOrDate
        setNewInfo(newInfo)
        return
      }

      const key = target.closest ? target.closest('[data-key]').getAttribute('data-key') : `plan`
      newInfo[key] = target.value

      if([ `numDays`, `num` ].includes(key)) {
        newInfo[key] = newInfo[key].replace(/[^0-9]/g, ``)
      }

      setNewInfo(newInfo)
    },
    [ getNewInfo, setNewInfo ],
  )

  const goCreateNewInfo = useCallback(
    async () => {

      setUpdating(true)

      try {

        await createActionCodes({
          variables: {
            input: {
              action: `REDEEM-FREE-SUBSCRIPTION`,
              details: {
                plan: getNewInfo().plan,
                numDays: parseInt(getNewInfo().numDays, 10),
                firstTimeSubscribersOnly: true,
              },
              expiresAt: getNewInfo().expirationDate.getTime(),
              num: parseInt(getNewInfo().num, 10),
              shortenForm: true,
            },
          },
          context,
        })

        setNewInfo(initialNewInfo)
        await refetch()

      } catch(e) {
        alert(e.message ? `ERROR: ${e.message}` : `Unknown error creating action codes`)
        console.log(`Error creating action codes:`, e.message || e)
      }

      setUpdating(false)

    },
    [ getNewInfo, createActionCodes, context, setUpdating, setNewInfo, refetch ],
  )

  const readyToCreate = !!(
    parseInt(newInfo.numDays, 10) > 0
    && parseInt(newInfo.num, 10) > 0
  )

  return (
    <OuterContainer>
      <Container>

        <NewAlertContainer>

          <StyledTextField2
            {...commonStyledTextFieldProps}
            label={i18n("Subscription Level")}
            select
            value={newInfo.plan}
            onChange={onChange}
          >
            <MenuItem value="MY">
              {i18n("My Biblearc Study Bible")}
            </MenuItem>
            <MenuItem value="TOOLS">
              {i18n("Biblearc TOOLS")}
            </MenuItem>
            <MenuItem value="EQUIP">
              {i18n("Biblearc EQUIP")}
            </MenuItem>
          </StyledTextField2>

          <StyledTextField
            {...commonStyledTextFieldProps}
            label={i18n("Number of days")}
            value={newInfo.numDays}
            onChange={onChange}
            data-key="numDays"
          />

          <StyledKeyboardDatePicker
            views={['date']}
            label={i18n("Expiration Date")}
            minDate={new Date()}
            maxDate={new Date(Date.now() + 1000*60*60*24*31)}
            value={newInfo.expirationDate}
            onChange={onChange}
            inputProps={{
              disabled: true,
            }}
            inputVariant="outlined"
            slots={{
              textField: StyledTextField,
            }}
            KeyboardButtonProps={{
              disabled: loading || updating,
            }}
          />

          <StyledTextField
            {...commonStyledTextFieldProps}
            label={i18n("Number of codes")}
            value={newInfo.num}
            onChange={onChange}
            data-key="num"
          />

          <Button
            onClick={goCreateNewInfo}
            variant="contained"
            disableElevation
            color="secondary"
            disabled={!readyToCreate}
          >
            {i18n("Create")}
          </Button>

        </NewAlertContainer>

        <Heading>
          {i18n("Give-Away Code Sets")}
        </Heading>

        {Object.keys(actionCodeSets).map(setId => (
          <GiveAwayCodeRow
            key={setId}
            actionCodes={actionCodeSets[setId]}
          />
        ))}

      </Container>
    </OuterContainer>
  )
}


export default memo(GiveAwayCodesTab)
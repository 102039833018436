import { getLanguageInfo } from '@bibletags/bibletags-ui-helper/node_build/language'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { memo, useContext, useState } from 'react'
import styled from 'styled-components'
import { useMutation, useApolloClient } from '@apollo/client'

import useInstanceValuesCallback from '../../../hooks/useInstanceValuesCallback'
import { capitalize, cloneObj, equalObjs, preventDefaultEvent, usingModifierKey } from '../../../utils/misc'

import FadedLoading from '../../common/FadedLoading'

import updateI18nStringMutation from '../../../graphql/mutations/updateI18nString'
import i18nStringsQuery from '../../../graphql/queries/i18nStrings'
import { LoggedInUserContext } from '../../../context/LoggedInUser'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  border: 1px solid ${({ theme }) => theme.palette.tertiary.main};
  background: ${({ theme }) => theme.palette.tertiary.main}19;
  border-radius: 4px;
  margin-top: 20px;
  max-height: calc(100vh - 90px);
  overflow-y: auto;
`

const EnglishContainer = styled.div`
  min-width: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border: 1px solid ${({ theme, $isSelected }) => $isSelected ? theme.palette.tertiary.main : theme.palette.divider};
  border-radius: 4px;
  padding: 10.5px 14px;
  background: white;
  margin-bottom: 10px;
`

const StyledTextField = styled(TextField)`
  font-size: 17px;
  white-space: pre-wrap;
  background: white;
  margin-bottom: 10px;

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: rgb(0 0 0/.7) !important;
  }
`

const English = styled.span`
  white-space: pre-wrap;
  background: ${({ theme }) => theme.palette.primary.light};
  font-size: 16px;
`

const Desc = styled.div`
  font-size: 12px;
  font-weight: 500;
`

const Category = styled.div`
  font-size: 12px;
  font-weight: 300;
`

const History = styled.div`
`

const Label = styled.div`
  font-size: 13px;
`

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin: 5px 0 15px;
`

const StyledButton = styled(Button)`
  background: black;
  color: white;

  &:hover {
    background: rgb(0 0 0/.7);
  }
`

const GTranslate = styled.div`
  font-size: 12px;
  font-weight: 300;
`

const GTranslation = styled.span`
  font-weight: 500;
  margin: 10px 0;
  
  &:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.palette.secondary.main};
  }
`

const StyledIconButton = styled(IconButton)`
  padding: 8px;

  .MuiSvgIcon-root {
    font-size: 20px;
  }
`

const HeaderButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: -8px -8px 0;
`

const TranslateEditor = ({
  languageId,
  hash,
  nextHash,
  setHash,
  i18nEnglishString,
  i18nTranslationString,
  googleTranslateString,
  editedValue,
  setEditedValue,
  hasUnsavedEdits,
  i18nStrings,
}) => {

  const [ updateI18nString ] = useMutation(updateI18nStringMutation)
  const client = useApolloClient()
  const user = useContext(LoggedInUserContext)

  const { englishName, nativeName } = getLanguageInfo(languageId)

  const { engText, desc, category } = i18nEnglishString
  // const { descRequests } = i18nEnglishString.info || {}
  const { value } = (i18nTranslationString || {}).info || {}

  const [ saving, setSaving ] = useState(false)

  const onClose = useInstanceValuesCallback(() => setHash(null))

  const onChange = useInstanceValuesCallback(
    ({ target }) => {
      setEditedValue(target.value.replace(/\n|\r|\t/g, ``))
    }
  )

  const goSave = useInstanceValuesCallback(
    async () => {
      setSaving(true)

      const { data } = await updateI18nString({
        variables: {
          languageId,
          hash,
          prevUpdatedAt: i18nStrings.updatedAt,
          input: {
            value: editedValue,
          },
        }
      })

      if(data.updateI18nString.id === `no-other-updates`) {

        const now = Date.now()
        const newI18nTranslationStrings = cloneObj(i18nStrings.i18nTranslationStrings)

        let i18nTranslationString = newI18nTranslationStrings.find(i18nTranslationString => i18nTranslationString.hash === hash)
        if(!i18nTranslationString)  {
          i18nTranslationString = {
            hash,
            info: {
              value: editedValue,
              valueHistory: [],
            },
            createdAt: now,
            updatedAt: now,
            languageId,
          }
          newI18nTranslationStrings.push(i18nTranslationString)
        }
        i18nTranslationString.info.valueHistory.push({
          value,
          createdAt: now,
          userId: user.id,
        })
        if([ 'ADMIN', 'EDITOR', 'CONTRIBUTOR', 'TRANSLATOR' ].includes(user.adminLevel)) {
          i18nTranslationString.info.value = editedValue
        }

        client.writeQuery({
          query: i18nStringsQuery,
          data: {
            i18nStrings: {
              ...cloneObj(i18nStrings),
              i18nTranslationStrings: newI18nTranslationStrings,
              updatedAt: data.updateI18nString.updatedAt,
            },
          },
          variables: {
            id: languageId,
          },
        })

      }

      setSaving(false)

    }
  )

  const onKeyDown = useInstanceValuesCallback(
    event => {
      if(event.key === `Enter`) {
        preventDefaultEvent(event)
        if(usingModifierKey(event)) {
          goSave()
          nextHash && setHash(nextHash)
        }
      }
    }
  )

  const goReset = useInstanceValuesCallback(() => setEditedValue(value))

  const goUseGoogleTranslate = useInstanceValuesCallback(() => setEditedValue(googleTranslateString))

  const readyToSave = (
    equalObjs(
      (engText || ``).match(/\{\{.*\}\}/g),
      (editedValue || ``).match(/\{\{.*\}\}/g),
    )
  )

  return (
    <Container>

      <HeaderButtons>
        <StyledIconButton
          onClick={onClose}
          disabled={saving}
        >
          <CloseIcon />
        </StyledIconButton>
      </HeaderButtons>

      <Label>English</Label>

      <EnglishContainer>

        <English>
          {engText}
        </English>

      </EnglishContainer>

      <Label>{englishName === nativeName ? englishName : `${englishName} (${nativeName})`}</Label>

      <StyledTextField
        variant="outlined"
        value={editedValue || ``}
        onChange={onChange}
        onKeyDown={onKeyDown}
        multiline
        size="small"
        disabled={saving}
      />

      <Desc>
        Explanation: {desc || `[None]`}
      </Desc>

      <Category>
        Category: {capitalize(category)}
      </Category>

      <Buttons>
        <Button
          variant="outlined"
          onClick={goReset}
          disabled={editedValue === value || saving}
        >
          Reset
        </Button>
        <StyledButton
          variant="contained"
          disableElevation
          onClick={goSave}
          disabled={!hasUnsavedEdits || !readyToSave || saving}
        >
          Save
        </StyledButton>
      </Buttons>

      {!!googleTranslateString &&
        <GTranslate>
          From Google Translate:
          {` `}
          <GTranslation onClick={goUseGoogleTranslate}>
            {googleTranslateString}
          </GTranslation>
        </GTranslate>
      }

      <History>

      </History>

      {saving && <FadedLoading />}

    </Container>
  )
}


export default memo(TranslateEditor)
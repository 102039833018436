// i18n-default-category:"subscriptions"

import { memo } from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import { i18n } from 'inline-i18n'
import styled from 'styled-components'

const StyledCheckCircleIcon = styled(CheckCircleIcon)`
  height: 16px;
  position: absolute;
  top: 13px;
  right: 11px;
  z-index: 1;
  color: ${({ theme, $color }) => theme.palette[$color || `primary`].main};
`

const SubscribedIndicator = ({
  title,
  ...otherProps
}) => (

  <Tooltip title={title || i18n("Subscribed")}>
    <StyledCheckCircleIcon {...otherProps} />
  </Tooltip>

)

export default memo(SubscribedIndicator)

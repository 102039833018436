// i18n-default-category:"church-bible"

import { memo } from 'react'
import { useLocation } from "react-router-dom"
import styled from 'styled-components'
import { i18n } from 'inline-i18n'
import { i18nReact } from 'inline-i18n/build/i18nReact'
import Button from '@material-ui/core/Button'
import CheckIcon from '@material-ui/icons/Check'
import LinkIcon from '@material-ui/icons/Link'
import ShareIcon from '@material-ui/icons/Share'

import useAccountSetting from '../../../hooks/useAccountSetting'
import useShareOrCopy from '../../../hooks/useShareOrCopy'

import Header from "../../common/Header"
import NavLinkOrAWithDisable from '../../common/NavLinkOrAWithDisable'
import LinkButton from '../../common/LinkButton'

const Container = styled.div`
  flex: 1;
  overflow: auto;
  padding: 10px 20px 80px;
  background-color: white;
  position: relative;
`

const Content = styled.div`
  max-width: 800px;
  margin: 0 auto;
  font-size: 16px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 40vh;
`

const SectionHeading = styled.div`
  margin: 50px 0 10px;
  font-size: 20px;
  font-weight: 300;
  padding: 0 10px;
  background: ${({ theme }) => theme.palette.primary.veryLight}88;
`

const Question = styled.div`
  margin: 30px 0 10px;
  font-size: 26px;
  font-weight: 300;
`

const Answer = styled.div`
  margin: 5px 0 20px;
  padding: 2px 7px;
  font-size: 18px;
  font-weight: bold;
`

const AnswerOL = styled.ol`
  text-align: left;
`

const MoreInfo = styled.div`
  margin: -5px 0 20px;
  padding: 2px 7px;
  font-size: 15.5px;
  font-style: italic;
`

const PromoImage = styled.img`
  margin: 30px 0;
  width: 588px;
  height: 350px;
  max-width: calc(100vw - 30px);
  object-fit: contain;

  @media (max-width: 620px) {
    height: auto;
  }
`

const TagLine = styled.div`
  font-weight: 700;
  font-size: 23px;
`

const About = styled.div`
  font-weight: 300;
  font-size: 20px;
  margin: 0 0 50px;
`

const ButtonContainer = styled.div`
  margin: -5px 0 20px;
`

const ChurchBiblesFAQ = ({ ...props }) => {

  const location = useLocation()
  const id = location.pathname.split('/')[2]

  const [ churchBibles ] = useAccountSetting(`church-bibles`, [])

  const cssbId = id || (churchBibles.at(-1) || []).id

  const { copyOrShare, willShare, justCopiedOrShared } = useShareOrCopy({
    canShare: !!cssbId,
    shareData: {
      title: i18n("My Church-Specific Study Bible"),
      text: i18n("A study Bible with sermons from my church next to the passages they teach on."),
      url: cssbId && `${document.location.origin}/church/${cssbId}`,
    },
  })

  return (
    <>

      <Header
        {...props}
        showBiblearcLogo
      />

      <Container>
        <Content>

          <SectionHeading>
            {i18n("General Questions")}
          </SectionHeading>

          <Question>
            {i18n("What is a Church-Specific Study Bible (CSSB)?")}
          </Question>

          <Answer>
            {i18n("A study Bible with sermons from your church next to the passages they teach on.")}
          </Answer>

          <Question>
            {i18n("How can a CSSB strengthen my local church?")}
          </Question>

          <Answer>
            {i18n("A CSSB aids your ability to shepherd your church by conveniently placing your sermons before them as they read and study their Bibles, and by making it easy for them to bookmark specific spots within those sermons.")}
          </Answer>

          <Question>
            {i18n("What beliefs must my church hold to use a CSSB?")}
          </Question>

          <Answer>
            {i18nReact("Your church must agree with {{statement_of_faith}}.", {
              statement_of_faith: (
                <NavLinkOrAWithDisable
                  to={`${document.location.origin}/partnership-statement-of-faith`}
                >
                  {i18n("Biblearc’s Partnership Statement of Faith")}
                </NavLinkOrAWithDisable>
              ),
            })}
          </Answer>

          <SectionHeading>
            {i18n("Pricing Questions")}
          </SectionHeading>

          <Question>
            {i18n("How much does it cost my church?")}
          </Question>

          <Answer>
            {i18n("There is a one-time setup cost based on church size.")}
            {` `}
            {i18nReact("See {{here_link}} to view the pricing chart.", {
              here_link: (
                <NavLinkOrAWithDisable to="/about-church-bibles">
                  {i18n("here")}
                </NavLinkOrAWithDisable>
              ),
            })}
          </Answer>

          <Question>
            {i18n("How much does it cost church members?")}
          </Question>

          <Answer>
            {i18n("Nothing!")}
          </Answer>

          <Question>
            {i18n("Will there be any need to pay for app maintenance down the road?")}
          </Question>

          <Answer>
            {i18n("No.")}
            {` `}
            {i18n("Paying the one-time setup fee entitles your church to make use of your study Bible “as-is” so long as your church remains committed to Biblearc’s Partnership Statement of Faith, and so long as this feature is maintained by Biblearc.")}
            {` `}
            {i18n("In the event this feature is discontinued less than one year after your purchase, you will be entitled to a proportionate refund.")}
          </Answer>

          {/* <Question>
            {i18n("Will there be more features added? How much will they cost?")}
          </Question>

          <Answer>
            {i18n("We hope to add further functionality in the future (e.g. the ability for admins to add other content).")}
            {` `}
            {i18n("Some of this functionality will likely be available on a paid subscription basis.")}
            {` `}
            {i18n("However, a paid subscription will not be required of current functionality.")}
          </Answer> */}

          <SectionHeading>
            {i18n("Connecting Sermons")}
          </SectionHeading>

          <Question>
            {i18n("Will sermons automatically sync with my hosting service?")}
          </Question>

          <Answer>
            {i18n("Yes, after a short delay of several minutes.")}
          </Answer>

          <Question>
            {i18n("Why isn't it finding my sermons?")}
          </Question>

          <Answer>
            {i18n("For YouTube, Vimeo, and Apple Podcasts, the full passage reference (e.g. 1 Corinthians 4:1-7) must be present in the title or description.")}
            {` `}
            {i18n("Passage references must use the full Bible book name, not an abbreviation, and must be in English.")}
            {` `}
            {i18n("For SermonAudio and Sermons by Logos, ensure that each specific sermon is associated with the appropriate Bible passage.")}
          </Answer>

          <Question>
            {i18n("Why isn't it finding my YouTube sermons?")}
          </Question>

          <Answer>
            {i18n("For YouTube, passage references must be included in the title or at the very beginning of the description.")}
            {` `}
            {i18n("Passage references occurring after the first 150 characters of the description may not be detected.")}
          </Answer>

          <Question>
            {i18n("What if my church does not use one of the listed hosting services?")}
          </Question>

          <Answer>
            {i18nReact("We recommend you begin using {{link}}. It’s free!", {
              link: (
                <NavLinkOrAWithDisable to="https://sermons.logos.com/">
                  {i18n("Sermons by Logos")}
                </NavLinkOrAWithDisable>
              )
            })}
          </Answer>

          <Question>
            {i18n("Can I connect more than one sermon host?")}
          </Question>

          <Answer>
            {i18n("Not at this time.")}
          </Answer>

          <SectionHeading>
            {i18n("Getting Church Members Set Up")}
          </SectionHeading>

          <Question>
            {i18n("How do people download my church Bible app?")}
          </Question>

          <Answer>
            {i18n("Send them the direct link to your CSSB.")}
          </Answer>

          {!!cssbId &&
            <ButtonContainer>
              <Button
                variant="outlined"
                color="primary"
                onClick={copyOrShare}
                startIcon={justCopiedOrShared ? <CheckIcon /> : (willShare ? <ShareIcon /> : <LinkIcon />)}
              >
                {
                  willShare
                    ? i18n("Share My CSSB Link")
                    : i18n("Copy My CSSB Link")
                }
              </Button>
            </ButtonContainer>
          }

          <MoreInfo>
            {i18n("When opened on a phone, this will prompt them to install the app.")}
            {` `}
            {i18n("When opened on a computer, this will automatically save a link to your CSSB in their main menu.")}
            {` `}
            {i18n("You may also tell people to go to biblearc.com/church and search for your church there.")}
            {` `}
            {i18n("(Just be sure to emphasize that “arc” is spelled with a ‘c’.)")}
          </MoreInfo>

          <Question>
            {i18n("What are the best ways to let my church know?")}
          </Question>

          <Answer>
            <AnswerOL>
              <li>{i18n("Add a button, menu item, or link to your church website")}</li>
              <li>{i18n("Do the same for your church app, if you have one")}</li>
              <li>{i18n("Send out a church mailing with the link above")}</li>
              <li>{i18n("Announce the new app at a worship service")}</li>
              <li>{i18n("Put the app icon and QR code in your bulletin")}</li>
            </AnswerOL>
          </Answer>

          <SectionHeading>
            {i18n("Questions About Features")}
          </SectionHeading>

          <Question>
            {i18n("Can users highlight and take notes?")}
          </Question>

          <Answer>
            {i18n("This feature is coming soon!")}
          </Answer>

          <PromoImage
            className="dark-mode-exempt"
            src="/cssb_phone2_tiny.png"
          />

          <TagLine>
            {i18n("For Those Who Love the Bible + Love Their Church")}
          </TagLine>

          <About>
            {i18n("A study Bible with sermons from your church next to the passages they teach on.")}
          </About>

          <ButtonContainer>
            <LinkButton
              to={id ? `/church/${id}/admin` : `/about-church-bibles`}
              variant="contained"
              color="primary"
              disableElevation
            >
              {
                id
                  ? i18n("Back to Admin Settings")
                  : i18n("Learn How to Create a CSSB")
              }
            </LinkButton>
          </ButtonContainer>

        </Content>

      </Container>

    </>
  )
}


export default memo(ChurchBiblesFAQ)
// i18n-default-category:"study-bible"

import { memo } from 'react'
import { i18n } from 'inline-i18n'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'

import { STUDY_BIBLE_CHANNELS } from '../../utils/constants'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;

  .MuiButton-root {
    max-width: 100%;
  }

  .MuiButton-label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    align-self: flex-start;
    display: block;
  }

`

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  max-width: 100%;
`

const AlreadyFollowingMessage = styled.div`
  color: ${({ theme }) => theme.palette.grey[500]};
  line-height: 1.1;
  margin: -10px 0;

  @media (max-width: 540px) {
    display: none;
  }
`

const StudyBibleFollowAddDialog = ({
  setChannel,
  studyBibleFollows,
}) => {

  return (
    <Container>
      {STUDY_BIBLE_CHANNELS.map(popularChannel => {
        const alreadyFollowing = studyBibleFollows.some(({ channelUrl }) => channelUrl === popularChannel.channelUrl)
        return (
          <ButtonContainer>

            <Button
              key={popularChannel.channelUrl}
              size="small"
              variant="contained"
              disableElevation
              onClick={() => setChannel(popularChannel)}
              disabled={alreadyFollowing}
            >
              {popularChannel.label}
            </Button>

            {alreadyFollowing &&
              <AlreadyFollowingMessage>
                {i18n("Already following")}
              </AlreadyFollowingMessage>
            }

          </ButtonContainer>
        )
      })}
    </Container>
  )
}

export default memo(StudyBibleFollowAddDialog)
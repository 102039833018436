// i18n-default-category:"study-bible"

import { memo, useMemo } from 'react'
import { i18n } from 'inline-i18n'
import styled from 'styled-components'

import ChurchBibleFinder from '../pages/church-bibles/ChurchBibleFinder'

const CSSB = styled.div`
  min-width: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 191px;
  max-height: min(500px, 100dvh - 300px);
`

const StyledChurchBibleFinder = styled(ChurchBibleFinder)`
  max-height: 100%;
  min-height: 0;
  margin: 0;

  .ChurchBibleFinder-ChannelsSearchResults {
    margin: 10px 0;
    min-height: 0;
    flex: 1;
  }

  .ChurchBibleFinder-ResultSection {
    align-self: stretch;
    width: auto;
  }

  .ChurchBibleFinder-Image {
    width: 24px;
    height: 24px;
  }

  .ChurchBibleFinder-ItemText {
    font-size: 16px;
  }

  .ChurchBibleFinder-NameAndLocation {
    margin-left: 34px;
  }

  .ChurchBibleFinder-ChurchLine {
    padding: 10px 15px;
  }

`

const StudyBibleFollowAddCSSB = ({
  setChannel,
}) => {

  const textFieldProps = useMemo(
    () => ({
      label: i18n("Search churches by name or location"),
      InputLabelProps: {
        shrink: true,
      },
      autoFocus: true,
      color: 'secondary',
    }),
    [],
  )
  
  return (
    <CSSB>

      <StyledChurchBibleFinder
        learnToCreateLink
        onSelect={setChannel}
        textFieldProps={textFieldProps}
      />

    </CSSB>
  )
}

export default memo(StudyBibleFollowAddCSSB)
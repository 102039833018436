import { memo, useCallback } from 'react'
import { i18n } from 'inline-i18n'
import styled from 'styled-components'
import FilterHdrIcon from '@material-ui/icons/FilterHdr'
import CloseIcon from '@material-ui/icons/Close'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'

import { getDateAsString, getPrimaryDate, getPrimaryName, preventDefaultEvent } from '../../utils/misc'
import useSimpleToggle from '../../hooks/useSimpleToggle'
import useAppSize from '../../hooks/useAppSize'

import NavLinkOrAWithDisable from '../common/NavLinkOrAWithDisable'

const Container1 = styled.div`
  position: relative;
`

const Slot = styled.div`
  position: absolute;
  right: -10px;
  top: -15px;
  bottom: -15px;
  width: 1px;
  background-color: ${({ theme }) => theme.palette.grey[300]};
  z-index: 2;

  ${({ $isRTL }) => !$isRTL ? `` : `
    right: auto;
    left: -20px;
  `}

`

const Container2 = styled.div`
  margin-bottom: 10px;
  transition: transform .25s ease-in-out;
  z-index: 1;

  ${({ $mini, $miniRevealed, $isRTL }) => (!$mini || ($miniRevealed === $isRTL)) ? `` : `
    transform: translateX(-190px);
  `}

`

const Heading = styled.div`
  padding: ${({ $mini }) => $mini ? `3px 28px 3px 0` : `5px 0`};
  font-style: italic;
  font-size: 13px;
  position: relative;
  z-index: 3;
  transition: opacity .25s ease-in-out;
  opacity: ${({ $hide }) => $hide ? 0 : 1};
  pointer-events: ${({ $hide }) => $hide ? `none` : `all`};
  white-space: ${({ $mini }) => $mini ? `nowrap` : `normal`};
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
  max-width: 100%;
`

const HeadingText = styled.span`
  margin-right: 4px;
`

const Content = styled.div`
  width: 208px;
  transition: background-color .15s ease-in-out;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 7px 10px 10px;
  background-color: ${({ theme }) => theme.palette.grey[200]};
  border-radius: 5px;

  @media (hover: hover) {
    &:hover {
      background-color: ${({ theme }) => theme.palette.grey[300]};
      cursor: pointer;
    }
  }
`

const DotHeading = styled.div`
  font-weight: 300;
  font-style: italic;
`

const DotDate = styled.span`
  font-weight: bold;
  font-style: normal;
`

const DotPlace = styled.div`
  font-size: 10.5px;
  font-weight: 400;
`

const StyledFilterHdrIcon = styled(FilterHdrIcon)`
  font-size: 21px;
  margin: -10px 3px -10px 0;
`

const Date = styled.div`
  white-space: nowrap;
  z-index: 1;
  user-select: none;
  transition: opacity .25s ease-in-out;
  opacity: ${({ $hide }) => $hide ? 0 : 1};
  pointer-events: ${({ $hide }) => $hide ? `none` : `all`};

  ${({ $mini, $isRTL }) => !$mini ? `` : `
    position: absolute;
    top: 0;
    left: -85px;
    width: 85px;
    line-height: 1;
    padding: 8px 0;
    overflow: hidden;
    white-space: nowrap;
    transform: rotate(270deg);
    transform-origin: top right;
    text-align: center;

    ${!$isRTL ? `` : `
      transform: rotate(90deg);
      transform-origin: top left;
      left: auto;
      right: -85px;
    `}
  `}
`

const DateAndIconButton = styled.div`
  align-self: flex-start;
  gap: 9px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: opacity .25s ease-in-out;
  opacity: ${({ $hide }) => $hide ? 0 : 1};
  pointer-events: ${({ $hide }) => $hide ? `none` : `all`};
`

const StyledMapIcon = styled.img`
  width: 14px;
  height: 14px;
  margin: -1px 0 0 0;
`

const DateNumber = styled.span`
  font-weight: 300;
  font-size: 13px;
`

const DateLetters = styled.span`
  font-weight: bold;
  font-size: 10px;
  line-height: .5;
  /* opacity: .6; */
  color: ${({ theme }) => theme.palette.secondary.main}cc;
`

const Dash = styled.span`
  opacity: .7;
`

const Place = styled.div`
  margin: 3px 0 0 0;
  display: flex;
  align-items: center;
  max-width: 188px;

  &:hover .StudyBibleItemEventThumbnail-CityDot {
    border-color: ${({ theme }) => theme.palette.mapColors.selectedPlace};
  }

  &:hover .StudyBibleItemEventThumbnail-CityDot::before,
  &:hover .StudyBibleItemEventThumbnail-SpotDot {
    background-color: ${({ theme }) => theme.palette.mapColors.selectedPlace};
  }

  &:hover .StudyBibleItemEventThumbnail-StyledFilterHdrIcon {
    color: ${({ theme }) => theme.palette.mapColors.selectedPlace};
  }

`

const StyledNavLinkOrAWithDisable = styled(NavLinkOrAWithDisable)`
  transition: opacity .25s ease-in-out;
  opacity: ${({ $hide }) => $hide ? 0 : 1};
  pointer-events: ${({ $hide }) => $hide ? `none` : `all`};
`

const PlaceAbout = styled.div`
  font-size: 12px;
  font-weight: 300;
  transition: opacity .25s ease-in-out;
  opacity: ${({ $hide }) => $hide ? 0 : 1};
  pointer-events: ${({ $hide }) => $hide ? `none` : `all`};
`

const PlaceName = styled.div`
  color: black;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ $type }) => ![ `PROVINCE` ].includes($type) ? `` : `
    font-size: 15px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    font-weight: 300;
  `}

  ${({ $type }) => ![ `KINGDOM` ].includes($type) ? `` : `
    font-size: 16px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    font-weight: 500;
    opacity: .8;
  `}  

  ${({ $type }) => ![ `SEA` ].includes($type) ? `` : `
    font-size: 18px;
    line-height: 1.2;
    font-family: Georgia;
    font-variant: small-caps;
    font-style: italic;
    margin-top: -2px;
  `}

  ${({ $type }) => ![ `DESERT` ].includes($type) ? `` : `
    font-size: 18px;
    font-family: Georgia;
    font-variant: small-caps;
    font-style: italic;
    opacity: .7;
    margin-top: -2px;
  `}

  ${({ $type }) => ![ `AREA` ].includes($type) ? `` : `
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 300;
  `}

  ${({ $type, theme }) => ![ `RIVER` ].includes($type) ? `` : `
    color: ${theme.palette.secondary.dark};
    font-size: 11px;
    letter-spacing: 5px;
    text-transform: uppercase;
    font-weight: bold;
  `}

  ${({ $type, theme }) => ![ `PROVINCE`, `KINGDOM`, `SEA`, `DESERT`, `AREA`, `RIVER` ].includes($type) ? `` : `
    &:hover {
      color: ${theme.palette.mapColors.selectedPlace};
    }
  `}

`

const CityDot = styled.div`
  border-radius: 100px;
  border: 1px solid black;
  margin-right: 4px;
  transition: border-color .15s ease-in-out;
  transform: translateY(-.5px);

  &::before {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    margin: 2px;
    background-color: black;
    border-radius: 100px;
    transition: background-color .15s ease-in-out;
  }
`

const SpotDot = styled.div`
  width: 8px;
  height: 8px;
  background: black;
  border-radius: 100px;
  margin: 0 5px 0 2px;
`

const CloseIconButton = styled(IconButton)`
  position: absolute;
  top: 1px;
  right: 1px;
  z-index: 4;
  padding: 10px;
  transition: opacity .25s ease-in-out;
  opacity: ${({ $hide }) => $hide ? 0 : 1};
  pointer-events: ${({ $hide }) => $hide ? `none` : `all`};

  .MuiSvgIcon-root {
    font-size: 16px;
  }
`

const StudyBibleItemEventThumbnail = ({
  mini,
  isRTL,
  studyBibleItem,
}) => {

  const {
    place,
    fromLoc,
    toLoc,
    ...event  
  } = studyBibleItem

  const heading = getPrimaryName(event)
  const date = getPrimaryDate(event)
  const dateStr = getDateAsString({ date })
  const [ fromDate, toDate ] = date.split(` - `)
  const placeName = getPrimaryName(place)

  const fromDateNumbers = fromDate.replace(/ \[.*$/, ``).replace(/[ADBC ]/g, ``)
  let toDateNumbers = toDate && toDate.replace(/ \[.*$/, ``).replace(/[ADBC ]/g, ``)
  if(toDateNumbers === fromDateNumbers) {
    toDateNumbers = null
  }
  const bcadDiffer = /BC/.test(fromDate) !== /BC/.test(toDate)
  
  const { miniHeaderSize } = useAppSize()
  const [ miniRevealed, toggleMiniRevealed ] = useSimpleToggle()

  const closeMiniRevealed = useCallback(
    event => {
      preventDefaultEvent(event)
      toggleMiniRevealed({ force: false })
    },
    [ toggleMiniRevealed ],
  )

  const open = useCallback(
    event => {
      if(mini && !miniRevealed) {
        preventDefaultEvent(event)
        toggleMiniRevealed()
      }
    },
    [ mini, miniRevealed, toggleMiniRevealed ],
  )

  const dateComponents = (
    <>
      {/AD/.test(fromDate) &&
        <>
          <DateLetters>
            {i18n("AD")}
            {` `}
          </DateLetters>
        </>
      }
      <DateNumber>
        {fromDateNumbers}
      </DateNumber>
      {bcadDiffer && /BC/.test(fromDate) &&
        <>
          <DateLetters>
            {` `}
            {i18n("BC")}
          </DateLetters>
        </>
      }
      {!!toDateNumbers &&
        <>
          <Dash>–</Dash>
          {bcadDiffer && /AD/.test(toDate) &&
            <>
              <DateLetters>
                {i18n("AD")}
                {` `}
              </DateLetters>
            </>
          }
          <DateNumber>
            {toDateNumbers}
          </DateNumber>
          {/BC/.test(toDate) &&
            <>
              <DateLetters>
                {` `}
                {i18n("BC")}
              </DateLetters>
            </>
          }
        </>
      }
    </>
  )

  return (
    <>

      {!mini &&
        <Heading>
          <HeadingText>
            {heading}
          </HeadingText>
        </Heading>
      }

      <Container1>

        <Container2
          onClick={open}
          $miniRevealed={miniRevealed}
          $isRTL={isRTL}
          $mini={mini}
          className={`StudyBibleItemEventThumbnail-Container2-${(mini && !miniRevealed) ? `mini` : `normal`}`}
        >

          <Tooltip
            title={
              (mini && !miniRevealed)
                ? (
                  <>
                    <DotHeading>
                      {heading}
                    </DotHeading>
                    <DotDate>
                      {dateStr}
                    </DotDate>
                    <DotPlace>
                      {placeName}
                    </DotPlace>
                  </>
                )
                : ``
            }
            placement={isRTL ? `right` : `left`}
          >
            <NavLinkOrAWithDisable
              to={`/map?eventId=${encodeURIComponent(event.id)}`}
              disabled={mini && !miniRevealed}
            >
              <Content
                className={`StudyBibleItemEventThumbnail-Thumbnail`}
                onClick={mini ? toggleMiniRevealed : null}
              >

                {mini &&
                  <Heading
                    $mini={mini}
                    $hide={!miniRevealed}
                  >
                    <HeadingText>
                      {heading}
                    </HeadingText>
                  </Heading>
                }

                <DateAndIconButton $hide={mini && !miniRevealed}>

                  <Date>
                    {dateComponents}
                  </Date>

                  <StyledMapIcon
                    src="/map.svg"
                    className="dark-mode-exempt"
                  />

                </DateAndIconButton>

                <Tooltip
                  title={i18n("View location in map")}
                >
                  <StyledNavLinkOrAWithDisable
                    to={`/map?placeId=${encodeURIComponent(place.id)}`}
                    $hide={mini && !miniRevealed}
                  >
                    <Place>
                      {place.type === `CITY` && <CityDot className="StudyBibleItemEventThumbnail-CityDot" />}
                      {[ `SPOT`, `ROAD`, `HOUSE`, `GARDEN` ].includes(place.type) && <SpotDot className="StudyBibleItemEventThumbnail-SpotDot" />}
                      {place.type === `MOUNTAIN` && <StyledFilterHdrIcon className="StudyBibleItemEventThumbnail-StyledFilterHdrIcon" />}
                      <PlaceName $type={place.type}>
                        {placeName}
                      </PlaceName>
                    </Place>
                  </StyledNavLinkOrAWithDisable>
                </Tooltip>

                {!!place.about && !mini &&
                  <PlaceAbout $hide={mini && !miniRevealed}>
                    {place.about}
                  </PlaceAbout>
                }

                {mini &&
                  <Date
                    $mini={mini}
                    $hide={miniRevealed}
                    $isRTL={isRTL}
                  >
                    {dateComponents}
                  </Date>
                }

                {mini &&
                  <CloseIconButton
                    onClick={closeMiniRevealed}
                    className={`StudyBibleItemEventThumbnail-CloseIconButton-${miniRevealed ? `visible` : `hidden`}`}
                    $hide={!miniRevealed}
                  >
                    <CloseIcon />
                  </CloseIconButton>
                }

              </Content>
            </NavLinkOrAWithDisable>
          </Tooltip>

        </Container2>

        {mini && !miniHeaderSize && <Slot $isRTL={isRTL} />}

      </Container1>

    </>
  )
}

export default memo(StudyBibleItemEventThumbnail)
import { memo, useCallback, useContext, useState } from 'react'
import { useLocation, useHistory } from "react-router-dom"
import styled from 'styled-components'
import MenuIcon from '@material-ui/icons/Menu'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
// import CardGiftcardIcon from '@material-ui/icons/CardGiftcard'
import SearchIcon from '@material-ui/icons/Search'
import AssignmentIcon from '@material-ui/icons/Assignment'
import Tooltip from '@material-ui/core/Tooltip'
import { i18n } from 'inline-i18n'
import { useMeasure } from 'react-use'

import { IS_EMBED } from '../../utils/constants'
import useUniversalShortcuts from '../../hooks/useUniversalShortcuts'
import useAssignmentBlock from '../../hooks/useAssignmentBlock'
import useSetTimeout from '../../hooks/useSetTimeout'
import useEffectAsync from '../../hooks/useEffectAsync'
import useAppSize from '../../hooks/useAppSize'
import { getModifierChar } from '../../utils/misc'
// import { LoggedInUserContext } from '../../context/LoggedInUser'

import LinkIconButton from './LinkIconButton'
import TextAndShortcutCombo from './TextAndShortcutCombo'
import SearchModal from '../search/SearchModal'
// import LinkButton from './LinkButton'

import SecondaryHeaderItems from './SecondaryHeaderItems'
import { LoggedInUserContext } from '../../context/LoggedInUser'

const Container = styled.div`
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.palette.divider};
  padding: 5px;
  background: white;
  max-width: 100vw;
  user-select: none;

  @media print {
    padding: 0 0 5px 0;
  }
`

const PageTitle = styled.div`
  line-height: 48px;
  font-size: 18px;
  user-select: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const StyledLinkIconButton = styled(LinkIconButton)`
  transition: transform .3s ease-in-out;
  position: relative;

  ${({ $sliderSectionOpen }) => !$sliderSectionOpen ? `` : `
    transform: rotate(180deg);
  `}

  @media print {
    display: none;
  }
`

const StyledAssignmentIcon = styled(AssignmentIcon)`
  color: black;
`

const ChildrenSection = styled.div`
  flex: 1;
  min-width: 0;
`

const CenteredSection = styled.div`
  flex: 1;
  min-width: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
`

const SliderSection = styled.div`
  display: flex;
`

const SliderSectionIcons = styled.div`
  display: flex;
`

const Logo = styled.img`
  align-self: center;
  height: 30px;
  margin: -1px 10px 0;

  @media (max-width: 1200px) {
    display: none;
  }

  @media print {
    align-self: flex-end;
    margin: 1px -5px 0 10px;
    display: inline-block;
  }
`

const StudyBibleLogo = styled(Logo)`
  height: 35px;
`

const OpenCloseSliderContainer = styled.div`
  transition: min-width .3s ease-in-out;
  min-width: 0;

  ${({ $sliderSectionOpen, $sliderSectionIconsWidth }) => !$sliderSectionOpen ? `` : `
    min-width: ${438 - $sliderSectionIconsWidth}px;
  `}
`

// const StyledLinkButton = styled(LinkButton)`
//   margin: 5px 10px;
// `

const Header = ({
  toggleDrawerOpen,
  displaySettingsAnchorEl,
  openDisplaySettings,
  closeDisplaySettings,
  alertsAnchorEl,
  openAlerts,
  closeAlerts,
  showSectionOpen,
  sliderSectionOpen,
  toggleSliderSectionOpen,
  showBibleDisplaySettings,
  hideAlerts,
  showBiblearcLogo,
  showStudyBibleLogo,
  accountMenuButtonReplacement,
  leftExtraComponent=null,
  centerExtraComponent=null,
  rightExtraComponent=null,
  projectId,
  title,  // This is taken care of in Routes.js for most pages
  titleNoBiblearc,
  children,
  className,
}) => {

  // const isLoggedIn = useIsLoggedIn()
  const { hasMyPlan } = useContext(LoggedInUserContext) || {}

  const location = useLocation()
  const history = useHistory()

  const { miniHeaderSize } = useAppSize()
  const [ sliderSectionIconsRef, { width: sliderSectionIconsWidth } ] = useMeasure()
  const { assignmentBlock, heading: assignmentHeading } = useAssignmentBlock()
  const [ tooltipOpen, setTooltipOpen ] = useState(!!assignmentBlock)

  // currentSearch to be used for situations like when the user clicks to open
  // up a passage from search results. This way, when they click the search
  // icon again, it brings them immediately to the last search results.
  const initSearchText = decodeURIComponent((location.hash.match(/^#search=(.*)$/) || [])[1] || "")
  const [ currentSearch, setCurrentSearch ] = useState(initSearchText)
  const [ searchBarOpen, setSearchBarOpen ] = useState(/^#search(?:=|$)/.test(location.hash))

  const onClick = useCallback(() => window.getSelection().empty(), [])

  const openSearchBar = useCallback(
    () => {
      if(currentSearch) {
        history.replace(`#search=${currentSearch}`)
      }
      setSearchBarOpen(true)
    },
    [ currentSearch, history ],
  )

  useUniversalShortcuts({
    setSearchBarOpen,
  })

  useEffectAsync(
    () => {
      if(title || titleNoBiblearc) {
        document.title = titleNoBiblearc || `${title} | Biblearc`
      }
    },
    [ title, titleNoBiblearc ],
  )

  const [ setTooltipTimeout ] = useSetTimeout()
  useEffectAsync(
    () => {
      setTooltipTimeout(() => setTooltipOpen(), 2000)
    },
    [],
  )

  return (
    <Container
      onClick={onClick}
      className={`Header-Container ${className}`}
    >

      {!IS_EMBED &&
        <Tooltip
          key={tooltipOpen ? `forceOpen` : `onHover`}
          title={assignmentBlock ? (assignmentHeading || i18n("Assignment Instructions", "", "equip")) : ``}
          arrow
          {...(tooltipOpen ? { open: true } : {})}
        >
          <span>
            <StyledLinkIconButton
              className="Header-MenuIconButton"
              onClick={toggleDrawerOpen}
            >
              {!assignmentBlock && <MenuIcon />}
              {!!assignmentBlock && <StyledAssignmentIcon />}
            </StyledLinkIconButton>
          </span>
        </Tooltip>
      }

      {leftExtraComponent}

      {!centerExtraComponent &&
        <ChildrenSection>
          {(!children || typeof children === 'string')
            ? (
              <PageTitle>
                {children || title || titleNoBiblearc}
              </PageTitle>
            )
            : children
          }
        </ChildrenSection>
      }

      {!!centerExtraComponent &&
        <CenteredSection>
          {centerExtraComponent}
        </CenteredSection>
      }

      {!miniHeaderSize && !!showBiblearcLogo && <Logo src="/logo.svg" />}
      {!miniHeaderSize && !!showStudyBibleLogo && !hasMyPlan && <StudyBibleLogo src="/the_biblearc_study_bible_1.svg" />}
      {!miniHeaderSize && !!showStudyBibleLogo && hasMyPlan && <StudyBibleLogo src="/my_biblearc_study_bible_1.svg" />}

      <SliderSection>

        {!!showSectionOpen && !!sliderSectionIconsWidth &&
          <OpenCloseSliderContainer
            $sliderSectionIconsWidth={sliderSectionIconsWidth}
            $sliderSectionOpen={sliderSectionOpen}
          >
            <StyledLinkIconButton
              $sliderSectionOpen={sliderSectionOpen}
              onClick={toggleSliderSectionOpen}
            >
              <NavigateBeforeIcon />
            </StyledLinkIconButton>
          </OpenCloseSliderContainer>
        }

        <SliderSectionIcons ref={sliderSectionIconsRef}>

          {rightExtraComponent}

          <Tooltip
            title={
              <TextAndShortcutCombo
                text={i18n("Search")}
                shortcutCombo={`${getModifierChar()}/`}
              />
            }
          >
            <span>
              <StyledLinkIconButton
                onClick={openSearchBar}
                className="Header-SearcIconButton"
              >
                <SearchIcon />
              </StyledLinkIconButton>
            </span>
          </Tooltip>

          {!miniHeaderSize &&
            <SecondaryHeaderItems
              showBibleDisplaySettings={showBibleDisplaySettings}
              hideAlerts={hideAlerts}
              accountMenuButtonReplacement={accountMenuButtonReplacement}
              displaySettingsAnchorEl={displaySettingsAnchorEl}  
              openDisplaySettings={openDisplaySettings}
              closeDisplaySettings={closeDisplaySettings}
              alertsAnchorEl={alertsAnchorEl}
              openAlerts={openAlerts}
              closeAlerts={closeAlerts}
            />
          }

        </SliderSectionIcons>

      </SliderSection>

      <SearchModal
        setCurrentSearch={setCurrentSearch}
        searchBarOpen={searchBarOpen}
        setSearchBarOpen={setSearchBarOpen}
        projectId={projectId}
        openDisplaySettings={openDisplaySettings}
        openAlerts={openAlerts}
      />

    </Container>
  )
}

export default memo(Header)
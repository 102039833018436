import styled from 'styled-components'
import { i18n } from 'inline-i18n'

const Lex = styled.span`
  display: inline-block;
  direction: rtl;
`

export const getHebrewSuffixGloss = ({ morphPart, includeGender }) => ({
  Sd: i18n("toward", "", "original-languages"),
  Sh: i18n("[stylistic addition]", "", "original-languages"),
  Sn: i18n("[stylistic addition]", "", "original-languages"),
  Sp1cs: i18n("me, my", "", "original-languages"),
  Sp1cp: i18n("us, our", "", "original-languages"),
  Sp2ms: includeGender ? i18n("you, your [to a man]", "", "original-languages") : i18n("you, your", "", "original-languages"),
  Sp2mp: includeGender ? i18n("y’all, your", "", "original-languages") : i18n("y’all, your", "", "original-languages"),
  Sp2fs: includeGender ? i18n("you, your [to a woman]", "", "original-languages") : i18n("you, your", "", "original-languages"),
  Sp2fp: includeGender ? i18n("y’all, your [to women]", "", "original-languages") : i18n("y’all, your", "", "original-languages"),
  Sp3ms: i18n("him, his", "", "original-languages"),
  Sp3mp: i18n("them, their", "", "original-languages"),
  Sp3fs: i18n("her", "", "original-languages"),
  Sp3fp: includeGender ? i18n("them, their [women]", "", "original-languages") : i18n("them, their", "", "original-languages"),
}[morphPart] || i18n("[suffix]", "", "original-languages"))

export const getHebrewSuffixNakedStrongs = morphPart => (
  {
    Sd: `h->`,
    Sh: `h^`,
    Sn: `n^`,
  }[morphPart || ``]
  || (
    /^Sp[1-3][cmf][sp]$/.test(morphPart || ``)
      ? `suffix:${morphPart.slice(-3)}`
      : ``
  )
)

export const getHebrewPrefixDefinition = uhbStrongs => ({

  b: {
    lex: <Lex>בְּ–</Lex>,
    lexUnique: true,
    vocal: `bᵉʼ`,
    hits: 15778,
    gloss: i18n("in", "", "original-languages"),
    pos: [`R`],
    syn: ``,
    rel: ``,
    lxx: ``,
    nakedStrongs: `b`,
  },

  l: {
    lex: <Lex>לְ–</Lex>,
    lexUnique: true,
    vocal: `lᵉʼ`,
    hits: 20778,
    gloss: i18n("to, for, of", "", "original-languages"),
    pos: [`R`],
    syn: ``,
    rel: ``,
    lxx: ``,
    nakedStrongs: `l`,
  },

  k: {
    lex: <Lex>כִּ–</Lex>,
    lexUnique: true,
    vocal: `kiʼ`,
    hits: 2960,
    gloss: i18n("like, as", "", "original-languages"),
    pos: [`R`],
    syn: ``,
    rel: ``,
    lxx: ``,
    nakedStrongs: `k`,
  },

  m: {
    lex: <Lex>מֵ–</Lex>,
    lexUnique: true,
    vocal: `mêʼ`,
    hits: 6391,
    gloss: i18n("from", "", "original-languages"),
    pos: [`R`],
    syn: ``,
    rel: ``,
    lxx: ``,
    nakedStrongs: `m`,
  },

  c: {
    lex: <Lex>וְ–</Lex>,
    lexUnique: true,
    vocal: `vᵉʼ`,
    hits: 50994,
    gloss: i18n("and, then, but", "", "original-languages"),
    pos: [`C`],
    syn: ``,
    rel: ``,
    lxx: ``,
    nakedStrongs: `v`,
  },

  s: {
    lex: <Lex>שֶׁ–</Lex>,
    lexUnique: true,
    vocal: `sheʼ`,
    hits: 139,
    gloss: i18n("who, which, that", "", "original-languages"),
    pos: [`P`],
    syn: ``,
    rel: ``,
    lxx: ``,
    nakedStrongs: `sh`,
  },

  d: {
    lex: <Lex>הַ–</Lex>,
    lexUnique: true,
    vocal: `haʼ`,
    hits: 31210,
    gloss: i18n("the", "", "original-languages"),
    pos: [`Td`],
    syn: ``,
    rel: ``,
    lxx: ``,
    nakedStrongs: `h`,
  },

  i: {
    lex: <Lex>הֲ–</Lex>,
    lexUnique: true,
    vocal: `haʼ`,
    hits: 661,
    gloss: i18n("[introduces question]", "", "original-languages"),
    pos: [`Ti`],
    syn: ``,
    rel: ``,
    lxx: ``,
    nakedStrongs: `h?`,
  },

}[uhbStrongs])
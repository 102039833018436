import { memo, useMemo } from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import styled from 'styled-components'
import { Virtuoso } from 'react-virtuoso'

import { capitalize } from '../../../utils/misc'
import useInstanceValuesCallback from '../../../hooks/useInstanceValuesCallback'
import useStickyRefState from '../../../hooks/useStickyRefState'

import TranslateListItem from './TranslateListItem'

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const Header = styled.div`
  flex: 1;
  display: flex;
  gap: 10px;
  margin-top: 20px;
  max-width: calc(100vw - 30px);
`

const SearchTextField = styled(TextField)`
  flex: 1;
  min-width: 100px;
`

const HeaderSpacer = styled.div`
  height: 10px;
`

const FooterSpacer = styled.div`
  height: calc(100vh - 200px);
`

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
`

const TranslateList = ({
  // languageId,
  hash,
  setHash,
  i18nEnglishStrings,
  i18nTranslationStringsByHash,
  condensed,
  submitForReview,
  instructionsButton,
}) => {

  const [ searchStr, setSearchStr ] = useStickyRefState({ id: `TranslateList:searchStr`, defaultValue: `` })
  const [ category, setCategory ] = useStickyRefState({ id: `TranslateList:category`, defaultValue: `all` })
  const [ type, setType ] = useStickyRefState({ id: `TranslateList:type`, defaultValue: `all` })

  const categories = useMemo(() => [ ...new Set((i18nEnglishStrings || []).map(({ category }) => category)) ].sort(), [ i18nEnglishStrings ])

  const goSetSearchStr = useInstanceValuesCallback(({ target }) => setSearchStr(target.value))
  const goSetType = useInstanceValuesCallback(({ target }) => setType(target.value))
  const goSetCategory = useInstanceValuesCallback(({ target }) => setCategory(target.value))

  const filteredI18nTranslationStrings = useMemo(
    () => {

      const getPieces = str => str.toLowerCase().match(/(?:\p{L}+|[0-9]+)+/gu) || []
      const searchStrPieces = getPieces(searchStr)
      
      return (
        (i18nEnglishStrings || [])
          .filter(i18nEnglishString => (
            [ `all`, i18nEnglishString.category ].includes(category)
            && (
              type === `all`
              || (type === `translated` && !!i18nTranslationStringsByHash[i18nEnglishString.hash])
              || (type !== `translated` && !i18nTranslationStringsByHash[i18nEnglishString.hash])
            )
            && searchStrPieces.every(piece => getPieces(`${i18nEnglishString.engText} ${i18nEnglishString.desc || ``}`).some(word => word.indexOf(piece) === 0))
          ))
          .sort((a,b) => a.engText.toLowerCase() < b.engText.toLowerCase() ? -1 : 1)
          .map(i18nEnglishString => ({
            ...i18nEnglishString,
            i18nEnglishStringInfo: i18nEnglishString.info,
            ...i18nTranslationStringsByHash[i18nEnglishString.hash],
          }))
      )
    },
    [ i18nEnglishStrings, i18nTranslationStringsByHash, searchStr, category, type ],
  )
  

  return (
    <Container>

      <Header>

        <SearchTextField
          variant="outlined"
          value={searchStr}
          onChange={goSetSearchStr}
          size="small"
          label="Search"
          InputLabelProps={{
            shrink: true,
          }}
        />

        <TextField
          variant="outlined"
          value={category}
          onChange={goSetCategory}
          select
          size="small"
          label="Category"
          InputLabelProps={{
            shrink: true,
          }}
        >
          <MenuItem value="all">
            <b>All categories</b>
          </MenuItem>
          {categories.map(category => (
            <MenuItem
              key={category}
              value={category}
            >
              {capitalize(category)}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          variant="outlined"
          value={type}
          onChange={goSetType}
          select
          size="small"
          label="Status"
          InputLabelProps={{
            shrink: true,
          }}
        >
          <MenuItem value="all">
            <b>All statuses</b>
          </MenuItem>
          <MenuItem value="not-translated">
            Not yet translated
          </MenuItem>
          <MenuItem value="translated">
            Translated
          </MenuItem>
        </TextField>

      </Header>

      <Virtuoso
        totalCount={filteredI18nTranslationStrings.length}
        itemContent={index => (
          <TranslateListItem
            key={filteredI18nTranslationStrings[index].hash}
            {...filteredI18nTranslationStrings[index]}
            setHash={setHash}
            isSelected={filteredI18nTranslationStrings[index].hash === hash}
          />
        )}
        components={{
          Header: () => <HeaderSpacer />,
          Footer: () => (
            condensed
              ? (
                <Footer>
                  {instructionsButton}
                  {submitForReview}
                  <FooterSpacer />
                </Footer>
              )
              : <FooterSpacer />
          ),
        }}
        tabIndex={-1}
      />

    </Container>
  )
}


export default memo(TranslateList)
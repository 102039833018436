// i18n-default-category:"study-bible"

import { memo, useState, useCallback } from 'react'
import { i18n } from 'inline-i18n'
import styled from 'styled-components'
import InputAdornment from '@material-ui/core/InputAdornment'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn'
import { useApolloClient } from '@apollo/client'

import useRefState from '../../hooks/useRefState'
import useMutationContext from '../../hooks/useMutationContext'
import { defaultChannelUrlImmediateTransform, defaultChannelUrlTransform } from '../pages/create-a-church-bible/ChannelFinder'

import FadedLoading from '../common/FadedLoading'

import streamingServiceChannelQuery from '../../graphql/queries/streamingServiceChannel'

export const LOGOS_PROFILE_URL_REGEX = /^https:\/\/sermons\.logos\.com\/profile\/[^/]+$/
export const LOGOS_PROFILE_URL_EXAMPLE = `https://sermons.logos.com/profile/mc-xt`

const Logos = styled.div`
  min-width: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
`

const StyledTextField = styled(TextField)`
  margin-top: 15px;

  .MuiInputAdornment-positionStart {
    margin-right: 2px;
  }

  .MuiInputBase-root {
    padding-right: 5px;
  }
`

const Error = styled.div`
  color: ${({ theme }) => theme.palette.tertiary.main};
  text-align: center;
  padding: 30px;
`

const Example = styled.div`
  color: black;
  text-align: center;
  margin-top: 10px;
  font-weight: 300;
  word-wrap: break-word;
`

const StudyBibleFollowAddLogos = ({
  setChannel,
}) => {

  const [ url, setUrl, getUrl ] = useRefState(``)
  const [ finding, setFinding ] = useState(false)
  const [ error, setError ] = useState()

  const client = useApolloClient()
  const context = useMutationContext()

  const onChangeFind = useCallback(
    ({ target }) => {
      setUrl(
        defaultChannelUrlImmediateTransform(
          target.value
        )
      )
    },
    [ setUrl ],
  )

  const goFind = useCallback(
    async () => {

      setError()

      const id = defaultChannelUrlTransform(getUrl())

      if(!LOGOS_PROFILE_URL_REGEX.test(id)) {
        setError(
          i18n("Invalid {{invalid_item}}", {
            invalid_item: i18n("Sermons by Logos Profile URL"),
          })
        )
        return
      }

      setFinding(true)

      try {

        const { data: { streamingServiceChannel } } = await client.query({
          query: streamingServiceChannelQuery,
          variables: {
            id,
          },
          context,
        })

        if(streamingServiceChannel) {
          setChannel(streamingServiceChannel)
        } else {
          setError(i18n("Not found"))
        }

      } catch(err) {
        console.error(err)
        setError(`Unknown error`)
      }

      setFinding(false)

    },
    [ getUrl, client, context, setChannel ],
  )

  const onKeyDownFind = useCallback(
    async event => {

      if(event.key === `Enter`) {
        goFind()
      }

    },
    [ goFind ],
  )

  return (
    <Logos>

      <StyledTextField
        label={i18n("Sermons by Logos Profile URL")}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              {`https://`}
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={goFind}
              >
                <KeyboardReturnIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        fullWidth
        variant="outlined"
        value={url}
        onChange={onChangeFind}
        onKeyDown={onKeyDownFind}
        autoFocus
        color="secondary"
      />

      {!!error &&
        <Error>
          {error}
          <Example>
            {i18n("Valid example: {{example}}", {
              example: LOGOS_PROFILE_URL_EXAMPLE,
            })}
          </Example>
        </Error>
      }

      {finding && <FadedLoading />}

    </Logos>
  )
}

export default memo(StudyBibleFollowAddLogos)
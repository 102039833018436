import { useEffect, useState } from "react"
import { i18n } from 'inline-i18n'
import styled from 'styled-components'

import { textToJsx } from "../utils/misc"

import LinkButton from "../components/common/LinkButton"

const assignmentRegex = /^#assignment=(.*)$/
const assignmentHash = decodeURIComponent((document.location.hash.match(assignmentRegex) || [])[1] || "")
let assignment
try {
  const assignmentObj = JSON.parse(assignmentHash)
  if(/^https:\/\/equip\.biblearc\.com(?:\/|$)/.test(assignmentObj.buttonLink || ``)) {
    if(process.env.REACT_APP_STAGE !== 'development') {
      document.location.hash = document.location.hash.replace(assignmentRegex, '')
    }
    assignment = assignmentObj
  }
} catch(err) {}
let setAssignmentBlockFuncs = []
// const callSetAssignmentBlockFuncs = () => {
//   setAssignmentBlockFuncs.forEach(func => func())
//   assignment = undefined
// }

const Container = styled.div`
  padding: 16px;
  background: ${({ theme }) => theme.palette.primary.veryLight}88;
`

const Heading = styled.div`
  font-weight: bold;
  font-size: 17px;
  margin-bottom: 15px;
`

const Info = styled.div`
  p {
    margin: 10px 0;
    line-height: 1.3;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 20px;
`

const useAssignmentBlock = () => {

  const {
    heading,
    instructions,
    buttonLabel,
    buttonLink,
    buttonProps={},
    // closeButtonProps={},
  } = assignment || {}

  const [ assignmentBlock, setAssignmentBlock ] = useState(() => (
    instructions
      ? (
        <Container>
          <Heading>
            {heading || i18n("Assignment Instructions", "", "equip")}
          </Heading>
          <Info>
            {textToJsx(instructions)}
          </Info>
          <ButtonContainer>
            <LinkButton
              href={buttonLink}
              variant="contained"
              color="primary"
              size="small"
              disableElevation
              fullWidth
              navInSameTab
              {...buttonProps}
            >
              {buttonLabel || i18n("Back to Biblearc EQUIP", "", "equip")}
            </LinkButton>
            {/* <Button
              size="small"
              disableElevation
              fullWidth
              onClick={callSetAssignmentBlockFuncs}
              {...closeButtonProps}
            >
              {i18 n("Close These Instructions")}
            </Button> */}
          </ButtonContainer>
        </Container>
      )
      : null
  ))

  useEffect(
    () => {
      setAssignmentBlockFuncs.push(setAssignmentBlock)
      return () => {
        setAssignmentBlockFuncs = setAssignmentBlockFuncs.filter(func => func !== setAssignmentBlock)
      }
    },
    []
  )

  return {
    ...(assignment || {}),
    assignmentBlock,
  }

}

export default useAssignmentBlock

// Test with...
// {
//   instructions: "This <strong>is</strong> what you <b>need</b> <a href=\"https://google.com\">to do</a>.\nAnd here are other thoughts, thoughts that <span>go</span> on for awhile so that we have <i>multiple</i> lines.",
//   buttonLink: "https://equip.biblearc.com/"
// }
// http://localhost:3000/#assignment=%7B%22instructions%22%3A%22This%20%3Cstrong%3Eis%3C%2Fstrong%3E%20what%20you%20%3Cb%3Eneed%3C%2Fb%3E%20%3Ca%20href%3D%5C%22https%3A%2F%2Fgoogle.com%5C%22%3Eto%20do%3C%2Fa%3E.%5CnAnd%20here%20are%20other%20thoughts%2C%20thoughts%20that%20%3Cspan%3Ego%3C%2Fspan%3E%20on%20for%20awhile%20so%20that%20we%20have%20%3Ci%3Emultiple%3C%2Fi%3E%20lines.%22%2C%22buttonLink%22%3A%22https%3A%2F%2Fequip.biblearc.com%2F%22%7D
import React, { memo, useMemo, useContext } from 'react'
import { i18n } from 'inline-i18n'
import { i18nReact } from 'inline-i18n/build/i18nReact'
import styled from 'styled-components'
import { blockUsfmMarkers, tagInList, adjustPiecesForSpecialHebrew, adjustTextForSups, getTextLanguageId } from "@bibletags/bibletags-ui-helper"
import { getLocFromRef } from '@bibletags/bibletags-versification'
import Tooltip from '@material-ui/core/Tooltip'
import { removeCantillation } from '@bibletags/bibletags-ui-helper'

import { IS_EMBED } from '../../utils/constants'
import styledBibleTextComponentsByTag, { isHitStyling } from '../../utils/styledBibleTextComponentsByTag'
import useEqualObjsMemo from '../../hooks/useEqualObjsMemo'
import useVersionInfo from '../../hooks/useVersionInfo'
import { PassageShowNotesContext, PassageShowCfsContext, PassageShowCantillationContext,
         PassageShowGreekAccentsContext, PassageShowHebrewVowelsContext, PassageGreekPunctuationContext } from '../../context/LocalInfo'
import useInstanceValue from '../../hooks/useInstanceValue'
import useEmbedSettings from '../../hooks/useEmbedSettings'
import { normalizeVersionId } from '../../utils/misc'

import Loading from './Loading'
import OriginalLanguageWordPopperContent from '../passage/OriginalLanguageWordPopperContent'
import FootnotePopperContent from '../passage/FootnotePopperContent'
import ApparatusPopperContent from '../passage/ApparatusPopperContent'
import VerseNumberPopperContent from '../passage/VerseNumberPopperContent'
import ViewChapterIconButton from '../passage/ViewChapterIconButton'

const BasicDiv = styled.div``

const BasicSpan = styled.span`
  ${isHitStyling}
`

const CrossRefDot = styled.span`
  margin: 0 5px 0 2px;
  color: ${({ theme }) => theme.palette.grey[400]};
`

const TextContent = ({
  pieces: piecesProp,  // required
  versionId,  // required
  bookId,  // required
  startChapter,  // required
  startVerse,  // optional; needed to add data-word-loc if it is not a full verse
  classNamePrefix=``,
  wordClassName=`text-content-word`,
  showChapterNumbers=false,
  loadingSize,
  goSetPopperInfo,
  showCfs,
  showNotes,
  showCantillation,
  showHebrewVowels,
  showGreekAccents,
  greekPunctuation,
  showExpand,
  closeAndClearSearch,
  ignoreClick,  // if this doesn't work for every similar need, then change this into passing in a function to check if it should ignore
  locsToHighlight,
  disabledVsNumClick,
  wrapNonSpaceTextInSpan,
}) => {

  versionId = normalizeVersionId(versionId)

  const passageShowNotes = useContext(PassageShowNotesContext)
  const passageShowCfs = useContext(PassageShowCfsContext)
  const passageShowCantillation = useContext(PassageShowCantillationContext)
  const passageShowHebrewVowels = useContext(PassageShowHebrewVowelsContext)
  const passageShowGreekAccents = useContext(PassageShowGreekAccentsContext)
  const passageGreekPunctuation = useContext(PassageGreekPunctuationContext)

  showCfs = typeof showCfs === 'boolean' ? showCfs : passageShowCfs
  showNotes = typeof showNotes === 'boolean' ? showNotes : passageShowNotes
  showCantillation = typeof showCantillation === 'boolean' ? showCantillation : passageShowCantillation
  showHebrewVowels = typeof showHebrewVowels === 'boolean' ? showHebrewVowels : passageShowHebrewVowels
  showGreekAccents = typeof showGreekAccents === 'boolean' ? showGreekAccents : passageShowGreekAccents
  greekPunctuation = typeof greekPunctuation === 'string' ? greekPunctuation : passageGreekPunctuation

  const { version } = useVersionInfo(versionId)

  const { type, languageId: preadjustedLanguageId } = version || {}
  const languageId = getTextLanguageId({ languageId: preadjustedLanguageId, bookId })
  const isOriginalOrLxx = [ `ORIGINAL`, `LXX` ].includes(type)

  const pieces = useEqualObjsMemo(piecesProp)
  locsToHighlight = useEqualObjsMemo(locsToHighlight)

  const getIgnoreClick = useInstanceValue(ignoreClick)

  const { disableOriginalLanguageHelps } = useEmbedSettings()
  const disabledWordAndVsNumClick = !goSetPopperInfo || (IS_EMBED && disableOriginalLanguageHelps)

  const passageJsx = useMemo(
    () => {
      if(!pieces) return null

      let modifiedStartVerse = startVerse
      let ch = startChapter || null
      let vs = modifiedStartVerse == null ? null : modifiedStartVerse  // may be 0
      let dotNum = 1
      let textAlreadyDisplayedInThisView = false

      const getJSXFromPieces = ({ pieces, parentWordId }) => {

        pieces = adjustPiecesForSpecialHebrew({ isOriginal: isOriginalOrLxx, languageId, pieces })

        const simplifiedPieces = []
        pieces.forEach(piece => {
          const { tag, text, type, color, children } = piece
          const previousPiece = simplifiedPieces.slice(-1)[0]

          if(
            (tag === 'x' && !showCfs)
            || (tag === 'f' && !showNotes)
            || (tag === 'zApparatusJson' && !showNotes)
          ) return

          if(
            previousPiece
            && !tag
            && !previousPiece.tag
            && text
            && previousPiece.text
            && type !== 'word'
            && previousPiece.type !== 'word'
            && !color
            && !previousPiece.color
          ) {
            previousPiece.text += text
          } else if(children && Object.values(piece).length === 1) {
            // flattening it out like this is needed to have adjustTextForSups work
            simplifiedPieces.push(...children.map(child => ({ ...child })))
          } else if(tag === 'd') {
            if(!piece.text && !piece.children) {  // inline only
              simplifiedPieces.push({ tag: `v`, content: `T`, verse: 0 })
            } else {
              simplifiedPieces.push({
                tag: `d`,
                children: [
                  { tag: `v`, content: `T`, verse: 0 },
                  { ...piece, tag: null },
                ],
              })
            }
          } else {
            simplifiedPieces.push({ ...piece })
          }
        })

        return simplifiedPieces.map((piece, idx) => {
          let { tag, text, content, color, children, chapter, verse, type, apparatusJson, baseWords, wordNumberInVerse, attrib='', otherAttrs={}, isHit } = piece
          tag = tag && tag.replace(/^\+/, '')
          tag = (!tag && type === `word` && `w`) || tag

          if([ "b" ].includes(tag)) {
            text = ' '
          }

          if([ "mt" ].includes(tag) && /\\(?:fe?|x) /.test(content)) {
            const footnoteRegex = /^\\(fe?|x) (.*?)\\((?:fe?|x)\*)$/
            children = content
              .split(/(\\(?:fe?|x) .*?\\(?:fe?|x)\*)/g)
              .map(text => (
                footnoteRegex.test(text)
                  ? {
                    content: text.replace(footnoteRegex, '$2'),
                    tag: text.replace(footnoteRegex, '$1'),
                    endTag: text.replace(footnoteRegex, '$3'),
                  }
                  : {
                    text,
                  }
              ))
          }

          const chVsBefore = `${ch} ${vs}`
          ch = chapter != null ? chapter : (tag === 'c' ? parseInt(content) : ch)
          vs = verse != null ? verse : (tag === 'v' ? parseInt(content) : (tag === 'd' ? 0 : vs))
          if(`${ch} ${vs}` !== chVsBefore) {
            dotNum = 1
          }

          if(modifiedStartVerse == null) {
            modifiedStartVerse = vs
          }

          const wrapInView = tagInList({ tag, list: blockUsfmMarkers })

          if(!children && !text && !content && !apparatusJson && !wrapInView) return null
          if([ "c", "cp" ].includes(tag)) return null

          text = adjustTextForSups({ tag, text, pieces: simplifiedPieces, idx })

          if(wrapInView) {
            textAlreadyDisplayedInThisView = false
          }

          if([ "v", "vp" ].includes(tag) && content) {
            const nextPiece = simplifiedPieces[idx+1] || {}
            if(tag === "v" && nextPiece.tag === "vp") return null
            const adjustedVsContent = (
              showChapterNumbers
                ? (
                  i18n("{{chapter}}:{{verse}}", {
                    chapter: ch,
                    verse: content,
                  })
                )
                : content
            )
            content = `${adjustedVsContent}\u00A0`
            otherAttrs[`data-vsnum-id`] = `${versionId}_${getLocFromRef({ bookId, chapter: ch, verse: vs })}`
          }
          const spaceBeforeVsNum = [ "v", "vp" ].includes(tag) && content && textAlreadyDisplayedInThisView ? ` ` : null

          const StyledBibleTextComponent = (
            styledBibleTextComponentsByTag[(tag || "").replace(/^\+|1$/, '')]
            || (
              wrapInView
                ? BasicDiv
                : BasicSpan
            )
          )

          otherAttrs.className = otherAttrs.className || ``

          if(verse != null) {
            otherAttrs.className = `${otherAttrs.className} TextContent-verse`.trim()
            if(
              locsToHighlight
              && locsToHighlight.includes(getLocFromRef({ bookId, chapter, verse }))
            ) {
              otherAttrs.className = `${otherAttrs.className} TextContent-highlighted-loc`.trim()
            }
          }

          if(tag) {
            otherAttrs.className = `${otherAttrs.className} ${classNamePrefix}TextContent-tag-${tag}`.trim()
          }

          if(color) {
            otherAttrs[`data-color`] = color
          }

          if(parentWordId) {
            otherAttrs[`data-word-part`] = `${parentWordId}|${idx+1}`
          }

          if(type === 'word') {
            otherAttrs.className = `${otherAttrs.className} ${wordClassName}`.trim()
            const locWithWordNumber = getLocFromRef({
              bookId,
              chapter: ch,
              verse: vs,
              wordRanges: [ `${wordNumberInVerse || 0}` ],
            })
            if(piece['x-id'] || wordNumberInVerse) {
              otherAttrs[`data-word-id`] = piece['x-id'] || `${versionId}_${locWithWordNumber}`
              if(versionId === `original` && languageId === `heb` && !children) {
                otherAttrs[`data-word-part`] = `${piece['x-id']}|1`
              }
            }
            if(wordNumberInVerse) {
              otherAttrs[`data-word-loc`] = locWithWordNumber
            }
            const { lemma, morph, strong } = piece
            if(strong) {
              otherAttrs[`data-word-strong`] = strong
              otherAttrs[`data-word-morph`] = morph
              otherAttrs[`data-word-lemma`] = lemma
            }
          }

          if(tag === 'jmp') {
            otherAttrs.target = "_blank"
            otherAttrs.rel = "noreferrer"
            ;(attrib.slice(0).match(/link-[-a-z]+="[^"]+"/g) || []).forEach(m => {
              const [ x, attr, val ] = m.match(/link-([-a-z]+)="([^"]+)"/)  // eslint-disable-line no-unused-vars
              otherAttrs[attr] = val
            })
          }

          if(apparatusJson && (apparatusJson.critical || []).some(reading => reading.includes(':'))) {
            otherAttrs.className = `${otherAttrs.className} TextContent-apparatus-with-critical-alt`.trim()
          }

          if([ 'f', 'x', 'zApparatusJson' ].includes(tag)) {
            otherAttrs[`data-dot-id`] = `${versionId}_${getLocFromRef({ bookId, chapter: ch, verse: vs })}_${dotNum++}`
          }

          if(
            goSetPopperInfo
            && (
              (type === 'word' && !disabledWordAndVsNumClick && isOriginalOrLxx)
              || ([ 'v', 'vp' ].includes(tag) && !disabledWordAndVsNumClick && !disabledVsNumClick)
              || [ 'f', 'x', 'zApparatusJson' ].includes(tag)
            )
          ) {

            const contextRef = {
              bookId,
              chapter: ch,
              verse: vs,
            }

            otherAttrs.onClick = event => {
              if(getIgnoreClick() === true) return
              if(!document.getSelection().isCollapsed) return

              event.preventDefault()
              event.stopPropagation()

              if(type === 'word' && isOriginalOrLxx) {
                if(getIgnoreClick() === `word`) return

                const id = otherAttrs[`data-word-id`]
                const { lemma, morph, strong, text, children } = piece
                goSetPopperInfo(event, {
                  Component: OriginalLanguageWordPopperContent,
                  props: {
                    versionId,
                    content,
                    contextRef,
                    wordNumberInVerse,
                    lemma,
                    morph,
                    strong,
                    form: text || children,
                    id,
                    goSetPopperInfo,
                    closeAndClearSearch,
                  },
                })

              } else if(tag === 'f') {
                goSetPopperInfo(event, {
                  Component: FootnotePopperContent,
                  props: {
                    versionId,
                    content,
                    contextRef,
                    dotId: otherAttrs[`data-dot-id`],
                    goSetPopperInfo,
                    closeAndClearSearch,
                  },
                })

              } else if(tag === 'x') {
                const wordsToFollow = (
                  ((simplifiedPieces[idx+1] || {}).children || [])
                    .slice(0, 5)
                    .map(({ text }) => text || ``)
                    .join(' ')
                )
                let contextText = (
                  <>
                    <CrossRefDot>●</CrossRefDot>
                    {wordsToFollow}
                    {i18n("...", "ellipsis")}
                  </>
                )
                contextText = i18nReact("“{{quote}}”", { quote: contextText })
                goSetPopperInfo(event, {
                  Component: FootnotePopperContent,
                  props: {
                    versionId,
                    content,
                    contextText,
                    contextRef,
                    goSetPopperInfo,
                    selectedIdx: 0,
                    isCf: true,
                    dotId: otherAttrs[`data-dot-id`],
                    closeAndClearSearch,
                  },
                })

              } else if(tag === 'zApparatusJson') {
                goSetPopperInfo(event, {
                  Component: ApparatusPopperContent,
                  props: {
                    apparatusJson,
                    baseWords,
                    contextRef,
                    goSetPopperInfo,
                    dotId: otherAttrs[`data-dot-id`],
                    closeAndClearSearch,
                  },
                })

              } else if([ 'v', 'vp' ].includes(tag)) {
                goSetPopperInfo(event, {
                  Component: VerseNumberPopperContent,
                  props: {
                    versionId,
                    contextRef,
                    goSetPopperInfo,
                    closeAndClearSearch,
                    isVs: true,
                  },
                })

              }

            }
          }

          if(!children) {
            textAlreadyDisplayedInThisView = true
          }

          if(text && versionId === `original`) {

            if(languageId === `heb`) {
              if(!showCantillation) {
                text = removeCantillation(text)
              }
              if(!showHebrewVowels) {
                text = (
                  text
                    .replace(/[\u05B0-\u05BC\u05C1\u05C2\u05C4]/g,'')  // vowels
                    .replace(/(?:שׁ|שׂ|שׁ|שׂ)/g, 'ש')  // sin an shin
                )
              }
            }

            if(languageId === `grc`) {
              if(!showGreekAccents) {
                text = text.normalize('NFD').replace(/[\u0300-\u036f]/g, "").normalize('NFC')
              }
              if(greekPunctuation === `GREEK`) {
                text = (
                  text
                    .replace(/;/g, '·')
                    .replace(/\?/g, ';')
                )
              }
              if(greekPunctuation === `NONE`) {
                text = text.replace(/[,;:.?!]/, ``)
              }
            }

          }

          let bibleTextComponent
          if(
            !spaceBeforeVsNum
            && StyledBibleTextComponent === BasicSpan
            && Object.values(otherAttrs).length === 1  // i.e. only `className`; see next line
            && !otherAttrs.className
            && !isHit
            && !children
          ) {

            const textOrContent = text || content || ``
            if(wrapNonSpaceTextInSpan && !/^[ —,;.?!:…/”’»)\]׃־·]/.test(textOrContent)) {  // second part has chars that we don't want to start off a line
              bibleTextComponent = textOrContent.split(/( )/g).filter(Boolean).map((textPiece, idx2) => {
                return (
                  <React.Fragment key={`${idx} ${idx2}`}>
                    <span className={`TextContent-empty-span-${idx}-${idx2}`} />
                    {textPiece}
                  </React.Fragment>
                )
              })
            } else {
              // this reduces the need for extra spans
              bibleTextComponent = (
                <React.Fragment key={idx}>
                  {textOrContent}
                </React.Fragment>
              )
            }

          } else {
            bibleTextComponent = (
              <React.Fragment key={idx}>
                {spaceBeforeVsNum}
                {wrapNonSpaceTextInSpan && <span className={`TextContent-empty-span-${idx}`} />}
                <StyledBibleTextComponent
                  {...otherAttrs}
                  $isHit={isHit}
                  $disabledWordAndVsNumClick={disabledWordAndVsNumClick}
                  $disabledVsNumClick={disabledVsNumClick}
                  $isOriginalOrLxx={isOriginalOrLxx}
                  // className={wrapInView ? `print-break-text-block` : ``}
                  // ignoreChildrenChanging={ignoreChildrenChanging}
                >
                  {children
                    ? getJSXFromPieces({
                      pieces: children,
                      parentWordId: otherAttrs[`data-word-id`],
                    })
                    : (text || content)
                  }
                </StyledBibleTextComponent>
              </React.Fragment>
            )
          }

          if([ 'samech', 'peh' ].includes(tag)) {
            bibleTextComponent = (
              <Tooltip
                key={idx}
                placement="top"
                title={{
                  samech: i18n("“סתומה” – The term means “closed [portion]” with this symbol indicating a minor paragraph break.", "", "original-languages"),
                  peh: i18n("“פתוחה” – The term means “open [portion]” with this symbol indicating a major paragraph break.", "", "original-languages"),
                }[tag]}
              >
                <span>
                  {bibleTextComponent}
                </span>
              </Tooltip>
            )
          }

          return bibleTextComponent

        })
      }

      const passageJsx = getJSXFromPieces({ pieces })

      if(showExpand) {
        const contextRefs = [{
          bookId,
          chapter: startChapter,
          verse: modifiedStartVerse,
        }]
        if(startChapter !== ch || modifiedStartVerse !== vs) {
          contextRefs.push({
            bookId,
            chapter: ch,
            verse: vs,
          })
        }
        passageJsx.push(
          <ViewChapterIconButton
            key="ViewChapterIconButton"
            bookId={bookId}
            startChapter={startChapter}
            endChapter={ch}
            contextRefs={contextRefs}
            versionId={versionId}
            goSetPopperInfo={goSetPopperInfo}
            closeAndClearSearch={closeAndClearSearch}
          />
        )
      }

      return passageJsx
    },
    [ pieces, isOriginalOrLxx, languageId, showCfs, showNotes, wordClassName, classNamePrefix, bookId, showExpand, locsToHighlight,
      startChapter, startVerse, showChapterNumbers, goSetPopperInfo, versionId, closeAndClearSearch, wrapNonSpaceTextInSpan,
      disabledWordAndVsNumClick, disabledVsNumClick, showCantillation, showHebrewVowels, showGreekAccents, greekPunctuation, getIgnoreClick ],
  )

  return passageJsx || <Loading bgOpacity={0} size={loadingSize} />
}

export default memo(TextContent)
// i18n-default-category:"church-bible"

import { memo } from 'react'
import styled from 'styled-components'
import { i18n } from 'inline-i18n'

import Header from "../../common/Header"
import ChurchBibleFinder from './ChurchBibleFinder'
import LinkButton from '../../common/LinkButton'

const Container = styled.div`
  flex: 1;
  overflow: auto;
  padding: 10px 20px calc(50vh);
  background-color: white;
  position: relative;
`

const Content = styled.div`
  max-width: 800px;
  margin: 0 auto;
  font-size: 16px;
  text-align: center;
`

const PromoImage = styled.img`
  margin: 30px 0;
  width: 588px;
  height: 350px;
  max-width: calc(100vw - 30px);
  object-fit: contain;

  @media (max-width: 620px) {
    height: auto;
  }
`

const TagLine = styled.div`
  font-weight: 700;
  font-size: 23px;
`

const About = styled.div`
  font-weight: 300;
  font-size: 20px;
`

const Heading = styled.div`
  margin: 70px 0 20px;
  font-size: 24px;
`

const MoreInfo = styled.div`
  font-weight: 300;
  font-size: 14px;
`

const ButtonContainer = styled.div`
  margin: 15px 0;
`

const StyledLinkButton = styled(LinkButton)`
`

const ChurchBibles = ({ ...props }) => {

  return (
    <>

      <Header
        {...props}
        showBiblearcLogo
      />

      <Container>
        <Content>

          <PromoImage
            className="dark-mode-exempt"
            src="/cssb_phone2_tiny.png"
          />

          <TagLine>
            {i18n("For those who love the Bible + love their church")}
          </TagLine>

          <About>
            {i18n("A study Bible with sermons from your church next to the passages they teach on.")}
          </About>

          <Heading>
            {i18n("Look-up your church’s study Bible...")}
          </Heading>

          <ChurchBibleFinder />

          <Heading>
            {i18n("Does your church not have a study Bible yet?")}
          </Heading>

          <ButtonContainer>
            <StyledLinkButton
              to="/about-church-bibles"
              variant="contained"
              disableElevation
            >
              {i18n("Learn How to Create One")}
            </StyledLinkButton>
          </ButtonContainer>

          <MoreInfo>
            {i18n("(10 minute setup + no maintanence required!)")}
          </MoreInfo>

        </Content>

      </Container>

    </>
  )
}

export default memo(ChurchBibles)
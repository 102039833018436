// i18n-default-category:"projects"

import { memo, useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import EditIcon from '@material-ui/icons/Edit'
import { i18n } from 'inline-i18n'

import useModalAnchor from '../../../hooks/useModalAnchor'

import PassagePopover from '../../passage/PassagePopover'
// import HelpIconButton from '../shared/HelpIconButton'

const Container = styled.div`
  padding: 15px;
  display: flex;
  justify-content: space-between;
`

const Spacer = styled.div`
  flex: 1;
`

const StyledButton = styled(Button)`
  @media (max-width: 319px) {
    min-width: 30px;
    width: 30px;
    overflow: hidden;
    border-radius: 15px;
    .MuiButton-label {
      justify-content: start;
      width: 12px;
      white-space: nowrap;
    }
  }
`

const SmallEditIcon = styled(EditIcon)`
  height: 16px;
`

const PassageInNotesOptionsChangePassage = ({
  refs,
  versionId,
  updatePassage,
  onClose,
}) => {

  const { anchorEl, openModal, closeModal } = useModalAnchor()

  const { bookId } = refs[0] || {}
  const [ selectedVersionId, setSelectedVersionId ] = useState(versionId)

  const initialPassageState = useMemo(
    () => ({
      refs,
      bookId,
      versionId: selectedVersionId,
    }),
    [ refs, bookId, selectedVersionId ],
  )

  const updatePassageBookmark = useCallback(
    params => {
      if(params.versionId !== selectedVersionId) {
        setSelectedVersionId(params.versionId)
      } else {
        onClose()
        requestAnimationFrame(() => {
          updatePassage(params)
        })
      }
    },
    [ selectedVersionId, updatePassage, onClose ],
  )

  return (
    <Container>

      <StyledButton
        variant="contained"
        size="small"
        startIcon={<SmallEditIcon />}
        disableElevation
        onClick={openModal}
      >
        {i18n("Passage and Version")}
      </StyledButton>

      <Spacer />

      {/* <HelpIconButton
        gifSrc="/features_discourse.gif"
        courseHref="https://equip.biblearc.com/course/arcing"
        title={i18n("How to Use the Arcing/Bracketing Module")}
        courseButtonLabel={i18n("Learn Arcing")}
      /> */}

      <PassagePopover
        anchorEl={anchorEl}
        onClose={closeModal}
        initialPassageState={initialPassageState}
        updatePassageBookmark={updatePassageBookmark}
        type="verses"
        maxChapterSpan={2}
      />

    </Container>
  )
}

export default memo(PassageInNotesOptionsChangePassage)
// i18n-default-category:"subscriptions"

import { memo, useCallback, useContext, useState } from 'react'
import { i18n } from 'inline-i18n'
import { i18nReact } from 'inline-i18n/build/i18nReact'
import styled from 'styled-components'
import { NavLink } from "react-router-dom"
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'

import { getSubscriptionPlanImageSrc } from '../../utils/misc'
import { LoggedInUserContext } from '../../context/LoggedInUser'

import LinkButton from './LinkButton'
import SubscriptionInfoLine from './SubscriptionInfoLine'
import NavLinkOrAWithDisable from './NavLinkOrAWithDisable'
import useInstanceValuesCallback from '../../hooks/useInstanceValuesCallback'

const Container = styled.div`
`

const Plan = styled.div`
  white-space: nowrap;
  display: flex;
  align-items: center;
`

const PlanImageSpot = styled.div`
  margin: -5px -10px -5px 0;
  width: 201px;
  display: flex;
  align-items: center;
`

const PlanImage = styled.img`
  height: 28px;
`

const StyledDivider = styled(Divider)`
  margin: 20px -20px;
`

const SubHeading = styled.div`
  margin-top: 5px;
`

const NoSub = styled.div`
  font-weight: 300;
  padding: 5px 0;
  color: ${({ theme }) => theme.palette.grey[500]};
`

const ShowAllSubs = styled.div`
  margin-top: 20px;
  text-align: center;
`

const BalanceContainer = styled.div`
  font-size: 12px;
  margin: 15px -1px 15px;
  border: 1px solid ${({ theme }) => theme.palette.divider};
  background-color: ${({ theme }) => theme.palette.grey[50]};
  padding: 6px 10px;
  border-radius: 3px;
  line-height: 1.2;
`

const Balance = styled.div`
  font-weight: bold;
`

const BalanceExplanation = styled.div`
  margin-top: 5px;
`

const Upsell = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
`

const AboutLink = styled(NavLinkOrAWithDisable)`
  margin-left: 12px;
  font-size: 12px;
  color: ${({ theme }) => theme.palette.grey[500]};
`

const FreeForDevelopingWorld = styled.div`
  border-top: 1px solid ${({ theme }) => theme.palette.divider};
  margin: 10px -20px 0;
  padding: 20px 20px 0;
  font-size: 12px;
`

const DidYouKnow = styled.span`
  font-weight: bold;
`

const GroupSub = styled.div`
  border-top: 1px solid ${({ theme }) => theme.palette.divider};
  margin: 20px -20px -20px;
  padding: 20px 20px 20px;
  font-size: 12px;
  position: relative;
`

const GroupSubContent = styled.div`
  position: relative;
  z-index: 1;
`

const GroupSubImage = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: url("/group_subscriptions.jpg");
  background-position-y: bottom;
  background-position-x: right;
  background-size: cover;
  opacity: .15;
`
const StyledNavLinkOrAWithDisable = styled(NavLinkOrAWithDisable)`
  font-weight: 500;
`

const UpdateSubContainer = styled.div`
  margin-top: 10px;
`

const StyledLinkButton = styled(LinkButton)`
  font-weight: 400;
  color: rgb(255 255 255/.8);
`

const MyLinkButton = styled(StyledLinkButton)`
  background-color: ${({ theme }) => theme.palette.tertiary.main};

  @media (hover: hover) {
    &:hover {
      background-color: ${({ theme }) => theme.palette.tertiary.dark};
    }
  }
`

const KeyWord = styled.span`
  color: white;
  font-weight: bold;
`

const Space = styled.span`
  display: inline-block;
  width: 3px;
`

const SubscriptionInfo = ({
  user,
  setSelectedUser,
  closeModalAndDrawer,
}) => {

  const viewingAUserAsAdmin = !!user
  const me = useContext(LoggedInUserContext)
  user = user || me

  const [ showAllSubscriptions, setShowAllSubscriptions ] = useState(false)
  const goShowAllSubscriptions = useCallback(() => setShowAllSubscriptions(true), [])
  const closeDrawer = useInstanceValuesCallback(() => (closeModalAndDrawer && closeModalAndDrawer()))

  const plan = (
    user.hasEquipPlan
      ? 'EQUIP'
      : (
        user.hasToolsPlanWithoutOpenAccessWeek
          ? 'TOOLS'
          : (
            user.hasMyPlanWithoutOpenAccessWeek
              ? 'MY'
              : null
          )
      )
  )

  const hasStripeSubscription = (user.activeSubscriptions || []).some(({ type }) => type === 'STRIPE')

  const numSubs = (user.subscriptions || []).length
  const numActiveSubs = (user.activeSubscriptions || []).length

  return (
    <Container>

      {!viewingAUserAsAdmin && plan &&
        <Plan>
          {i18n("My plan:")}
          <PlanImageSpot>
            <PlanImage src={getSubscriptionPlanImageSrc(plan)} />
          </PlanImageSpot>
        </Plan>
      }

      {!viewingAUserAsAdmin && !user.hasMyPlanWithoutOpenAccessWeek &&
        <Upsell>
          <MyLinkButton
            to="/subscribe"
            variant="contained"
            disableElevation
            size="small"
            onClick={closeDrawer}
          >
            {i18nReact("Get {{my}} Biblearc Study Bible", { my: <KeyWord><Space />{i18n("My")}<Space /></KeyWord> })}
          </MyLinkButton>
          <AboutLink
            to="/about-my-bsb"
            exact
            onClick={closeDrawer}
          >
            {i18n("Learn More")}
          </AboutLink>
        </Upsell>
      }

      {!viewingAUserAsAdmin && !user.hasToolsPlanWithoutOpenAccessWeek &&
        <Upsell>
          <StyledLinkButton
            to="/subscribe"
            color="secondary"
            variant="contained"
            disableElevation
            size="small"
            onClick={closeDrawer}
          >
            {i18nReact("Get Biblearc {{tools}}", { tools: <KeyWord><Space />{i18n("TOOLS")}</KeyWord> })}
          </StyledLinkButton>
          <AboutLink
            to="/about-tools"
            exact
            onClick={closeDrawer}
          >
            {i18n("Learn More")}
          </AboutLink>
        </Upsell>
      }

      {!viewingAUserAsAdmin && !user.hasEquipPlan &&
        <Upsell>
          <StyledLinkButton
            to="/subscribe"
            color="primary"
            variant="contained"
            disableElevation
            size="small"
            onClick={closeDrawer}
          >
            {i18nReact("Get Biblearc {{equip}}", { equip: <KeyWord><Space />{i18n("EQUIP")}</KeyWord> })}
          </StyledLinkButton>
          <AboutLink to="https://equip.biblearc.com">
            {i18n("Learn More")}
          </AboutLink>
        </Upsell>
      }

      <StyledDivider />

      <SubHeading>
        {i18n("Subscription details")}
      </SubHeading>

      {!showAllSubscriptions && (user.activeSubscriptions || []).map(subscription => (
        <SubscriptionInfoLine
          key={subscription.id}
          {...subscription}
          isActive={true}
          viewingAUserAsAdmin={viewingAUserAsAdmin}
          setSelectedUser={setSelectedUser}
        />
      ))}

      {!showAllSubscriptions && numActiveSubs === 0 &&
        <NoSub>
          {i18n("No active subscription.")}
        </NoSub>
      }

      {viewingAUserAsAdmin && !showAllSubscriptions && numSubs > 0 && numSubs > numActiveSubs &&
        <ShowAllSubs>
          <Button
            color="default"
            size="small"
            variant="contained"
            onClick={goShowAllSubscriptions}
            disableElevation
          >
            {i18n("Show inactive subscriptions ({{number}})", "", "admin", {
              number: numSubs - numActiveSubs,
            })}
          </Button>
        </ShowAllSubs>
      }

      {showAllSubscriptions && (user.subscriptions || []).map(subscription => (
        <SubscriptionInfoLine
          key={subscription.id}
          {...subscription}
          isActive={(user.activeSubscriptions || []).some(sub => sub.id === subscription.id)}
          viewingAUserAsAdmin={viewingAUserAsAdmin}
          setSelectedUser={setSelectedUser}
        />
      ))}

      {!!user.stripeBalance &&
        <BalanceContainer>
          <Balance>
            {user.stripeBalance > 0 && (
              i18n("Credit: ${{amount}}", {  // eslint-disable-line no-template-curly-in-string
                amount: user.stripeBalance.toFixed(2),
              })
            )}
            {user.stripeBalance < 0 && (
              i18n("Balance owed: ${{amount}}", {  // eslint-disable-line no-template-curly-in-string
                amount: (user.stripeBalance * -1).toFixed(2),
              })
            )}
          </Balance>
          {!viewingAUserAsAdmin &&
            <BalanceExplanation>
              {user.stripeBalance > 0 && i18n("This credit will be exhausted before your card is charged.")}
              {user.stripeBalance < 0 && i18n("This amount will be added to your next charge.")}
            </BalanceExplanation>
          }
        </BalanceContainer>
      }

      {!viewingAUserAsAdmin && hasStripeSubscription &&
        <UpdateSubContainer>
          <LinkButton
            to="/subscribe"
            variant="contained"
            disableElevation
            size="small"
            onClick={closeDrawer}
          >
            {i18n("Update my subscription")}
          </LinkButton>
        </UpdateSubContainer>
      }

      {!viewingAUserAsAdmin && !user.hasToolsPlanWithoutOpenAccessWeek &&
        <FreeForDevelopingWorld>
          <DidYouKnow>
            {i18n("Did you know?")}
          </DidYouKnow>
          {` `}
          {i18nReact("Biblearc TOOLS is free or discounted for select countries in the developing world. Click {{here}} to learn more.", {
            here: (
              <NavLink
                exact
                to="/whatever-you-can-afford"
                onClick={closeDrawer}
              >
                {i18n("here")}
              </NavLink>
            ),
          })}
        </FreeForDevelopingWorld>
      }

      {!viewingAUserAsAdmin &&
        <GroupSub>
          <GroupSubImage className="dark-mode-exempt" />
          <GroupSubContent>
            <StyledNavLinkOrAWithDisable
              to="/group-subscriptions"
              exact
              onClick={closeDrawer}
            >
              {i18n("Group Subscriptions")}
            </StyledNavLinkOrAWithDisable>
            <div>
              {i18n("For your family, church, or college.")}
            </div>
          </GroupSubContent>
        </GroupSub>
      }

    </Container>
  )
}

export default memo(SubscriptionInfo)

// i18n-default-category:"study-bible"

import React, { memo, useContext, useState } from 'react'
import styled from 'styled-components'
import { i18n } from 'inline-i18n'
import { i18nReact } from 'inline-i18n/build/i18nReact'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
// import ShareIcon from '@material-ui/icons/Share'
// import CheckIcon from '@material-ui/icons/Check'
import DeleteIcon from '@material-ui/icons/Delete'
import { isRTLText } from '@bibletags/bibletags-ui-helper'

import { LoggedInUserContext } from '../../context/LoggedInUser'
import { getRefFromLoc } from '@bibletags/bibletags-versification'
import { SKETCH_LINE_HEIGHT, SKETCH_FONT_SIZE } from '../passage/MakeASketchMenuItem'
import { SKETCH_TEXT_HORIZONTAL_PADDING, SKETCH_TEXT_VERTICAL_PADDING } from '../../utils/constants'
import useVersionInfo from '../../hooks/useVersionInfo'
import usePassageRef from '../../hooks/usePassageRef'
import useInstanceValuesCallback from '../../hooks/useInstanceValuesCallback'
import useGoUpdateHighlight from '../../hooks/useGoUpdateHighlight'
import useVersesPieces from '../../hooks/useVersesPieces'
import useSimpleToggle from '../../hooks/useSimpleToggle'
import useEffectAsync from '../../hooks/useEffectAsync'
import useSetTimeout from '../../hooks/useSetTimeout'
import useAppSize from '../../hooks/useAppSize'

import TextContent from '../common/TextContent'
import RelativeTime from '../common/RelativeTime'
import ContainerWithPassagePopper from '../passage/ContainerWithPassagePopper'
import CustomSwitch from '../common/CustomSwitch'
import SketchCanvas from '../common/SketchCanvas'
import PassThruPropsToChildrenWrapper from '../common/PassThruPropsToChildrenWrapper'

const Container = styled.div`
  min-width: 250px;
  width: ${({ $lineWidth }) => ($lineWidth || 380) + SKETCH_TEXT_HORIZONTAL_PADDING*2}px;
  user-select: ${({ $inEditingMode }) => $inEditingMode ? 'none' : 'text'};
  max-width: calc(100vw - 30px);
  border-radius: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
`

const Header = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: flex-start;
  padding: 5px 0;
`

const HeaderInfo = styled.div`
  flex: 1;
  padding: 10px 15px;
  font-size: 15px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const StyledIconButton = styled(IconButton)`
  padding: 8px;
  margin: 1px 6px 4px 10px;
  position: relative;
  z-index: 9;

  .MuiSvgIcon-root {
    height: 20px;
    width: 20px;
  }
`

const LastUpdated = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  font-size: 10px;
  font-weight: 300;
  text-align: right;
  padding: 0 15px 12px;
  white-space: nowrap;
  height: 15px;
  line-height: 15px;
  box-sizing: content-box;
`

const Box = styled.div`

  .PassThruPropsToChildrenWrapper,
  .FreehandContainer-Svg {
    transform: scale(${({ $scale }) => $scale})
               translateY(${({ $scale }) => (1 / $scale - 1) * SKETCH_TEXT_VERTICAL_PADDING}px);
    transform-origin: top left;
  }

  .PassThruPropsToChildrenWrapper {
    margin-bottom: ${({ $marginBottom }) => $marginBottom}px;
    margin-right: -${({ $scale }) => (1 / $scale - 1) * 100}%;
  }

  .FreehandContainer-Svg {
    width: ${({ $scale }) => (1 / $scale) * 100}%;
    height: ${({ $scale }) => (1 / $scale) * 100}%;
  }

`

const StyledContainerWithPassagePopper = styled(ContainerWithPassagePopper)`

  line-height: ${SKETCH_LINE_HEIGHT}px;
  font-size: ${SKETCH_FONT_SIZE}px;

  white-space: nowrap;
  direction: ${({ $isRTL }) => $isRTL ? 'rtl' : 'ltr'};

  ${({ $breaks }) => (
    ($breaks || [])
      .map(emptySpanNums => `
        & .TextContent-empty-span-${emptySpanNums} {
          display: block;
        }
      `)
      .join(`\n`)
  )}
`

const StyledCustomSwitch = styled(CustomSwitch)`
  position: relative;
  z-index: 10;
`

const StyledPassThruPropsToChildrenWrapper = styled(PassThruPropsToChildrenWrapper)`
  padding: ${SKETCH_TEXT_VERTICAL_PADDING}px ${SKETCH_TEXT_HORIZONTAL_PADDING}px;
`

const StudyBibleSketch = ({
  highlight,
  onClose,
  setSnackbarInfo,
}) => {

  const { id: loggedInUserId, hasMyPlan } = useContext(LoggedInUserContext) || {}
  const [ goUpdateHighlight, goDeleteHighlight ] = useGoUpdateHighlight({ highlight })
  const [ currentHighlight, setCurrentHighlight ] = useState({})
  const { width } = useAppSize()

  const {
    // __typename,
    // id,
    // note,
    // shared,
    userId,
    // user,  // TODO
    info,
    // createdAt,
    savedAt,
    versionId,
    fromLoc,
    toLoc,
    fromWordNumber,
    toWordNumber,
    // fromCharacterNumber,
    // toCharacterNumber,
  } = highlight || {}

  const { isNew } = info || {}
  const { textSettings, lineWidth, breaks, svgStrokes, utensilState } = currentHighlight.info || {}

  const isMine = userId === loggedInUserId
  const { bookId, chapter: startChapter, verse: startVerse } = getRefFromLoc(fromLoc)
  let [ inEditingMode, toggleInEditingMode ] = useSimpleToggle(!!isNew)
  inEditingMode = isMine && hasMyPlan && inEditingMode
  const [ setNotNewTimeout ] = useSetTimeout()

  // figure out scale, etc
  const neededWidth = lineWidth + SKETCH_TEXT_HORIZONTAL_PADDING*2
  const availableWidth = width - 30  // 30 is the popover margin
  const scale = Math.min(1, availableWidth / neededWidth)
  const unscaledTextHeight = ((breaks || []).length+1) * SKETCH_LINE_HEIGHT
  const scaledTextHeight = unscaledTextHeight * scale
  const marginBottom = scaledTextHeight - unscaledTextHeight

  const [ pieces, x, numWordsInLastVerse ] = useVersesPieces({  // eslint-disable-line no-unused-vars
    fromLoc: `${fromLoc}:${fromWordNumber}`,
    toLoc: `${toLoc}:${toWordNumber}`,
    versionId,
  })

  const { version } = useVersionInfo(versionId) || {}
  const { languageId } = version || {}
  const isRTL = isRTLText({ languageId, bookId })

  const includesLastWordOfFinalVerse = toWordNumber === numWordsInLastVerse
  const refs = [ getRefFromLoc(`${fromLoc}${fromWordNumber !== 1 || ((fromLoc === toLoc && !includesLastWordOfFinalVerse)) ? `:${fromWordNumber}` : ``}`) ]
  if(toLoc !== fromLoc) refs.push(getRefFromLoc(`${toLoc}${!includesLastWordOfFinalVerse ? `:${toWordNumber}` : ``}`))
  const passageStr = usePassageRef({ refs })

  const goDeleteAndClose = useInstanceValuesCallback(
    () => {

      onClose()
      goDeleteHighlight()

      const closeSnackbar = () => setSnackbarInfo({ ...snackbarInfo, open: false })
      const snackbarInfo = {
        open: true,
        message: i18n("Sketch deleted"),
        onClose: closeSnackbar,
        onClick: () => {
          goUpdateHighlight({ deletedAt: null })
          closeSnackbar()
        },
      }
      setSnackbarInfo(snackbarInfo)

    }
  )

  const updateSketchInfo = useInstanceValuesCallback(
    sketchInfo => {
      const info = {
        ...currentHighlight.info,
        ...sketchInfo,
      }
      const updatedHighlight = {
        ...currentHighlight,
        info,
      }
      goUpdateHighlight({ info })
      setCurrentHighlight(updatedHighlight)
    },
  )

  useEffectAsync(
    () => {
      setCurrentHighlight(highlight || {})
      if(isNew) {
        setNotNewTimeout(() => {
          updateSketchInfo({ isNew: false })
        }, 500)
      }
    },
    [ highlight ],
  )

  useEffectAsync(() => window.getSelection().empty(), [ inEditingMode ])

  return (
    <Container
      $lineWidth={lineWidth}
      $inEditingMode={inEditingMode}
    >

      <Header>

        <HeaderInfo>
          {passageStr}
        </HeaderInfo>

        {isMine && hasMyPlan &&
          <StyledCustomSwitch
            checked={!!inEditingMode}
            onChange={toggleInEditingMode}
            label={i18n("Editing")}
            tabIndex={-1}
          />
        }

        {isMine &&
          <Tooltip title={i18n("Delete this note")}>
            <StyledIconButton
              onClick={goDeleteAndClose}
            >
              <DeleteIcon />
            </StyledIconButton>
          </Tooltip>
        }

      </Header>

      <Box
        $scale={scale}
        $marginBottom={marginBottom}
      >

        <StyledContainerWithPassagePopper
          onTopOfAll
          versionId={versionId}
          bookId={bookId}
          closePopperOnChangeText={inEditingMode ? `editing` : `not-editing`}
          $breaks={breaks}
          $isRTL={isRTL}
        >
          <StyledPassThruPropsToChildrenWrapper>
            <TextContent
              pieces={pieces}
              versionId={versionId}
              bookId={bookId}
              startChapter={startChapter}
              startVerse={startVerse}
              {...(textSettings || {})}
              wrapNonSpaceTextInSpan
            />
          </StyledPassThruPropsToChildrenWrapper>
        </StyledContainerWithPassagePopper>

        {!!currentHighlight.id &&
          <SketchCanvas
            key={currentHighlight.id}
            svgStrokes={svgStrokes}
            utensilState={utensilState}
            updateSketchInfo={updateSketchInfo}
            inEditingMode={inEditingMode}
            toggleInEditingMode={toggleInEditingMode}
            scaleAdjustment={scale}
          />
        }

      </Box>

      <LastUpdated>
        {!inEditingMode &&
          i18nReact("Last updated: {{date}}", {
            date: <RelativeTime date={savedAt} />,
          })
        }
      </LastUpdated>

    </Container>
  )
}

export default memo(StudyBibleSketch)
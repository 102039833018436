import { memo, useContext, useState } from 'react'
import { i18n } from 'inline-i18n'
import styled from 'styled-components'
import IconButton from '@material-ui/core/IconButton'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import Divider from '@material-ui/core/Divider'
import Menu from '@material-ui/core/Menu'
import TranslateIcon from '@material-ui/icons/Translate'
import CheckIcon from '@material-ui/icons/Check'
import { getLanguageInfo } from '@bibletags/bibletags-ui-helper'
import { useMutation } from '@apollo/client'

import { getLocalStorage, loginCallback, preventDefaultEvent, setLocalStorage } from '../../utils/misc'
import useInstanceValuesCallback from '../../hooks/useInstanceValuesCallback'
import useModalAnchor from '../../hooks/useModalAnchor'
import useMutationContext from '../../hooks/useMutationContext'
import localesInfo from '../../localesInfo'
import { ChannelIdInPWAContext } from '../../context/ChannelIdInPWA'
import { LoggedInUserContext } from '../../context/LoggedInUser'

import NavLinkOrAWithDisable from './NavLinkOrAWithDisable'

import updateUserMutation from '../../graphql/mutations/updateUser'

export const LOCALE_LOCAL_STORAGE_ID = `I18nButton:locale`

const localeStatus = {
  en: `complete`,
  ...localesInfo.localeStatus,
}

const StyledMenu = styled(Menu)`
  .MuiMenu-list {
    padding: 0;
  }
`

const StyledCheckIcon = styled(CheckIcon)`
  font-size: 20px;
  margin-left: 8px;
`

const StyledIconButton = styled(IconButton)`
  position: absolute;
  top: 4px;
  right: 4px;
  padding: 10px;

  .MuiSvgIcon-root {
    font-size: 18px;
  }
`

const IncompleteNote = styled.div`
  padding: 0 15px 12px;
  color: ${({ theme }) => theme.palette.text.secondary};
  font-style: italic;
  font-size: 12px;
  font-weight: 300;
`

const DontSeeMenuItem = styled(MenuItem)`
  font-size: 13px;
  line-height: 1.2;
  font-weight: 300;
`

const locale = getLocalStorage(LOCALE_LOCAL_STORAGE_ID, `en`)

const I18nButton = ({
  onClose,
  ...otherProps
}) => {

  const { anchorEl, openModal, closeModal } = useModalAnchor()
  const [ reloadingLocale, setReloadingLocale ] = useState()
  const { channelIdInPWA } = useContext(ChannelIdInPWAContext)
  const user = useContext(LoggedInUserContext)
  const context = useMutationContext()
  const [ updateUser ] = useMutation(updateUserMutation)

  const goChangeLocale = useInstanceValuesCallback(
    async event => {
      preventDefaultEvent(event)

      const newLocale = event.target.closest('[data-locale]').getAttribute('data-locale')
      setReloadingLocale(newLocale)

      if(user && user.languageId !== newLocale) {
        const { data: { updateUser: updatedUser } } = await updateUser({
          variables: {
            id: user.id,
            input: {
              languageId: newLocale,
            },
          },
          context,
        })
        loginCallback(updatedUser)
      } else {
        setLocalStorage(LOCALE_LOCAL_STORAGE_ID, newLocale)
        window.location.reload()  
      }
    }
  )

  const goClose = useInstanceValuesCallback(
    () => {
      closeModal()
      onClose && onClose()
    }
  )

  if(channelIdInPWA) return null

  return (
    <>

      <StyledIconButton
        onClick={openModal}
        {...otherProps}
      >
        <TranslateIcon />
      </StyledIconButton>

      <StyledMenu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={!!reloadingLocale ? null : closeModal}
      >
        <MenuList>
          {Object.keys(localeStatus)
            .filter(iso6391 => [ `complete`, `partial` ].includes(localeStatus[iso6391]))
            .map(getLanguageInfo)
            .sort((a,b) => a.englishName.toLowerCase() < b.englishName.toLowerCase() ? -1 : 1)
            .map(({ iso6391, englishName, nativeName }) => {
              englishName = localeStatus[iso6391] === `partial` ? `${englishName}*` : englishName
              return (
                <MenuItem
                  key={iso6391}
                  onClick={!!reloadingLocale ? null : goChangeLocale}
                  disabled={!!reloadingLocale || locale === iso6391}
                  data-locale={iso6391}
                >
                  {
                    nativeName === englishName
                      ? englishName
                      : (
                        i18n("{{title}} ({{subtitle}})", {
                          title: englishName,
                          subtitle: nativeName,
                        })
                      )
                  }
                  {(reloadingLocale || locale) === iso6391 && <StyledCheckIcon />}
                </MenuItem>
              )
          })}
        </MenuList>

        {Object.keys(localeStatus).some(iso6391 => [ `partial` ].includes(localeStatus[iso6391])) &&
          <IncompleteNote>
            {i18n("* Site translation incomplete")}
          </IncompleteNote>
        }

        <Divider />

        <MenuList>
          <NavLinkOrAWithDisable
            to="/translate"
            onClick={goClose}
          >
            <DontSeeMenuItem>
              Don’t see your language?
              <br />
              Want to improvement a translation?
            </DontSeeMenuItem>
          </NavLinkOrAWithDisable>
        </MenuList>

      </StyledMenu>

    </>
  )

}

export default memo(I18nButton)